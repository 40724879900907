import moment from 'moment'
import { extractYears } from '../../../../helper'

const RenderColumnByFieldId = ({ field_id, row, gridRelatedData }) => {
  const { globalUIFilter } = gridRelatedData
  switch (field_id) {
    case 'domain_id':
      return row?.domain_name
    case 'block_id':
      const blocks = row?.locations?.map((item) => item?.name)
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return
      return (
        <div className=''>
          {blocks?.map((item, i) => (
            <div key={i} className='mb-2 line-clamp-1'>
              {item}
            </div>
          ))}
        </div>
      )
    case 'status':
      const blocks_status = row?.locations?.map((item) => {
        if (item?.created_at) {
          moment(item?.created_at).year()
        }
      })
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return
      return (
        <div>
          {blocks_status?.map((item, i) => (
            <div key={i} className='mb-2'>
              {item ? <>Started {item}</> : ''}
            </div>
          ))}
        </div>
      )
    case 'details':
      if (
        globalUIFilter?.block_reports?.acadamic_year?.value &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== '5' &&
        globalUIFilter?.block_reports?.acadamic_year?.value !== 5
      )
        return

      const details = []
      row?.locations?.map((location) => {
        const first_item = location?.items?.[0]?.item_name || location?.items?.[0]?.id
        const last_item = location?.items?.[location?.items?.length - 1]?.item_name || location?.items?.[location?.items?.length - 1]?.id
        if (first_item && last_item) {
          details?.push(`${first_item}-${last_item}`)
        } else {
          details?.push(' - ')
        }
      })
      return (
        <div>
          {details?.map((item, i) => (
            <div key={i} className='mb-2'>
              {item}
            </div>
          ))}
        </div>
      )
    case 'item_date_time_id':
      return <>{row?.item_date_time_id_label && <div>{extractYears(row?.item_date_time_id_label)}</div>}</>
    default:
      return
  }
}

const BlockReportColumn = ({ cell, row, gridRelatedData }) => {
  return (
    <td
      {...cell.getCellProps()}
      style={{ ...cell.column.commonStyle, maxHeight: 'fit-content', alignItems: 'start' }}
      className={`rt-td py-4 bg-${cell.background_color}`}

      // className={'rt-td bg-' + cell.background_color + isCellInSync ? ' blue-border' : ''}
    >
      <div>
        <RenderColumnByFieldId field_id={cell.column.colData?.field_id} row={row} gridRelatedData={gridRelatedData}></RenderColumnByFieldId>
      </div>
    </td>
  )
}

export default BlockReportColumn
