import { FileUploader } from 'react-drag-drop-files'
import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { request } from '../../../Redux/Sagas/requests/api'
import { useEffect } from 'react'
import { cms, covertFileIntoBase64 } from '../../../helper'
import { AvField } from 'availity-reactstrap-validation'
import { triggerBase64Download } from 'common-base64-downloader-react'

const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'ZIP']

const DownloadLink = ({ type, link, id, onFileDelete, isBase64 }) => {
  const [deleting, setDeleting] = useState(false)
  const onDelete = (id) => {
    const payload = {
      upload_file_id: [id],
    }
    setDeleting(true)
    setDeleting(false)
    onFileDelete(id)
    // request('remove-form-files', 'POST', payload)
    //   .then((data) => {
    //     setDeleting(false)
    //     onFileDelete(id)
    //   })
    //   .catch((err) => setDeleting(false))
  }

  const onDownLoadFile = () => {
    triggerBase64Download(link, (Math.random() + 1).toString(36).substring(7))
  }

  return (
    <div>
      {isBase64 ? (
        <span
          className='text-primary'
          onClick={() => {
            onDownLoadFile()
          }}>
          Download file
        </span>
      ) : (
        <a href={link} downloadLink target='_blank'>
          View {link?.split('/')?.splice(-1)}
        </a>
      )}

      <span style={{ color: 'red', marginLeft: '2rem', cursor: 'pointer' }} onClick={() => onDelete(id)}>
        {!deleting ? 'Remove' : 'Removing...'}
      </span>
    </div>
  )
}

const AllFileUpload = ({ data, form, onChange, defaultValue }) => {
  const [file, setFile] = useState(null)
  const [fileURL, setFileURL] = useState([])
  const [fileId, setFileId] = useState()

  const handleChange = (file) => {
    setFile(file)
    if (form?.component_name !== 'advanced_grid' && form?.component_name !== 'static_form') {
      let formData = new FormData()
      for (let i = 0; i < file.length; i++) {
        formData.append(`files[${i}]`, file[i])
      }

      // formData.append("files",JSON.stringify(file_array) );
      formData.append('form_name', form.name)

      request('upload-form-files', 'POST', formData).then((d) => {
        setFileURL(d.data.files)
        setFileId(d.data.upload_id)
        onChange(data.field_id, d.data.upload_id)
      })
    } else {
      covertFileIntoBase64(file).then((file) => {
        const values = {
          file: file,
          file_name: file?.name,
          original_file_name: file?.name,
          ext: file?.type?.split('/')[1],
          isBase64: true,
        }

        setFile(file)
        setFileURL([{ file, isBase64: true }])
      })
    }
  }

  const onFileDelete = (id) => {
    const fileToDelete = fileURL?.filter((item) => item.upload_file_id === id)
    const index = fileURL?.indexOf(fileToDelete)
    fileURL.splice(index, 1)
    const temp = [...fileURL]
    setFileURL(temp)
    if (temp.length === 0) {
      setFile(null)
    }
  }

  useEffect(() => {
    if (form?.component_name !== 'advanced_grid' && form?.component_name !== 'static_form') {
      const payload = {
        upload_id: parseInt(defaultValue),
      }
      if (defaultValue) {
        request('get-form-files', 'POST', payload).then((d) => setFileURL(d.data.files))
      }
    } else {
      if (defaultValue) {
        setFileURL([{ url: process.env.REACT_APP_STORAGE + defaultValue }])
      }
    }
  }, [])

  return (
    <div className='form-group'>
      <label>{cms(data.cms_key)}</label>
      <FileUploader
        handleChange={handleChange}
        name='file'
        types={fileTypes}
        multiple={form?.component_name === 'advanced_grid' || form?.component_name === 'static_form' ? false : true}
        fileOrFiles={file}
        classes='file-upload-field'
      />
      {fileURL &&
        fileURL.map((item, i) => {
          return (
            <DownloadLink
              key={i}
              type={item.type}
              link={item?.url || item?.file}
              isBase64={item?.isBase64}
              id={item.upload_file_id}
              onFileDelete={onFileDelete}
            />
          )
        })}
      <AvField
        type='hidden'
        name={data?.field_id || data?.name || 'no_name'}
        value={form?.component_name === 'advanced_grid' || form?.component_name === 'static_form' ? file : fileId}></AvField>
      {/* <Button onClick={() => setFile(null)}>Remove File</Button> */}
    </div>
  )
}

export default AllFileUpload
