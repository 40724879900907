import { useSelector } from 'react-redux'
import { useFetchLogsByTable } from '../../Queries/Logs/useFetchLogsByTable'
import { useState } from 'react'
import WidgetDesginUpdate from './logs/widgetUpdate'
import EmailUpdateLog from './logs/emailUpdate'
import TagUpdateLog from './logs/tagUpdateLog'
import Loader from 'react-loaders'

const renderLog = (type, data, onExpand, expandedId) => {
  switch (type) {
    case 'create':
      return
    case 'widget_update':
      return <WidgetDesginUpdate data={data} onExpand={onExpand} expandedId={expandedId}></WidgetDesginUpdate>
    case 'update':
      return <EmailUpdateLog data={data} onExpand={onExpand} expandedId={expandedId}></EmailUpdateLog>
    case 'tag_update':
      return <TagUpdateLog data={data} onExpand={onExpand} expandedId={expandedId}></TagUpdateLog>
    case 'role_update':
      return
    default:
      return
  }
}

const EmailLogs = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const { data, isLoading } = useFetchLogsByTable(role_id, 'email_templates')

  const [expandedId, setExpandedId] = useState()

 

  const onExpand = (id) => {
    setExpandedId(id)
  }


  if (!isLoading && data?.data?.length === 0)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '3rem' }}>
        <img src='not_found.png' style={{ width: '20rem' }}></img>
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div>
      {data?.data?.map((item, index) => {
        if (!expandedId) {
          return (
            <div key={index} className='p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
              {renderLog(item?.type, item, onExpand, null)}
            </div>
          )
        } else {
          if (expandedId === item?.id) {
            return (
              <div key={index} className='p-3'>
                {renderLog(item?.type, item, onExpand, expandedId)}
              </div>
            )
          } else {
            return <></>
          }
        }
      })}
    </div>
  )
}

export default EmailLogs
