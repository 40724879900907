import { AvField, AvForm } from 'availity-reactstrap-validation'
import { primary_color } from '../../../Helper/uiHelper'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const MoveOutsideBlock = ({
  blockDetails,
  setBlocketails,
  setSelectedLockerIds,
  selectedLockerIds,
  setSelectedLockers,
  selectedLockers,
  isPlannedBlock,
}) => {
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)
  const [selectedBlock, setSelectedBlock] = useState()
  const [selectedLocker, setSelectedLocker] = useState()
  const [blockOptions, setBlockOptions] = useState([])
  const [lockerOptions, setLockerOptions] = useState([])

  useEffect(() => {
    request('get-locations-by-domain-id', 'POST', { domain_id: blockDetails?.domain_id, active: !isPlannedBlock }).then((res) => {
      const locations = res?.data?.locations?.filter((location) => location?.id !== blockDetails?.id)
      setBlockOptions(locations)
    })
  }, [])

  useEffect(() => {
    if (!selectedBlock) return
    request('get-items-by-location-id', 'POST', { location_id: parseInt(selectedBlock), active: !isPlannedBlock }).then((res) => {
      setLockerOptions(res?.data?.items)
    })
  }, [selectedBlock])

  const onMove = () => {
    if (!selectedBlock) {
      dispatch(setNotification({ type: 'error', message: 'Please select Block' }))
      return
    }
    if (!selectedLocker) {
      dispatch(setNotification({ type: 'error', message: 'Please select move after locker' }))
      return
    }
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select locker' }))
      return
    }

    let payload = {
      item_date_time_id: blockDetails?.item_date_time_id,
      domain_id: blockDetails?.domain_id,
      location_id: blockDetails?.id,
      selected_locker: parseInt(selectedLocker),
      selected_block: parseInt(selectedBlock),
      moved_locker: selectedLockerIds,
      is_planned_block: isPlannedBlock,
    }

    setLoader(true)
    request('move-out-side-block', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        request('get-location-data', 'POST', { location_id: blockDetails?.id, is_planned_block: isPlannedBlock }).then((res) => {
          dispatch(setNotification({ type: 'success', message: 'Locker moved successfully!' }))
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader(false)
      }
    })
    setSelectedLockerIds()
    setSelectedLockers()
    setSelectedLocker()
    setSelectedLocker()
  }

  return (
    <div>
      <AvForm>
        <div className='d-flex'>
          <div style={{ width: '12rem' }} className='mr-4'>
            <AvField
              type='select'
              name='selected_locker'
              value={selectedBlock}
              onChange={(e) => {
                setSelectedBlock(e.target.value)
              }}>
              <option>Select Block</option>
              {blockOptions?.map((item) => {
                return <option value={item?.id}>{item?.name}</option>
              })}
            </AvField>
          </div>
          <div style={{ width: '12rem' }} className='mr-4'>
            {' '}
            <AvField
              type='select'
              name='move_after'
              value={selectedLocker}
              onChange={(e) => {
                setSelectedLocker(e.target.value)
              }}>
              <option>Move After Locker</option>
              {lockerOptions?.map((item) => {
                return <option value={item?.id}>{item?.name}</option>
              })}
            </AvField>
          </div>

          <button
            className='btn text-white mb-4 ml-4'
            // disabled={!!loading}
            type='submit'
            onClick={() => {
              onMove()
            }}
            style={{ background: primary_color, fontWeight: 700 }}>
            {loader ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default MoveOutsideBlock
