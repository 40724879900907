import React, { Fragment, useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Col } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import _ from 'lodash'
import { Button as ReactButton } from 'reactstrap'
import IconSet from '../icon'

import {
  clearCurrentRowData,
  clearParentTableData,
  closeOpenModal,
  fetchUpdateApiData,
  setConnectTemplateDataRedux,
  setCurrentRow,
  updateSubmittedChangedField,
} from '../../Redux/Reducers/CMS'
import {
  checkPermission,
  cms,
  editCMS,
  FormDataValidation,
  getMainGridNameByParentPageName,
  isDirectUpdateConnectTemplate,
  storeUserSecurityOverviewDropdownPnSubmit,
  updateSaveDefaultFormData,
  updateSyncForFormUpdateByReactId,
} from '../../helper'
import RenderFields from './renderFields'
import { useHistory, useLocation } from 'react-router-dom'
import { clearPageTab, setTabFormData } from '../../Redux/Reducers/page'
import { setQueue } from '../../Redux/Reducers/queue'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { request } from '../../Redux/Sagas/requests/api'
import moment from 'moment'
import { updateProgressDataByPagename } from '../../progressHelper'
import { primary_color } from '../../Helper/uiHelper'

const getDefaultCasePrintValue = (value) => {
  try {
    if (value && new Date(value) !== 'Invalid Date' && !isNaN(new Date(value))) {
      return moment(value).format('D MMM YYYY h:mm a')
    } else {
      return value
    }
  } catch (err) {
    return value
  }
}
const getPrintValue = (field_type, data, field) => {
  const value = data[field?.field_id]
  switch (field_type) {
    case 'rich_text':
      return <div dangerouslySetInnerHTML={{ __html: data[field?.field_id] }} style={{ flex: 1 }}></div>
    case 'date':
    case 'datetime':
    case 'date-time':
      return value && <div>{moment(value).format('D MMM YYYY h:mm a')}</div>
    default:
      const ans = getDefaultCasePrintValue(data[`${field?.field_id}_label`] || data[field?.field_id])
      return ans
  }
}

const PrintForm = ({ data, formData, cmsTabKey }) => {
  const editMode = useSelector((state) => state.CMS.editMode)
  const [render, reRender] = useState(false)
  const cms_data = useSelector((state) => state.CMS.cmsData)

  return (
    <div className='gridSettigTable'>
      <table>
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{ cursor: editMode ? 'pointer' : '' }}
              onClick={() => {
                if (editMode) {
                  editCMS(cmsTabKey)
                }
              }}>
              {cms(cmsTabKey)}
            </th>
          </tr>
        </thead>
        <tbody>
          {formData?.component_call_fields?.map((field, i) => {
            return (
              <tr key={i}>
                <td
                  style={{ width: '50%', cursor: editMode ? 'pointer' : '' }}
                  onClick={() => {
                    if (editMode) {
                      editCMS(field?.cms_key)
                    }
                  }}>
                  {cms(field.cms_key)}
                </td>

                <td>{getPrintValue(field?.field_setting?.field, data, field)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const select = (state) => ({
  coreData: state.CMS.coreData,
  openModal: state.CMS.openModal,
  // currentTabData: state.Page.currentTabData,
  primaryColor: state.ThemeOptions.primaryColor,
  tabFormData: state.Page.tabFormData.data,
  currentRowData: state.Page.tabFormData,
  currentRow: state.CMS.currentRow,
  connectTemplateDataRedux: state.CMS.connectTemplateDataRedux,
  domain_id: state.CMS.selected_domain_id,
})

const CMSForm = ({
  allFields,
  coreData,
  cols,
  dataConnection,
  formData,
  currentTabData,
  primaryColor,
  openModal,
  tabFormData,
  isPrintVisible = false,
  currentRowData,
  cmsTabKey,
  isModalAction,
  isModel,
  content,
  currentRow,
  pageName,
  parentPageName,
  connectTemplateDataRedux,
  defaultFormValue,
  domain_id,
  sourceTable,
}) => {
  const dispatch = useDispatch()
  const [formSubmitButton, setFormSubmitButton] = useState({
    available: false,
    url: '',
  })

  const [form, setFormData] = useState({})
  const isSaveButtonClicked = useRef(false)
  // const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)
  const [validationErrorOnFieldValueChange, setValidationErrorOnFieldValueChange] = useState([])

  const tabFormDataSourceTable = useSelector((state) => state.Page.tabFormData.main_source_table)
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const isPrintView = query.get('printTab') ? true : false
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const queues = useSelector((state) => state.Queue.queues)

  // state to maintain all form field value in tab form

  const [formFieldValue, setFormFieldValue] = useState({})

  useEffect(() => {
    if (currentRow && currentRow?.length > 0) {
      updateProgressDataByPagename(currentRow[currentRow?.length - 1], parentPageName)
    }
  }, [currentRow])

  const onPrint = () => {
    if (isPrintView) {
      window.print()
    } else {
      const params = new URLSearchParams({ printTab: true })
      history.replace({ pathname: location.pathname, search: params.toString() })
    }
  }

  const onBackToForm = () => {
    history.replace({ pathname: location.pathname, search: '' })
  }

  const handleSubmit = (e, values) => {
    const { id, ...value_without_id } = values

    if (values && Object.keys(values)?.includes('password') && Object.keys(values)?.includes('password_confirmation')) {
      if (values['password_confirmation'] && values['password'] !== values['password_confirmation']) {
        dispatch(setNotification({ type: 'error', message: 'Please confirm password' }))
        return
      }
    }
    let new_row_data = {
      ...currentRowData,
      data: _.merge({}, tabFormData, value_without_id),
      // data: {
      //   ...tabFormData,
      //   ...value_without_id,
      // },
    }

    if (values?.source_table === 'security_global') {
      let { users, locations, templates, domains, contrators, messages } = values
      storeUserSecurityOverviewDropdownPnSubmit({ users, locations, templates, domains, contrators, messages })
    }

    dispatch(clearCurrentRowData())

    dispatch(setCurrentRow(_.merge({}, tabFormData, value_without_id)))

    if (formSubmitButton.available && formSubmitButton.url) {
      let updatedValues = values
      if (openModal.action && openModal.action.post_data && openModal.action.post_data.length > 0) {
        openModal.action.post_data.map((item) => {
          if (openModal.pageData && openModal.pageData.length > 0) {
            openModal.pageData.map((item1) => {
              if (item1.name === item) {
                updatedValues = { ...updatedValues, [item]: item1.value }
              }
            })
          }
        })
      }

      dispatch(setQueue({ url: formSubmitButton.url, payload: { data: updatedValues } }))
    } else {
      const mainGridName = getMainGridNameByParentPageName(parentPageName)
      const connectTemplateDataOfCurrentForm = connectTemplateDataRedux[parentPageName]?.[mainGridName]
      let pageIndex, rowIndex
      Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
        Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
          if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.id === new_row_data?.data?.parent_current_row_id) {
            pageIndex = key
            rowIndex = rowKey
          }
        })
      })

      if (!pageIndex && !rowIndex) {
        Object.keys(connectTemplateDataOfCurrentForm)?.map((key) => {
          Object.keys(connectTemplateDataOfCurrentForm[key]?.data?.data)?.map((rowKey) => {
            if (connectTemplateDataOfCurrentForm[key]?.data?.data[rowKey]?.temporary_id === new_row_data?.data?.temporary_id) {
              pageIndex = key
              rowIndex = rowKey
            }
          })
        })
      }

      let connectTemplateDataReduxNeedToUpdate = {
        ...connectTemplateDataRedux,
      }
      connectTemplateDataReduxNeedToUpdate[parentPageName] = {
        ...connectTemplateDataReduxNeedToUpdate[parentPageName],
      }
      connectTemplateDataReduxNeedToUpdate[parentPageName][mainGridName] = {
        ...connectTemplateDataReduxNeedToUpdate[parentPageName]?.[mainGridName],
      }

      connectTemplateDataReduxNeedToUpdate[parentPageName][mainGridName][pageIndex]['data']['data'][rowIndex] = new_row_data?.data
      if (!isDirectUpdateConnectTemplate(parentPageName)) {
        dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxNeedToUpdate))
      }

      if (!isModalAction) {
        dispatch(setTabFormData({ ...new_row_data }))
      }
      dispatch(closeOpenModal())
      const data = {
        form_name: formData.name,
        values: { ...values, ...form },
        domain_id: domain_id,
        tab_name: currentTabData?.name,
        parent_page_name: parentPageName,
      }
      if (values.api_template && values.source_table) {
        data.api_template = values.api_template
        data.source_table = tabFormDataSourceTable
      }

      if (currentRow?.length === 0 || (currentRow?.length > 0 && currentRow?.[currentRow?.length - 1]?.id)) {
        if (formData?.instant_update) {
          request('save-default-form-data', 'POST', data).then(() => {
            dispatch(fetchUpdateApiData())
          })
        } else {
          updateSaveDefaultFormData(values?.id, data?.source_table, data?.form_name, {
            url: 'save-default-form-data',
            payload: { data },
          })
          //  dispatch(
          //    setQueue({
          //      url: 'save-default-form-data',
          //      payload: { data },
          //    })
          //  )
          dispatch(updateSubmittedChangedField())
        }
      } else if (currentRow?.length > 0 && !currentRow?.[currentRow?.length - 1]?.id && currentRow?.[currentRow?.length - 1]?.temporary_id) {
        if (queues?.length > 0) {
          const parentTable = parentTableDataInRedux?.[0]?.source_table
          updateSyncForFormUpdateByReactId(currentRow?.[currentRow?.length - 1]?.temporary_id, parentTable, tabFormDataSourceTable, values)
        } else {
          const payload = {
            ...data,
            parent_page_name: parentPageName,
            values: {
              ...data?.values,
              temporary_id: currentRow?.[currentRow?.length - 1]?.temporary_id,
            },
          }
          if (formData?.instant_update) {
            request('save-default-form-data', 'POST', payload).then(() => {
              dispatch(fetchUpdateApiData())
            })
          } else {
            dispatch(
              setQueue({
                url: 'save-default-form-data',
                payload: { data: payload },
              })
            )
            dispatch(updateSubmittedChangedField())
          }
        }
      }

      // dispatch(
      //   submitCommonApi()
      // )
    }
    dispatch(
      setNotification({
        type: 'success',
        message: 'Form is updating',
        status: 200,
      })
    )

    if (!isSaveButtonClicked.current) {
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
      dispatch(clearPageTab())
    }
    isSaveButtonClicked.current  = false
    // setIsSaveButtonClicked(false)
  }
  const [render, ReRender] = useState(false)
  useEffect(() => {
    ReRender(!render)
  }, [coreData])

  useEffect(() => {
    setFormData({})
    allFields?.map((field, key) => {
      if (field.component_name === 'submit_api_button') {
        setFormSubmitButton({
          available: true,
          url: field.api,
        })
      }
    })
    // updateFields(allFields)
  }, [coreData, allFields])

  useEffect(() => {
    const promises = form?.data_validations?.map(async (validation) => {
      const parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
      const parent_table_row_id = currentRow[0]?.id || currentRow?.[0]?.temporary_id
      let formValue = {
        ...formFieldValue,
      }
      const result = await FormDataValidation(
        formValue,
        validation,
        parent_table_row_id,
        parent_table,
        sourceTable,
        currentRow?.[1]?.id || currentRow?.[1]?.temporary_id
      )
      return result
    })
    if (promises) {
      Promise.all(promises).then((results) => {
        let errorCheckArray = []
        results?.forEach((result) => {
          if (Array.isArray(result)) {
            errorCheckArray = [...errorCheckArray, ...result]
          } else {
            errorCheckArray?.push(result)
          }
        })
        const failedValidation = errorCheckArray?.filter((item) => !item?.validation)
        setValidationErrorOnFieldValueChange(failedValidation)
      })
    }
  }, [formFieldValue])

  return (
    <Fragment>
      {/* {isPrintVisible && (
        <ReactButton className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`} onClick={onPrint}>
          <IconSet icon='fa-regular fa-print' color='light' />
        </ReactButton>
      )} */}
      {isPrintView && (
        <ReactButton className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`} onClick={onBackToForm}>
          <IconSet icon='fa-light fa-arrow-left' color='light' />
        </ReactButton>
      )}

      {isPrintView && <PrintForm data={tabFormData} formData={formData} cmsTabKey={cmsTabKey} />}
      {!isPrintView && (
        <AvForm onValidSubmit={(event, values) => handleSubmit(event, values)} className='pv-1'>
          {/* {set twoCol value to add section} */}

          <RenderFields
            allForm={[formData]}
            twoCol={true}
            noTitle={true}
            isModalAction={isModalAction}
            isModel={isModel}
            content={content}
            parentPageName={parentPageName}
            pageName={pageName}
            defaultFormValue={defaultFormValue}
            source_table={sourceTable}
            formFieldValue={formFieldValue}
            setFormFieldValue={setFormFieldValue}
          />

          {!formSubmitButton.available &&
            formData?.name !== 'new_alarms' &&
            allFields?.length > 0 &&
            checkPermission('edit', pageName, null, true) && (
              <div className='float-right'>
                <Button
                  color='secondary'
                  variant='outlined'
                  // disabled={validationErrorOnFieldValueChange?.length > 0}
                  size='lg'
                  className='btn rounded-pill mt-2 text-danger border-danger mr-3 subtitle1'
                  style={{ fontWeight: 700, width: '8rem', marginBottom: '2rem', background: 'transparent' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    dispatch(clearCurrentRowData())
                    dispatch(clearParentTableData())
                    dispatch(clearPageTab())
                  }}>
                  Close
                </Button>
                <Button
                  color='primary'
                  // disabled={validationErrorOnFieldValueChange?.length > 0}
                  id='form-save-btn'
                  size='lg'
                  className='btn text-white rounded-pill mt-2 mr-2 subtitle1'
                  style={{ background: primary_color, fontWeight: 700, width: '8rem', marginBottom: '2rem' }}
                  onClick={(e) => {
                    isSaveButtonClicked.current = true
                    // setIsSaveButtonClicked(true)

                    setTimeout(() => {
                      document.getElementById('submitAndCloseButtonForm').click()
                    }, 500)
                  }}>
                  Save
                </Button>
                <Button
                  color='primary'
                  // disabled={validationErrorOnFieldValueChange?.length > 0}
                  size='lg'
                  className='btn text-white rounded-pill mt-2 subtitle1'
                  style={{ background: primary_color, fontWeight: 700, width: '12rem', marginBottom: '2rem' }}
                  id='submitAndCloseButtonForm'>
                  Save & Close
                </Button>
              </div>
            )}
        </AvForm>
      )}
    </Fragment>
  )
}
export default connect(select)(CMSForm)

// <button
//                   className='btn rounded-pill mt-2 text-danger border-danger mr-3'
//                   type='button'
//                   onClick={(e) => {
//                     e.preventDefault()
//                     e.stopPropagation()
//                     dispatch(clearCurrentRowData())
//                     dispatch(clearParentTableData())
//                     dispatch(clearPageTab())
//                   }}
//                   style={{ fontWeight: 700, width: '8rem', marginBottom: '2rem' }}>
//                   Cancel
//                 </button>

//                 <button
//                   className='btn text-white rounded-pill mt-2 mr-2'
//                   type='button'
//                   onClick={(e) => {
//                     setIsSaveButtonClicked(true)
//                     setTimeout(() => {
//                       document.getElementById('submitAndCloseButtonForm').click()
//                     }, 500)
//                   }}
//                   style={{ background: primary_color, fontWeight: 700, width: '8rem', marginBottom: '2rem' }}>
//                   Save
//                 </button>
//                 <button
//                   className='btn text-white rounded-pill mt-2'
//                   type='button'

//                   id='submitAndCloseButtonForm'
//                   style={{ background: primary_color, fontWeight: 700, width: '12rem', marginBottom: '2rem' }}>
//                   Save & Close
//                 </button>
