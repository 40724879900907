import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import './mergeUser.scss'
import { primary_color } from '../../Helper/uiHelper'
import useFetchUserForMergeTable from '../../Queries/MergeUser/useFetchUserForMergeTable'
import Loader from 'react-loaders'

const DUMMY_DATA = [
  { id: 1, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890' },
  { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '234-567-8901' },
  { id: 3, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890' },
  { id: 4, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '234-567-8901' },
  { id: 5, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890' },
  { id: 6, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '234-567-8901' },
  { id: 7, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890' },
  { id: 8, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '234-567-8901' },
  { id: 9, name: 'John Doe', email: 'john.doe@example.com', phone: '123-456-7890' },
  { id: 10, name: 'Jane Smith', email: 'jane.smith@example.com', phone: '234-567-8901' },

  // Add more data here...
]


const checkUserSelected = (users,uuid) =>{
  if(users?.includes(uuid)){
    return true
  }else{
    return false
  }

}

const MergeUserTable = ({ toggle, setMergedUsers, mergedUsers }) => {
  const [searchName, setSearchName] = useState('')
  const [searchEmail, setSearchEmail] = useState('')
  const [searchPhone, setSearchPhone] = useState('')
  const [page, setPage] = useState()
  const [id, setId] = useState()

  const [selectedUser, setSelectedUser] = useState([])

  const mergedUserUUID = mergedUsers?.map((item)=>item?.uuid)


  const handleUserSelection = (checked, user) => {
    if (checked) {
      const newUser = [...selectedUser]
      newUser?.push(user)
      setSelectedUser(newUser)
    } else {
      const newUser = [...selectedUser]
      let index
      newUser?.map((item, i) => {
        if (item?.uuid === user?.uuid) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedUser(newUser)
    }
  }

  const { data: userData, isLoading } = useFetchUserForMergeTable({ name: searchName, page, email: searchEmail, phone_number: searchPhone, id })

  const selectedUserUUID = selectedUser?.map((item) => item?.uuid)


  return (
    <div className='px-5 mt-4 merge-user-table'>
      <Table bordered hover>
        <thead>
          <tr>
            <th></th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Id
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Name
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Email
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Phone number
            </th>
          </tr>
          <tr>
            <th></th>
            <th>
              <Input type='text' placeholder='Search by id' value={id} onChange={(e) => setId(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Search by name' value={searchName} onChange={(e) => setSearchName(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Search by email' value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Search by phone number' value={searchPhone} onChange={(e) => setSearchPhone(e.target.value)} />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                  <Loader type='ball-pulse' color='blue' />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {userData?.data?.data.map((item) => {
                if (mergedUserUUID?.includes(item?.uuid)) return
                  return (
                    <tr key={item.id} className={mergedUserUUID?.includes(item?.uuid) && 'disabled-pill'}>
                      <td>
                        <input
                          type='checkbox'
                          checked={checkUserSelected(selectedUserUUID, item?.uuid)}
                          onChange={(e) => handleUserSelection(e.target.checked, item)}></input>
                      </td>
                      <td className='subtitle1'>{item.id}</td>
                      <td className='subtitle1'>{item.full_name}</td>
                      <td className='subtitle1'>{item.email}</td>
                      <td className='subtitle1'>{item.phone_number || item?.number}</td>
                    </tr>
                  )
              })}
            </>
          )}
        </tbody>
      </Table>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {userData?.data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(userData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(userData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
          {/* <PaginationItem disabled={parseInt(userData?.data?.current_page) === 1}>
            <PaginationLink first onClick={() => setPage()} />
          </PaginationItem>
          <PaginationItem disabled={parseInt(userData?.data?.current_page) === 1}>
            <PaginationLink previous onClick={() => setPage(parseInt(userData?.data?.current_page) - 1)} />
          </PaginationItem>
          {parseInt(userData?.data?.total) && (
            <>
              {[...Array(parseInt(userData?.data?.total))].map((_, index) => (
                <PaginationItem active={parseInt(userData?.data?.current_page) === index + 1} key={index}>
                  <PaginationLink onClick={() => setPage(index + 1)}>{index + 1}</PaginationLink>
                </PaginationItem>
              ))}
            </>
          )}

          <PaginationItem disabled={parseInt(userData?.data?.current_page) === parseInt(userData?.data?.total)}>
            <PaginationLink next onClick={() => setPage(parseInt(userData?.data?.current_page) + 1)} />
          </PaginationItem>
          <PaginationItem disabled={parseInt(userData?.data?.current_page) === parseInt(userData?.data?.total)}>
            <PaginationLink last onClick={() => setPage(parseInt(userData?.data?.total))} />
          </PaginationItem> */}
        </Pagination>
      </div>

      <div className='d-flex justify-content-between' style={{ width: '70%', float: 'right' }}>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger'
          type='button'
          onClick={() => {
            toggle()
          }}
          style={{ fontWeight: 700, width: '48%' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={() => {
            setMergedUsers([...mergedUsers, ...selectedUser])
            toggle()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
          Merge
        </button>
      </div>
    </div>
  )
}

export default MergeUserTable
