import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { request } from '../../../Redux/Sagas/requests/api'
import { useSelector } from 'react-redux'
import { isSuccessResponse } from '../../../helper'
import { primary_color } from '../../../Helper/uiHelper'

const LinkCategory = ({ setLinkCategroyModel }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    request('get-categories-by-domain-id', 'POST', { domain_id: currentRow?.[0]?.id }).then((res) => {
      if (isSuccessResponse(res)) {
        setData(res?.data?.data)
      }
      setLoading(false)
    })
  }, [])

  const onChange = (checked, id) => {
    const newData = data?.map((item) => {
      if (item?.categories_id === id) {
        return {
          ...item,
          linked: checked,
        }
      } else {
        return item
      }
    })
    setData(newData)
  }

  const onSave = () => {
    request('update-domain-categories', 'POST', { domain_id: currentRow?.[0]?.id, categories: data }).then((res) => {
      if (isSuccessResponse(res)) {
        //
      }
      setLinkCategroyModel(false)
    })
  }
  return (
    <Modal
      isOpen={true}
      centered={true}
      toggle={() => {
        setLinkCategroyModel()
      }}
      style={{ width: '30rem', boxShadow: 'none', maxWidth: '50rem' }}>
      <ModalHeader style={{ backgroundColor: '#FFF', border: 'none' }}>{loading && 'Loading...'}</ModalHeader>
      <ModalBody className='px-5 pt-1'>
        {data?.length > 0 &&
          data?.map((item) => {
            return (
              <div className='my-3 mr-4' style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <input
                  checked={item?.linked}
                  type='checkbox'
                  id='tier1'
                  className='subtitle1'
                  onChange={(e) => {
                    onChange(e.target.checked, item?.categories_id)
                  }}
                />
                <div>{item?.name}</div>
              </div>
            )
          })}
      </ModalBody>
      <ModalFooter>
        {' '}
        <div className='d-flex justify-content-between'>
          <button
            className='btn text-white rounded-pill mt-2 mr-2'
            type='button'
            onClick={() => {
              onSave()
            }}
            style={{ background: primary_color, fontWeight: 700, width: '7rem' }}>
            Save
          </button>
          <button
            className='btn rounded-pill mt-2 text-danger border-danger'
            type='button'
            style={{ fontWeight: 700, width: '7rem' }}
            onClick={() => setLinkCategroyModel()}>
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default LinkCategory
