import { Button } from 'reactstrap'

const TransactionLogAction = ({ onClick, gridRelatedData }) => {
  let refundList = gridRelatedData?.gridData?.data?.filter((item) => item.status?.toLowerCase() === 'waiting for approval')
  return (
    <>
      <Button
        className='bg-warning px-2 rounded cursor-pointer text-white'
        disabled={refundList?.length > 0 ? true : false}
        style={{ border: 'none', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
        onClick={() => {
          onClick()
        }}>
        Refund Request
      </Button>
    </>
  )
}

export default TransactionLogAction
