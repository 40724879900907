import React from 'react'
import SweetAlert from 'sweetalert-react'
import { Alert } from 'reactstrap'
import { renderToStaticMarkup } from 'react-dom/server'
import { connect, useDispatch } from 'react-redux'
import { clearNotification } from '../Redux/Reducers/notificationHandling'

const select = (state) => ({
  errors: state.NotificationHandling.errors,
})

export const ListErrors = ({ errors }) => {

  return errors.map((error) => (
    <Alert className='show' color='danger'>
      {error}
    </Alert>
  ))
}

const ErrorAlert = ({ errors }) => {
  const dispatch = useDispatch()
  const handleClearErrors = () => {
    dispatch(clearNotification())
  }
  const updateErrors = []
  for (const key in errors) {
    updateErrors.push(`${key}: ${errors[key][0]}`)
  }

  return (
    <SweetAlert
      title='Alert!'
      text={renderToStaticMarkup(<ListErrors errors={updateErrors} />)}
      html
      show={updateErrors.length > 0}
      type='error'
      onConfirm={() => handleClearErrors()}
    />
  )
}

export default connect(select)(ErrorAlert)
