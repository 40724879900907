import { Fragment, useEffect, useState } from 'react'
import { CustomInput, FormGroup, Label } from 'reactstrap'
import CommonFields from './commonFields'

function Radio({ item, answers, setAnswers, requiredError }) {
  const [value, setValue] = useState(null)
  const [selectOptions, setSelectOptions] = useState()

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  useEffect(() => {
    if (item.item_answer_responses?.length > 0) {
      setValue(item.item_answer_responses[0].item_answer_id)
    }
    const options = []
    {
      item.item_answers?.map((item, key) => {
        options.push({ value: item.id, label: item.answer })
      })
    }
    setSelectOptions(options)
  }, [])

  useEffect(() => {

    setValue(current_answer_object?.answer_id)
  }, [JSON.stringify(current_answer_object)])

  const onValueChange = (value) => {
    setValue(value)

    const selectedOption = selectOptions?.filter((item) => item?.value === value)
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          answer_id: value,
          response_text: selectedOption[0]?.label,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  return (
    <Fragment>
      <div>
        <FormGroup>
          <Label>{item.question}?</Label>
          <FormGroup>
            <div>
              {selectOptions?.map((i, key) => {
                return (
                  <CustomInput
                    key={key}
                    id={i.value}
                    checked={value === i.value}
                    type='radio'
                    name='radio_question'
                    label={i.label}
                    onChange={() => onValueChange(i.value)}
                  />
                )
              })}
            </div>
          </FormGroup>
        </FormGroup>
        <CommonFields item={item} selectedAnswer={value} answers={answers} setAnswers={setAnswers} current_answer_object={current_answer_object} />
        {item?.validations?.includes('required') && requiredError && (!current_answer_object?.response_text || !current_answer_object?.answer_id) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
      </div>
    </Fragment>
  )
}

export default Radio
