import React, { useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'

import {
  addChangedFieldIdInReduxByFormName,
  adddChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  checkIsDate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
  tidyName,
  validateForAv,
} from '../../../helper'
import ToggleField from './toggleField'
import { connect, useSelector } from 'react-redux'
import { addChangedFieldIdByFormName } from '../../../Redux/Reducers/CMS'
import moment from 'moment'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClipboard, faClock, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard'

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
  currentFormData: state.CMS.coreData.currentFormData,
})

const getInputType = (data) => {
  let type = 'text'
  if (data?.field_id === 'order' || data?.field_id === 'discount' || data?.field_id === 'issued') {
    type = 'number'
  } else if (data?.field_id === 'details') {
    type = 'textarea'
  } else if (data?.field_id === 'password') {
    type = 'password'
  } else if (data?.field_id === 'email') {
    type = 'email'
  }

  return type
}

const CMSInput = ({
  data = {},
  toggleActions = [],
  cmsEditMode,
  id = '',
  value = '',
  currentRow,
  onValueChange = () => {},
  formName,
  parentPageName,
  pageName,
  formFieldValue,
  currentFormData,
  setFormFieldValue,
  onChangeValue,
}) => {
  const [fieldValue, updateValue] = useState(value)
  const [isFieldDisabled, setIsFieldDisabled] = useState(false)
  const [isValueCopied, setIsValueCopied] = useState(false)

  useEffect(() => {
    const disabled_if = data?.field_setting?.disable_if

    if (!Array.isArray(disabled_if) && disabled_if) {
      let isFieldDisabled = false
      Object.keys(disabled_if).map((key) => {
        let disabled_field_value
        if (currentRow?.length > 0) {
          disabled_field_value = currentRow?.[currentRow?.length - 1]?.[key]
        } else {
          disabled_field_value = currentFormData?.[key]
        }
        if (disabled_field_value == disabled_if[key]) {
          isFieldDisabled = true
        }
      })

      setIsFieldDisabled(isFieldDisabled)
    }
  }, [formFieldValue])
  useEffect(() => {
    let valueToUpdate = value
    if (getInputType(data) === 'number' && value && typeof value === 'string') {
      valueToUpdate = parseInt(value?.replace(/\s+/g, ''))
    }

    updateValue(valueToUpdate ? valueToUpdate : '')
    // getInputType
  }, [data, value])

  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (fieldValue === value && fieldValue) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (fieldValue) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [fieldValue])

  let isFieldVisible = true

  if (data?.field_setting?.depend_fields && data?.field_setting?.depend_fields?.length > 0 && currentRow) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      if (currentRow[currentRow?.length - 1]) {
        return checkSingleCondition(
          dependForm?.condition,
          currentRow[currentRow?.length - 1][dependForm?.dependent_field],
          parseInt(dependForm?.value)
        )
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  let isNumaricField = false
  data.component_call_validation?.forEach((item) => {
    if (item?.validation_type === 'number' || item?.validation_type === 'numaric') {
      isNumaricField = true
    }
  })
  if (data?.field_id === 'order') {
    isNumaricField = true
  }

  if (!isFieldVisible) return

  return (
    <>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)} className='subtitle1'>
        {tidyName(cms(data.cms_key)) || tidyName(data?.title)}{' '}
        {data.component_call_fields && <ToggleField data={data} toggleActions={toggleActions} />}
      </label>
      {data?.field_id === 'rate' && isNumaricField ? (
        <>
          {' '}
          <InputGroup>
            {/* <AvField type='password' name='password' label='password' required /> */}
            <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
              <div className='input-dollar-icon input-group-text'>
                <FontAwesomeIcon icon={faDollarSign} />
              </div>
            </InputGroupAddon>
            <Input
              type={`'text`}
              onChange={(e) => {
                setIsValueCopied(false)
                updateValue(e.target.value)
                onValueChange(data?.field_id, e.target.value)
                setFormFieldValue((formValue) => {
                  let updatedValue = {
                    ...formValue,
                  }
                  updatedValue[data?.field_id] = e.target.value

                  return updatedValue
                })
              }}
              id={id}
              value={fieldValue}
            />
            {/* removed by richard */}
            {data?.field_setting?.is_copy_allowed && false && (
              <InputGroupAddon addonType='append' style={{ cursor: 'pointer' }}>
                <div className='input-group-text' onClick={() => navigator.clipboard.writeText(fieldValue)}>
                  <CopyToClipboard
                    text={fieldValue}
                    onCopy={() => {
                      setIsValueCopied(true)
                      setTimeout(() => {
                        setIsValueCopied(false)
                      }, 2000)
                    }}>
                    <FontAwesomeIcon icon={isValueCopied ? faCheck : faClipboard} />
                  </CopyToClipboard>
                </div>
              </InputGroupAddon>
            )}
          </InputGroup>
          <AvField
            type='hidden'
            value={fieldValue}
            validate={data.component_call_validation && !isFieldDisabled ? validateForAv(data.component_call_validation, data.field_id) : {}}
            disabled={data.disabled === 1 || isFieldDisabled ? true : false}
            name={data.field_id || data?.name || 'no_name'}
          />
        </>
      ) : (
        <AvField
          type={getInputType(data)}
          name={data.field_id || data?.name || 'no_name'}
          id={id}
          placeholder={tidyName(cms(data.cms_key))}
          value={fieldValue}
          className={isFieldInSync ? 'blue-border subtitle1' : 'subtitle1'}
          // value={fieldValue}
          onClick={() => (cmsEditMode ? editCMS(data.cms_key) : null)}
          onChange={(e) => {
            updateValue(e.target.value)
            onValueChange(data?.field_id, e.target.value)
            setFormFieldValue((formValue) => {
              let updatedValue = {
                ...formValue,
              }
              updatedValue[data?.field_id] = e.target.value
              return updatedValue
            })
          }}
          validate={data.component_call_validation && !isFieldDisabled ? validateForAv(data.component_call_validation, data.field_id) : {}}
          disabled={data.disabled === 1 || isFieldDisabled ? true : false}
        />
      )}
    </>
  )
}

export default connect(select)(CMSInput)
