import { useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { addDataStatusSet } from '../../../../Redux/Reducers/CMS'
import moment from 'moment'

const LockerStatus = ({ row, cell, gridRelatedData }) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const releaseLocker = () => {
    setIsLoading(true)
    request('release-locker', 'POST', { item_id: row?.item_id }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker released successfully!' }))
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
      }
    })
    setIsLoading(false)
    setIsModalOpen(false)
    setTimeout(() => {
      gridRelatedData.updateRefreshGrid(true)
    }, 500)
  }


  return (
    <td
      {...cell.getCellProps()}
      style={{ ...cell.column.commonStyle }}
      className={`rt-td bg-${cell.background_color}`}
      // className={'rt-td bg-' + cell.background_color + isCellInSync ? ' blue-border' : ''}
    >
      <div className='d-flex justify-content-between align-items-center' style={{ flex: 1 }}>
        <div>{row?.status}</div>
        {(row?.status === 'Booked' || row?.status === 'Reserved') && (
          <i className='fa-solid fa-arrow-right cursor-pointer' style={{ color: primary_color }} onClick={() => setIsModalOpen(true)}></i>
        )}
      </div>
      <Modal toggle={() => setIsModalOpen(false)} isOpen={isModalOpen} centered={true} style={{ width: '40vw', boxShadow: 'none', maxWidth: '60vw' }}>
        <ModalHeader className='h4' style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem', color: primary_color }}>
          {row?.status === 'Booked' ? (
            <>
              <div className='text-center'>
                <span className='font-weight-bold'>This locker is Booked by </span>
                <span style={{ color: '#00000099' }}>
                  {row?.first_name} {row?.last_name}
                </span>{' '}
                {' on '}
                <span>{moment(row?.updated_at).format('Do MMM YY')}</span>
              </div>
              <div className='font-weight-bold text-center'>Do you wish to go to orders?</div>
            </>
          ) : (
            <>
              <div className='text-center'>
                {row?.first_name ? (
                  <>
                    <span className='font-weight-bold '>This locker is reserved to </span>
                    <span style={{ color: '#00000099' }}>{`${row?.first_name} (${row?.booking_reservation_id})`}</span>
                    <span className='font-weight-bold '>{' till '}</span>
                    <span style={{ color: '#00000099' }}>{moment(row?.post_time).format('Do MMM YY')}</span>
                  </>
                ) : (
                  <>
                    {' '}
                    <span className='font-weight-bold '>This locker is reserved with Booking reservation id </span>
                    <span style={{ color: '#00000099' }}>{row?.booking_reservation_id}</span>
                    <span className='font-weight-bold '>{' till '}</span>
                    <span style={{ color: '#00000099' }}>{moment(row?.post_time).format('Do MMM YY')}</span>
                  </>
                )}
              </div>
              <div className='font-weight-bold text-center'>Do you wish to release it?</div>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger'
              type='button'
              onClick={() => {
                setIsModalOpen(false)
              }}
              style={{ fontWeight: 700, width: '48%' }}>
              Cancel
            </button>
            <button
              className='btn text-white rounded-pill mt-2'
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                if (row?.status === 'Booked') {
                  setIsModalOpen(false)
                } else {
                  releaseLocker()
                }
              }}
              style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
              {row?.status === 'Booked' ? 'Go to Orders' : isLoading ? 'Releasing' : 'Release'}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </td>
  )
}

export default LockerStatus
