import moment from 'moment'
import { primary_color } from '../../Helper/uiHelper'
import { getObjectDifference, tidyName } from '../../helper'
import { getTabNameForLogs } from './logHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          New Data
        </div>
        <div className='p-4'>{getValue(updated_data)}</div>
      </div>
    </div>
  )
}

const DeleteLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)

  if (expandedId) {
    return <ShowDifference data={data} onExpand={onExpand}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div className='disabled-pill'>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has deleted  `}
        <span className='font-weight-bold'>{getValue(updated_data)}</span> {' in '} <span className='font-weight-bold'>{tab_name}</span>
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default DeleteLog
