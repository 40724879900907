import { container_width } from '../../../Helper/uiHelper'

export const getContainerPadding = (isDesktop, isFluid, isShowOnlyContent) => {
  if (isDesktop) {
    if (isFluid || isShowOnlyContent) {
      return '0'
    } else {
      return '6rem'
    }
  } else {
    return '0'
  }
}

export const getContainerWidth = (section) => {
  if (
    section?.widgets?.[0]?.component_name === 'hero_bg' ||
    section?.widgets?.[0]?.component_name == 'hero_search' ||
    section?.widgets?.[0]?.component_name == 'payment_success' ||
    section?.widgets?.[0]?.component_name == 'carousal' ||
    (section?.widgets?.[0]?.component_name === 'card' && (window.location.pathname?.includes('home') || window.location.pathname === '/'))
  ) {
    return
  } else {
    return container_width
  }
}
