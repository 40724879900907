import { useEffect, useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'
import RCTabs from '../../../Tabs/rcTabs'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useSelector } from 'react-redux'
import LockerMove from './lockerMove'
import moment from 'moment'

const MoveBooking = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const payment_id = currentRow?.[0]?.id

  const [activeTab, setActiveTab] = useState(0)
  const [unitData, setUnitData] = useState([])
  const [tabsData, setTabsData] = useState([])
  const [isPastAcademicYear, setIsPastAcademicYear] = useState(false)

  const fetchBookingData = () => {
    request('get-booking-by-payment-id', 'POST', { payment_id: payment_id && parseInt(payment_id) }).then((res) => {
      if (res?.data && res?.data?.units) {
        let items = JSON.parse(JSON.stringify(res?.data?.units))
        setUnitData(items)
        const tabs = getTabs(items, res?.data?.domain_id)
        setTabsData(tabs)
      }
    })
  }

  useEffect(() => {
    request('get-booking-by-payment-id', 'POST', { payment_id }).then((res) => {
      const units = res?.data?.units
      const formattedDate = moment(units?.[0]?.post_time, 'DD MMM, YYYY') // Parse the input date

      if (!formattedDate.isValid()) {
        const isFutureDate = formattedDate.isAfter(moment()) // Check if the date is greater than today
        if (!isFutureDate) {
          setIsPastAcademicYear(true)
        }
      }
    })
    fetchBookingData()
  }, [])

  const getTabs = (tabs, domain_id) => {
    return tabs?.map((item, index) => {
      return {
        title: `Pupil ${index + 1}`,
        getContent: () => {
          return <LockerMove domainId={domain_id} item={item} unitData={tabs} fetchBookingData={fetchBookingData}></LockerMove>
        },
      }
    })
  }

  if (isPastAcademicYear) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div>
          {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
          <div>
            <svg width='169' height='168' viewBox='0 0 169 168' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M166.609 137.671C171.993 147.003 165.235 158.667 154.483 158.667H14.5158C3.74254 158.667 -2.98388 146.985 2.38921 137.671L72.3735 16.3296C77.7597 6.99365 91.2501 7.01057 96.6267 16.3296L166.609 137.671ZM84.5001 112.584C77.0903 112.584 71.0834 118.591 71.0834 126.001C71.0834 133.41 77.0903 139.417 84.5001 139.417C91.9099 139.417 97.9168 133.41 97.9168 126.001C97.9168 118.591 91.9099 112.584 84.5001 112.584ZM71.7621 64.3581L73.9257 104.025C74.0269 105.881 75.5617 107.334 77.4205 107.334H91.5797C93.4385 107.334 94.9733 105.881 95.0745 104.025L97.238 64.3581C97.3474 62.3532 95.7511 60.6673 93.7433 60.6673H75.2566C73.2488 60.6673 71.6528 62.3532 71.7621 64.3581Z'
                fill='#EAEAEA'
              />
            </svg>
          </div>
        </div>
        <div className='body2'>You can't edit the booking for past academic year</div>
      </div>
    )
  }

  return (
    <div className='px-5 py-2'>
      <div className='body1 my-3 font-weight-bold' style={{ color: primary_color }}>
        Move Booking
      </div>

      <RCTabs
        items={tabsData}
        selectedTabKey={activeTab || 0}
        activeTab={activeTab}
        onChange={(e) => {
          setActiveTab(parseInt(e))
        }}
      />
    </div>
  )
}

export default MoveBooking
