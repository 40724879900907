import { AvForm } from 'availity-reactstrap-validation'
import RenderFields from '../../../../CMSComponent/Forms/renderFields'
import { useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'

const AddEditForm = ({ form, onCloseForm, defaultValue, blockDetails, setBlocketails }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formFieldValue, setFormFieldValue] = useState()
  const onUpdateLocker = (values) => {
    if (values.code.length != 4) {
      dispatch(setNotification({ type: 'error', message: 'Code should be of 4 digit' }))
      return
    }
    request('insert-codes', 'POST', { id: defaultValue?.id, ...values }).then((res) => {
      if (res?.status === 200 || res?.status === 202 || res?.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Code updated successfully!' }))
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
      }
      onCloseForm()
    })
  }
  return (
    <div className='m-4'>
      <AvForm
        onValidSubmit={(e, values) => {
          setLoading(true)
          onUpdateLocker(values)
          // onSubmitBlockDetails(values)
        }}>
        <RenderFields
          allForm={[form]}
          content={[form]}
          twoCol={true}
          defaultFormValue={defaultValue}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          disabled_field={['domain_id', 'location_id', 'name']}
          // defaultFormValue={blockDetails}
        />
        <div className='d-flex justify-content-end mt-5'>
          {' '}
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onCloseForm()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Cancel
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!loading}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default AddEditForm
