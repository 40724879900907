import React from 'react'
import { useHistory } from 'react-router'
import { Col, Row, Button, FormGroup, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import SliderFormWrapper from '../../Components/Form/sliderFormWrapper'

import { setForgotPassword, postForgotPassword } from '../../Redux/Reducers/user'
import { fetchThemeOptions } from '../../Redux/Reducers/themeOptions'
import { renderBlockColorClass, renderBlockColorStyle, renderBlockStyle, renderTextColorClass } from '../../helper'
import { primary_color } from '../../Helper/uiHelper'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const themeOptions = useSelector((state) => state.ThemeOptions)

  useEffect(() => {
    if (themeOptions.updatedAt === null) {
      dispatch(fetchThemeOptions())
    }
  }, [])

  const handleSubmit = (e, values) => {
    dispatch(setForgotPassword({ ...values, history }))
    dispatch(postForgotPassword())
  }
  const linkClassName = themeOptions?.primaryColor && renderTextColorClass(themeOptions?.primaryColor).split(' ')[0] + '-text btn'
  return (
    <SliderFormWrapper
      title={themeOptions?.forgotTitle || 'Forgot Password'}
      titleColor={themeOptions?.authTitleColor}
      messageColor={themeOptions?.authSubTitleColor}
      message={
        <span>
          {/* Forgot your Password? <span className='text-success'>Reset your password</span>{' '}
          <span>by completing the below form. You will be emailed to your registered email address a link to reset your password.</span> */}
          {themeOptions?.forgotSubTitle}
        </span>
      }>
      <AvForm onValidSubmit={(e, values) => handleSubmit(e, values)}>
        <Row form>
          <Col md={7}>
            <FormGroup>
              <AvField
                name='email'
                label='Email or Username'
                type='email'
                validate={{
                  email: { value: true, errorMessage: 'Please enter a valid email.' },
                  required: { value: true, errorMessage: 'This field is required.' },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className='divider' />

        <Row>
          <Col md={7}>
            <div className='mt-4 d-flex align-items-center'>
              <div className='mb-0'>
                <Link
                  to='/welcome/login'
                  className={linkClassName}
                  style={{ color: primary_color }}
                  //  style={renderBlockColorStyle(themeOptions?.primaryColor)}
                >
                  Sign in existing account
                </Link>
              </div>
              <div className='ml-auto'>
                <div className=''>
                  <Button
                    size='lg'
                    className='subtitle1'
                    style={{ background: primary_color, border: 'none' }}
                    // className={renderBlockColorClass(themeOptions?.primaryColor)}
                    // style={renderBlockStyle(themeOptions?.primaryColor)}
                  >
                    Recover Password
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </AvForm>
    </SliderFormWrapper>
  )
}
export default ForgotPassword
