import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './navigationButton.scss'
import useMediaQuery from '../../CustomHook/useMediaQuery'

const getClassNameForTab = (isActive, isVisited) => {
  if (isActive) {
    return 'wizard-pulse'
  } else if (isVisited) {
    return 'wizard-tab-visited'
  } else {
    return 'wizard-tab-unvisited'
  }
}

const CircleIcon = ({ icon, index, isActive, isVisited, setActivePage, activePage, pageData, page_uuid }) => {
  const location = useLocation()
  const history = useHistory()
  //add style for isActive and isVisited
  return (
    <div
      className={getClassNameForTab(isActive, isVisited)}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        const searchParams = new URLSearchParams(location.search)
        if (isVisited && index === 0) {
          searchParams.delete('page_id')
          searchParams.delete('parent_id')
          history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
          })
          setActivePage(pageData)
          return
        }
        if (isVisited) {
          searchParams.set('page_id', page_uuid)
          history.push(`?${searchParams.toString()}`)
          setActivePage(pageData?.child_pages[index])
        }
      }}>
      <i className={icon + ' position-relative '} style={{ scale: '0.5' }} />
    </div>
  )
}

const NavigationButton = ({ selectedItem, index, icon, title, isActive, isVisited, setActivePage, activePage, pageData, page_uuid }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  return (
    <>
    <div className={isDesktop ? 'mx-4' : ''} style={{ width: 'min-content' }}>
      <div className='d-flex align-items-center flex-column' style={{ gap: '0.75rem' }}>
        <CircleIcon
          pageData={pageData}
          icon={icon}
          index={index}
          isActive={isActive}
          isVisited={isVisited}
          setActivePage={setActivePage}
          activePage={activePage}
          page_uuid={page_uuid}
        />
      </div>
    </div>
    {isDesktop ? <p style={{ textAlign: 'center' , marginTop: 20, }} className='subtitle1'>{title}</p> : <></>}
    </>
  )
}

export default NavigationButton
