import { Button, Col, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import CMSDate from '../../../CMSComponent/Forms/Fields/date'
import { useState } from 'react'
import { AvForm } from 'availity-reactstrap-validation'
import { request } from '../../../Redux/Sagas/requests/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const PaymentLinkSend = ({ paymentLinkData, is_admin }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const [isChangingDate, setIsChangingDate] = useState(false)
  const [isResendingLink, setIsResendingLink] = useState(false)
  const [isCopyingLink, setIsCopyingLink] = useState(false)

  const [formFieldValue, setFormFieldValue] = useState({
    payment_expiry: new Date(),
  })

  const onChangePaymentExpiry = () => {
    setIsChangingDate(true)
    request('update-expiry-date', 'POST', { link_id: paymentLinkData?.id, expiry_date: formFieldValue?.payment_expiry }).then((res) => {
      if (res.status == 200 || res.status === 202) {
        setIsChangingDate(false)
      }
    })
  }

  const onResendLink = () => {
    setIsResendingLink(true)
    request('resend-payment-link', 'POST', { link_id: paymentLinkData?.id }).then((res) => {
      if (res.status == 200 || res.status === 202) {
        setIsResendingLink(false)
      }
    })
  }

  return (
    <div className='d-flex'>
      <Row
        style={{
          flex: 1,
          height: 'fit-content',
          paddingBottom: isDesktop ? '0rem' : '0',
          paddingLeft: isDesktop ? '0' : '1rem',
          width: 'calc(100% - 300px)',
        }}
        className='ml-0 mr-0 w-100 main-page-container'>
        <Col md={9} className='ml-auto mr-auto' style={{ maxWidth: '1440px' }}>
          <div className='d-flex mb-4 align-items-center'>
            <div
              style={{ backgroundColor: `${primary_color}33`, borderRadius: '10px', width: '8rem', height: '8rem' }}
              className='mr-3 d-flex justify-content-center align-items-center'>
              <i class='fa-solid fa-envelope-circle-check' style={{ color: primary_color, fontSize: '4rem' }}></i>
            </div>
            <div className='body1 font-weight-bold' style={{ color: primary_color, width: '28rem' }}>
              Email has been sent successfully to the customer. Waiting for payment to be received.{' '}
            </div>
          </div>
          <div>
            <AvForm className='d-flex'>
              <div className='mr-3'>
                <CMSDate
                  data={{ field_id: 'payment_expiry', cms_key: 'cms.payment_expiry_date' }}
                  formFieldValue={formFieldValue}
                  setFormFieldValue={setFormFieldValue}
                  value={formFieldValue?.payment_expiry}></CMSDate>
              </div>

              <Button
                // outline
                className='ml-3 subtitle1 rounded-pill'
                // variant='outline-primary' // Change the variant to the desired color
                disabled={!is_admin}
                onClick={() => {
                  onChangePaymentExpiry()
                }}
                style={{
                  borderColor: primary_color,
                  background: 'transparent',

                  paddingInline: isDesktop ? '4rem' : '3rem',
                  color: primary_color,
                  borderRadius: '30px',
                  marginTop: '2rem',
                  marginBottom: '0.8rem',
                }}>
                {!isChangingDate ? 'Apply' : 'Applying...'}
              </Button>
            </AvForm>
          </div>
          <div className='mt-2'>
            <AvForm className='d-flex'>
              <div className='mb-3 mr-3'>
                {' '}
                <Label>{'Payment Link'}</Label>
                <InputGroup>
                  <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
                    <div className='input-dollar-icon input-group-text'>
                      <FontAwesomeIcon icon={faLink} />
                    </div>
                  </InputGroupAddon>
                  <Input
                    // type={'number'}
                    onChange={(e) => {
                      // onChangeValue(e.target.value)
                    }}
                    id={'payment_link'}
                    value={paymentLinkData?.url}
                    disabled={true}
                  />
                </InputGroup>
              </div>

              <div
                style={{ backgroundColor: '#FFCE001A', color: '#FFCE00', marginTop: '2rem', marginBottom: '0.8rem' }}
                className='d-flex cursor-pointer align-items-center px-3'
                onClick={() => {
                  navigator.clipboard.writeText(paymentLinkData?.url)
                  setIsCopyingLink(true)
                  setTimeout(() => {
                    setIsCopyingLink(false)
                  }, 2000)
                }}>
                <div className='mr-3'>{isCopyingLink ? 'Copied Payment Link' : 'Copy Payment Link'}</div>
                <div>
                  <i class='fa-regular fa-copy'></i>
                </div>
              </div>
            </AvForm>
          </div>
          <div className='d-flex'>
            <button
              className='btn mt-4 d-flex justify-content-center'
              style={{ color: 'white', borderRadius: '30px', background: primary_color, padding: isDesktop ? '0.7rem 4.5rem' : '0.7rem 2rem' }}
              onClick={onResendLink}
              type='submit'>
              <span>{!isResendingLink ? 'Resend Mail' : 'Sending...'}</span>
              <i class='fa-solid fa-envelope ml-2' style={{ marginTop: 4 }}></i>
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentLinkSend
