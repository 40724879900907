import { useEffect, useState } from 'react'
import * as Icon from 'react-icons/io'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkImage, getColor } from '../helper'

const IconSet = (props) => {
  const [IonIcon, updateIconSet] = useState(() => Icon['IoMdSync'])
  const { navheadericon = false, bgColor, type, icon, style, color = 'none', className = '', fontWeight = 600 } = props

  const renderIcon = () => {
    switch (type) {
      case 'Pe7':
        const updateIcon = icon === null ? 'pe-7s-menu' : icon
        return (
          <i {...props} className={className + ' subtitle1 ' + updateIcon} style={{ ...style, fontWeight: fontWeight, color: getColor(color) }}></i>
        )
      case 'ion':
        return <IonIcon {...props} fontSize='23px' />
      case 'file':
        return (
          <>
            <div className='user-avatar' style={{ backgroundImage: 'url(' + process.env.REACT_APP_URL + icon + ')' }}></div>
            <FontAwesomeIcon className='ml-2 he-100 opacity-8' icon={faAngleDown} />
          </>
        )
      // case 'font_awswome':
      //   return <IonIcon {...props} fontSize='23px' />
      default:
        return <i {...props} className={className + ' ' + icon} style={{ ...style, color: getColor(color) }}></i>
    }
  }

  useEffect(() => {
    if (type === 'ion') {
      updateIconSet(() => Icon[icon])
    }
  })

  if (checkImage(icon) || !navheadericon) return renderIcon()
  const BGColor = bgColor ? bgColor : color
  return (
    <div className='icon-wrapper icon-wrapper-alt rounded-circle'>
      <div className={`icon-wrapper-bg bg-` + BGColor} />
      {renderIcon()}
    </div>
  )
}
export default IconSet
