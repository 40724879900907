import { useState } from 'react'
import { useSelector } from 'react-redux'
import LockerMove from '../Purchase/MoveBooking/lockerMove'

const ChangeLocker = ({ item, setChangeLocker, changeLocker, onChangeLockerInPupilPremium }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [unitData, setUnitData] = useState([item])
  return (
    <div className='m-4'>
      <LockerMove
        isPupilPremium={true}
        unitData={unitData}
        item={item}
        domainId={currentRow?.[0]?.id}
        setChangeLocker={setChangeLocker}
        changeLocker={changeLocker}
        onChangeLockerInPupilPremium={onChangeLockerInPupilPremium}></LockerMove>
    </div>
  )
}

export default ChangeLocker
