import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { getClonedData, getConnectTemplateReduxDataAfterInsert } from '../../../helper'
import { addDataStatusClear, addDataStatusSet, fetchUpdateApiData, setConnectTemplateDataRedux } from '../../../Redux/Reducers/CMS'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { setQueue } from '../../../Redux/Reducers/queue'
import { request } from '../../../Redux/Sagas/requests/api'

const ReplicateColumn = ({ row, pageName, content, parentPageName, isTab }) => {
  const [loading, setLoading] = useState(false)

  const pages = useSelector((state) => state.CMS.coreData.page)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const dispatch = useDispatch()

  const onCopy = () => {
    setLoading(true)
    const tabs = pages?.[parentPageName]?.component_call_buttons?.filter((item) => item.component_name !== 'hidden_tab').map((item) => item.name)
    const row_id = row?.row?.original?.id
    const clonedData = getClonedData(row?.row?.original)

    let payload = {
      data: clonedData,
      row_id: row_id,
      source_table: content?.source_table,
    }

    request('copy-row-data', 'POST', payload).then((res) => {
      setLoading(false)
      dispatch(fetchUpdateApiData())
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Data copied successfully!' }))
      } else {
        dispatch(setNotification({ type: 'error', message: res?.message || 'Something went wrong!' }))
      }
    })
    
    return

  }

  return (
    <>
      <Button size='sm' color={'primary'} disabled={loading}>
        {!loading ? (
          <i class='fa-regular fa-copy cursor-pointer' onClick={() => onCopy()}></i>
        ) : (
          <i class='fa-solid fa-spinner fa-spin cursor-pointer'></i>
        )}
      </Button>
    </>
  )

  return <span className={`badge badge-pill badge-info`} style={{ cursor: 'pointer' }} onClick={() => onCopy()}></span>
}

export default ReplicateColumn
