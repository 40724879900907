import { AvField } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import {
  addChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
  validateForAv,
} from '../../../helper'

// import Redactor X
import '../../../Vendor/redactorx/redactorx.css'
import RedactorX from '../../../Vendor/redactorx/redactorx.js'

const checkIsFieldVisible = (formFieldValue, field_id) => {
  if (field_id === 'termination_reason' && !formFieldValue?.termination_date) {
    return false
  }
  return true
}

const get_hide_toolbar = (option) => {
  const tool_bar_hide_array = []
  const tool_bar_hide_shortcut = []
  if (!option?.bold) {
    tool_bar_hide_array.push('bold')
    tool_bar_hide_shortcut.push('ctrl+b')
    tool_bar_hide_shortcut.push('meta+b')
  }
  if (option?.italic === false) {
    tool_bar_hide_array.push('italic')
    tool_bar_hide_shortcut.push('ctrl+i')
    tool_bar_hide_shortcut.push('meta+i')
  }
  if (option?.link === false) {
    tool_bar_hide_array.push('link')
    tool_bar_hide_shortcut.push('ctrl+k')
    tool_bar_hide_shortcut.push('meta+k')
  }
  if (option?.deleted === false) {
    tool_bar_hide_array.push('deleted')
    tool_bar_hide_shortcut.push('ctrl+k')
    tool_bar_hide_shortcut.push('meta+k')
  }
  if (option?.code === false) {
    tool_bar_hide_array.push('code')
    tool_bar_hide_shortcut.push('ctrl+k')
    tool_bar_hide_shortcut.push('meta+k')
  }

  return [tool_bar_hide_array, tool_bar_hide_shortcut]
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSRichText = ({ data, cmsEditMode, id, value, formName, currentRow, onChange = () => {}, formFieldValue, setFormFieldValue }) => {
  const hide_toolbar = get_hide_toolbar()
  const [enteredValue, setEnteredValue] = useState(value)

  useEffect(() => {
    const app = RedactorX('#' + id, {
      editor: {
        minHeight: data?.section_rowspan?.rowspan === 3 ? '237px' : '150px',
      },
      subscribe: {
        'editor.change': function (event) {
          setEnteredValue(event.params.html)

          onChange(event.params.html)
        },
      },
    })
    if (value !== enteredValue) {
      // app.editor.insertContent({ html: value })
    }
  })
  const update = (e) => {}

  useEffect(() => {
    const a = value
    const b = a?.replaceAll('<div', '<p')
    const new_value = b?.replaceAll('/div>', '/p>')
    setEnteredValue(() => new_value)
    const app = RedactorX('#' + id, {})
    if (new_value !== enteredValue) {
      app?.editor?.setContent({ html: new_value ? new_value : '' })
    }
  }, [value])

  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (enteredValue === value && enteredValue) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (enteredValue) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [enteredValue])

  if (!checkIsFieldVisible(formFieldValue, data?.field_id)) return
  return (
    <div className={data?.background_color || '' + `${isFieldInSync ? 'blue-border' : ''}`}>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)} className='subtitle1'>
        {cms(data?.cms_key)}
      </label>
      <textarea id={id} onChange={update} style={{ width: '100%' }} className='blue-border subtitle1' defaultValue={enteredValue}></textarea>
      <AvField
        type='hidden'
        name={data.field_id || 'rich-text'}
        value={enteredValue}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}></AvField>
    </div>
  )
}

export default connect(select)(CMSRichText)
