import { AvCheckbox, AvCheckboxGroup, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'reactstrap'
import { cms, tidyName } from '../../../helper'
import { request } from '../../../Redux/Sagas/requests/api'
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from 'react-icons/io'
import { primary_color } from '../../../Helper/uiHelper'

const DUMMY_DATA = [
  {
    value: 1,
    label: 'XYZ1',
  },
  {
    value: 2,
    label: 'XYZ2',
  },
  {
    value: 3,
    label: 'XYZ3',
  },
  {
    value: 4,
    label: 'XYZ4',
  },
]

const MultiCheckBox = ({ searchData, data, form, filterData, setFilterData }) => {
  const [selectOptions, updateOptions] = useState([])
  const [disableNavigation, setDisableNavigation] = useState({ prev: true, next: true })
  const [selectedOption, updateSelectedOption] = useState()
  const [pageNo, setPageNo] = useState(1)
  const isFirstRendered = useRef(false)
  const onChangeValue = (value, checked) => {
    const selected_data = selectedOption ? [...selectedOption] : []
    if (checked) {
      selected_data?.push(value)
    } else {
      const index = selected_data?.indexOf(value)
      selected_data?.splice(index, 1)
    }
    updateSelectedOption(selected_data)

    let newFilterData = {
      ...filterData,
    }
    newFilterData[data?.field_id] = selected_data

    setFilterData(newFilterData)
  }

  const fetchData = () => {
    setDisableNavigation({ prev: true, next: true })
    let payload = {}
    payload['uuid'] = form?.uuid
    payload['fields'] = [data?.field_id]
    payload['is_filter'] = true
    Object.keys(searchData)?.map((key) => {
      if (searchData[key]) {
        payload[`-filter-${key}`] = searchData[key]
      }
    })

    request('get-form-data', 'POST', payload, {}, { page: pageNo })
      .then((res) => {
        const options = []
        res?.data?.data?.map((item) => {
          if (data?.field_id?.includes('.')) {
            options?.push({
              value: item[data?.field_id?.split('.')?.[0]][data?.field_id?.split('.')?.[1]],
              label: item[data?.field_id?.split('.')?.[0]][data?.field_id?.split('.')?.[1]],
            })
          } else {
            options?.push({
              value: item[data?.field_id],
              label: item[data?.field_id],
            })
          }
        })
        //logic need to add
        updateOptions(options)
        setDisableNavigation({ prev: res?.data?.links?.prev ? false : true, next: res?.data?.links?.next ? false : true })
      })
      .catch((e) => console.error('Error in get-form-data: ', e))
  }

  const data_conection = data?.component_call_data_connection

  useEffect(() => {
    isFirstRendered.current ? fetchData() : (isFirstRendered.current = true)
  }, [pageNo, searchData])

  useEffect(() => {
    if (data_conection?.default_options) {
      let options = []
      if (Array.isArray(data_conection?.default_options)) {
        data_conection?.default_options?.map((item) => {
          options?.push({
            label: tidyName(item),
            value: item,
          })
        })
      } else {
        Object.keys(data_conection?.default_options)?.map((key) => {
          options?.push({
            value: key,
            label: data_conection?.default_options[key],
          })
        })
      }

      updateOptions(options)
    } else if (data_conection?.component_name === 'template_data') {
      fetchData()
    }
  }, [])
  return (
    <div className='mb-4'>
      <div className=' mt-3 mb-3 body2' style={{ color: primary_color }}>
        {cms(data?.cms_key)}
      </div>
      <AvForm>
        {/* <FormGroup check> */}
        <AvCheckboxGroup name={data?.field_id || 'dummy'}>
          {selectOptions?.map((item, key) => (
            <>
              <AvCheckbox
                key={item?.value}
                label={item?.label}
                value={item?.value}
                onChange={(e) => onChangeValue(e.target.value, e.target.checked)}
                checked={selectedOption?.includes(item?.value) ? true : false}
                className='mb-3'
              />
            </>
          ))}
        </AvCheckboxGroup>
        {(!disableNavigation.prev || !disableNavigation.next) && (
          <div className='d-flex justify-content-between'>
            <button
              className=' p-0 pb-3 btn d-flex align-items-center'
              disabled={disableNavigation.prev}
              style={{ visibility: disableNavigation.prev ? 'hidden' : 'visible' }}
              onClick={() => {
                // fetchData()
                setPageNo((prev) => prev - 1)
              }}>
              <IoMdArrowRoundBack className='mr-1' /> Prev
            </button>
            <button
              className=' p-0 pb-3 btn d-flex align-items-center'
              disabled={disableNavigation.next}
              style={{ visibility: disableNavigation.next ? 'hidden' : 'visible' }}
              onClick={() => {
                setPageNo((prev) => prev + 1)
              }}>
              Next <IoMdArrowRoundForward className='ml-1' />
            </button>
          </div>
        )}

        {/* </FormGroup> */}
      </AvForm>
    </div>
  )
}

export default MultiCheckBox
