export const export_button = {
  component_type: 'fields',
  is_layout: null,
  row_index: null,
  block_index: null,
  component_name: 'icon_name_button_select',
  permission: 'no_permission',
  name: 'schools_export',
  cms_key: 'field.schools_export',
  cms_tooltip: 'field.export_current_results_for_schools',
  data_connection: null,
  disabled: false,
  hidden: false,
  background_color: 'link',
  drop_down_options: [
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'danger',
      icon_type: 'font_awesome',
      icon: 'fas fa-file-pdf',
      cms_key: 'field.selected_to_pdf_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'pdf',
            data: 'selected_id',
          },
        },
      ],
    },
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'danger',
      icon_type: 'font_awesome',
      icon: 'far fa-file-pdf',
      cms_key: 'field.export_all_pdf_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'pdf',
            data: 'filters',
          },
        },
      ],
    },
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'success',
      icon_type: 'font_awesome',
      icon: 'fas fa-file-excel',
      cms_key: 'field.selected_to_spreadsheet_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'spreadsheet',
            data: 'selected_id',
          },
        },
      ],
    },
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'success',
      icon_type: 'font_awesome',
      icon: 'far fa-file-excel',
      cms_key: 'field.export_all_spreadsheet_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'spreadsheet',
            data: 'filters',
          },
        },
      ],
    },
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'primary',
      icon_type: 'font_awesome',
      icon: 'fas fa-file-word',
      cms_key: 'field.selected_to_doc_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'doc',
            data: 'selected_id',
          },
        },
      ],
    },
    {
      component_type: 'actions',
      component_name: 'drop_down_actions',
      color: 'primary',
      icon_type: 'font_awesome',
      icon: 'far fa-file-word',
      cms_key: 'field.export_all_doc_option',
      component_call_actions: [
        {
          component_type: 'actions',
          component_name: 'submit_api',
          url: 'connect-template-export',
          payload: {
            type: 'doc',
            data: 'filters',
          },
        },
      ],
    },
  ],
  icon_type: 'font_awesome',
  icon: 'fas fa-file-export',
}