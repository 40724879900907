import React, { useEffect, useState, Fragment } from 'react'

import {
  addChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
} from '../../../helper'
import { connect, useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import { setCurrentRow } from '../../../Redux/Reducers/CMS'
import { primary_color } from '../../../Helper/uiHelper'
const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
  currentFormData: state.CMS.coreData.currentFormData,
})

const CMSSwitch = ({
  data,
  cmsEditMode,
  id,
  label = true,
  currentRow,
  value,
  onChangeAction,
  currentFormData,
  formName,
  formFieldValue,
  setFormFieldValue,
  onSetFormFieldValue,
}) => {
  const [toggle, setTogle] = useState(false)
  const [isFieldDisabled, setIsFieldDisabled] = useState(false)
  useEffect(() => {}, [data])

  useEffect(() => {
    setTogle(value)

    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = value
      return updatedValue
    })
    onChangeAction('', data.component_call_actions, data.field_id, value)
  }, [value])

  useEffect(() => {
    const disabled_if = data?.field_setting?.disable_if

    if (!Array.isArray(disabled_if) && disabled_if) {
      let isFieldDisabled = false
      Object.keys(disabled_if).map((key) => {
        let disabled_field_value
        disabled_field_value = formFieldValue?.[key] || 0
        if (disabled_field_value == disabled_if[key]) {
          isFieldDisabled = true
        }
      })

      setIsFieldDisabled(isFieldDisabled)
    }
  }, [formFieldValue])

  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (toggle == value) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (toggle || toggle === false) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [toggle])

  const dispatch = useDispatch()

  const onChangeToggle = () => {
    if (isFieldDisabled) return
    setTogle(!toggle)
    onChangeAction('', data.component_call_actions, data.field_id, !toggle)
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = !toggle
      return updatedValue
    })
    // onChangeAction('', [], data?.field_id, !toggle)
    // if (currentRow[currentRow?.length - 1]) {
    //   const field_id = data?.field_id
    //   let new_row_data = {
    //     ...currentRow[currentRow?.length - 1],
    //   }
    //   new_row_data[field_id] = !toggle

    //   dispatch(setCurrentRow(new_row_data))
    // }
  }

  let isFieldVisible = true

  if (
    data?.field_setting?.depend_fields &&
    data?.field_setting?.depend_fields?.length > 0 &&
    ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData || formFieldValue)
  ) {
    const dependsData = data?.field_setting?.depend_fields

    const condition_array = dependsData?.map((dependForm) => {
      if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else {
        if (currentRow && currentRow[currentRow?.length - 1]) {
          return checkSingleCondition(
            dependForm?.condition,
            currentRow[currentRow?.length - 1][dependForm?.dependent_field],
            parseInt(dependForm?.value)
          )
        }
      }

      // if (currentFormData) {
      //   return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      // } else {
      //   return checkSingleCondition(
      //     dependForm?.condition,
      //     currentRow[currentRow?.length - 1][dependForm?.dependent_field],
      //     parseInt(dependForm?.value)
      //   )
      // }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return

  let avFieldValue = toggle
  if (toggle === '') {
    avFieldValue = false
  }

  if (isFieldDisabled) {
    if (toggle) {
      return (
        <div>
          <div>{label && <label className='font-weight-bold'>{cms(data?.cms_key)} :</label>}</div>

          <div className='badge badge-pill bg-success sidebar-text-light'>Yes</div>
          <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
        </div>
      )
    } else {
      return (
        <div>
          <div>{label && <label className='font-weight-bold'>{cms(data?.cms_key)} :</label>}</div>
          <div className='badge badge-pill bg-danger sidebar-text-light'>No</div>
          <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
        </div>
      )
    }
  }
  if (data?.field_id === 'is_all_tier_price') {
    return (
      <div>
        <div className='subtitle1 mb-2 mt-2' style={{ color: primary_color }}>
          You must complete these actions in order to schedule block
        </div>
        <div className='mt-2 d-flex align-items-center'>
          <div style={{ background: toggle ? primary_color : '#F87171', borderRadius: '5px', padding: '0rem 0.6rem' }} className='mr-3'>
            {toggle ? <i class='fa-solid fa-check text-white subtitle1'></i> : <i class='fa-solid fa-xmark text-white'></i>}
          </div>
          <label className='mt-2 subtitle1' style={{ color: toggle ? primary_color : '#F87171' }}>
            {cms(data?.cms_key)}
          </label>
        </div>
      </div>
    )
  }
  if (data?.field_id === 'locker_created') {
    return (
      <div className='mt-2 mb-3 d-flex align-items-center'>
        <div style={{ background: toggle ? primary_color : '#F87171', borderRadius: '5px', padding: '0rem 0.6rem' }} className='mr-3'>
          {toggle ? <i class='fa-solid fa-check text-white subtitle1 '></i> : <i class='fa-solid fa-xmark text-white'></i>}
        </div>
        <label className='mt-2 subtitle1' style={{ color: toggle ? primary_color : '#F87171' }}>
          {cms(data?.cms_key)}
        </label>
      </div>
    )
  }
  return (
    <Fragment>
      <div className={isFieldInSync ? 'blue-border' : ''}>
        {label && <label className='subtitle1'>{cms(data?.cms_key)}</label>}
        <div>
          <div
            className='switch has-switch '
            style={{ marginBottom: '-5px' }}
            data-on-label='ON'
            data-off-label='OFF'
            onClick={(e) => onChangeToggle()}>
            <div
              className={cx('switch-animate', {
                'switch-on': toggle,
                'switch-off': !toggle,
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-info'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-info'>OFF</span>
            </div>
          </div>
        </div>
        <AvField type='hidden' value={avFieldValue} name={data?.field_id} id={data?.field_id} />
      </div>
    </Fragment>
  )
}

export default connect(select)(CMSSwitch)
