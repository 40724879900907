import Loader from 'react-loaders'
import useGetEmailWidgetDesign from '../../../Queries/EmailTemplate/useGetEmailWidgetDesign'
import { useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import EditWidget from './EditWidget/editWidget'

const checkIsEditAvailable = (name) => {
  const list = ['divider_line', 'email_title', 'profile_picture', 'dynamic_email_title', 'message_to_client', 'custom_message', 'verification_code']
  if (list?.includes(name)) {
    return false
  } else {
    return true
  }
}

const checkIsWidgetNeedAvailable = (name) => {
  const list = [ 'dynamic_email_title', 'message_to_client', 'custom_message', 'verification_code']
  if (list?.includes(name)) {
    return false
  } else {
    return true
  }
}

const Widget = ({ data }) => {
  const [isEditOpen, setIsEditOpen] = useState(false)
  const { data: widgetDesignData, isLoading } = useGetEmailWidgetDesign(data?.uuid)
  let html = widgetDesignData?.html ? widgetDesignData?.html?.replace(/\\/g, '') : ''

  if (!checkIsWidgetNeedAvailable(data?.component_name)) return
  return (
    <div className='mt-2' style={{ marginBottom: '6rem' }}>
      <div className='d-flex mb-4'>
        <div className='font-weight-bold  mr-4 d-flex align-items-center' style={{ fontSize: '1.5rem' }}>
          {data?.title}
        </div>
        {checkIsEditAvailable(data?.component_name) && (
          <div
            className='cursor-pointer py-1 px-3 d-flex align-items-center justify-content-center'
            style={{ background: `${primary_color}33`, borderRadius: '5px' }}
            onClick={() => setIsEditOpen(true)}>
            <i class='fa-solid fa-pen-to-square primary_color subtitle1' color={primary_color}></i>
          </div>
        )}
      </div>
      <Modal
        isOpen={isEditOpen}
        centered={true}
        toggle={() => {
          setIsEditOpen(false)
        }}
        scrollable={true}
        style={{ width: '60rem', boxShadow: 'none', maxWidth: '60rem' }}>
        <ModalHeader
          style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}
          toggle={() => {
            setIsEditOpen(false)
          }}>
          <div className='p font-weight-bold' style={{ color: primary_color }}>
            {`Edit ${data?.title} Widget`}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='p-2'>
            <EditWidget
              key={data?.component_name}
              widget={data}
              closeModal={() => {
                setIsEditOpen(false)
              }}></EditWidget>
          </div>
        </ModalBody>
      </Modal>

      {isLoading ? <Loader type='ball-pulse' color='blue' /> : <div dangerouslySetInnerHTML={{ __html: html ? html : '' }}></div>}
    </div>
  )
}

export default Widget
