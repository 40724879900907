import { Button, Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import { Button as ReactButton } from 'reactstrap'
import cx from 'classnames'
import { checkPermission, cms, editCMS } from '../../../../helper'
import IconSet from '../../../icon'
import GridButton from './gridButtons'
import GridNote from './gridNotes'
import { useDispatch, useSelector } from 'react-redux'
import { setFilterBy } from '../../../../Redux/Reducers/CMS'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import DatePicker from 'react-datepicker'
import EmailModal from './emailModal'
import RenderGlobalFilter from './renderGlobalFilter'
import TransactionLogAction from '../../../../Components/PageSpecificComponents/TransactionLog/actionButton'
import GridAdvancedFilter from './AdvancedFilter/gridAdvancedFilter'

const GridAction = (props) => {
  const {
    grid_buttons,
    getDefaultRowOption,
    mainGrid,
    gridSelectedRow,
    setting,
    triggerButtonActions,
    gridFilters,
    currentTabData,
    updatePreferenceModal,
    updateTempPreferenceColumns,
    tempPreferenceColumns,
    visiblePreference,
    preferenceColumns,
    UpdatePreferenceColumn,
    isRepeatingForm,
    gridSettingRedux,
    clearConnectTemplateReduxData,

    gridNote,
    gridData,

    grid,
    gridNotSyncWithDB,
    pageName,
    parentPageName,
    isTab,
    isModel,
    recPerPage,
    setRecPerPage,
    tableGrid,
    filteredTableRow,
  } = props

  const {
    updateRefreshGrid,
    selectedColorBadge,
    onClickColorBadge,
    onPrintClick,
    toggleLiveRefreshGridSetting,
    toggleInlineEditGridSetting,
    setGroupRow,

    connectTemplateLoading,
    editableColumn,
    groupRow,
    grid_colors,
    grid_filters_in,
    filterManage,
    grid_filters_out,
    setFilterManage,
    setFilterOn,
    gridRelatedData,
    expandAllRow,
    setExpandAllRow,
  } = props.actionProps

  const [email_modal_open, set_email_modal_open] = useState(false)

  const dispatch = useDispatch()
  const filterByRedux = useSelector((state) => state.CMS.filterBy)

  const onClickFilterBy = (filter) => {
    dispatch(
      setFilterBy({
        ...filterByRedux,
        [gridRelatedData?.tableGrid?.name]: filter,
      })
    )
    gridRelatedData?.setActiveFilterBy(filter)
    setTimeout(() => {
      updateRefreshGrid(true)
    }, 500)
  }

  const onToggleFilterType = (type) => {
    if (type === 'in') {
      if (filterManage?.filter_in === false) {
        setFilterManage({
          ...filterManage,
          filter_in: true,
          filter_out: false,
        })
      } else {
        setFilterManage({
          ...filterManage,
          filter_in: false,
        })
      }
    } else {
      if (filterManage?.filter_out === false) {
        setFilterManage({
          ...filterManage,
          filter_in: false,
          filter_out: true,
        })
      } else {
        setFilterManage({
          ...filterManage,
          filter_out: false,
        })
      }
    }
    setFilterOn(true)
    clearConnectTemplateReduxData(gridRelatedData)
  }

  const [selected_duration, set_selected_duration] = useState()

  return (
    <Row>
      {!isRepeatingForm && (
        <Col md={12}>
          <Row>
            <Col md={1.5}>
              {mainGrid?.live_refresh === 1 && (
                <>
                  <div className='subtitle1'>Live Refresh</div>
                  <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={toggleLiveRefreshGridSetting}>
                    <div
                      className={cx('switch-animate', {
                        'switch-on': gridSettingRedux[mainGrid?.id]?.live_refresh,
                        'switch-off': !gridSettingRedux[mainGrid?.id]?.live_refresh,
                      })}>
                      <input type='checkbox' />
                      <span className='switch-left bg-success'>ON</span>
                      <label>&nbsp;</label>
                      <span className='switch-right bg-success'>OFF</span>
                    </div>
                  </div>
                </>
              )}
            </Col>
            {editableColumn && checkPermission('edit', pageName, parentPageName, isTab) && (
              <Col md={1.5} style={{ marginLeft: '1rem' }}>
                <div className='subtitle1'>Inline Edit</div>
                <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={toggleInlineEditGridSetting}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': gridSettingRedux[mainGrid?.id]?.inline_edit_button,
                      'switch-off': !gridSettingRedux[mainGrid?.id]?.inline_edit_button,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
            )}
            {!!mainGrid?.group_by &&
              mainGrid?.group_by_setting &&
              JSON.parse(mainGrid?.group_by_setting) &&
              JSON.parse(mainGrid?.group_by_setting)?.Column &&
              JSON.parse(mainGrid?.group_by_setting)?.text && (
                <Col md={1.5} style={{ marginLeft: '1rem' }}>
                  <div>Group Row</div>
                  <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => setGroupRow(!groupRow)}>
                    <div
                      className={cx('switch-animate', {
                        'switch-on': groupRow,
                        'switch-off': !groupRow,
                      })}>
                      <input type='checkbox' />
                      <span className='switch-left bg-success'>ON</span>
                      <label>&nbsp;</label>
                      <span className='switch-right bg-success'>OFF</span>
                    </div>
                  </div>
                </Col>
              )}
            {parentPageName === 'approve_jobs' && (
              <Col md={1.5} style={{ marginLeft: '1rem' }}>
                <div>Expand row</div>
                <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => setExpandAllRow(!expandAllRow)}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': expandAllRow,
                      'switch-off': !expandAllRow,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
            )}

            {grid_filters_in && grid_filters_in?.length > 0 && (
              <Col md={1.5} style={{ marginLeft: '1rem' }}>
                <div>Filter In</div>
                <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => onToggleFilterType('in')}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': filterManage?.filter_in,
                      'switch-off': !filterManage?.filter_in,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
            )}
            {grid_filters_out && grid_filters_out?.length > 0 && (
              <Col md={1.5} style={{ marginLeft: '1rem' }}>
                <div>Filter Out</div>
                <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => onToggleFilterType('out')}>
                  <div
                    className={cx('switch-animate', {
                      'switch-on': filterManage?.filter_out,
                      'switch-off': !filterManage?.filter_out,
                    })}>
                    <input type='checkbox' />
                    <span className='switch-left bg-success'>ON</span>
                    <label>&nbsp;</label>
                    <span className='switch-right bg-success'>OFF</span>
                  </div>
                </div>
              </Col>
            )}

            <div className='badge-pill-container-grid mb-4'>
              {grid_colors?.map((color, index) => {
                if (color?.conditions?.length > 0) {
                  return (
                    <span
                      key={color?.id}
                      className={`badge badge-pill badge-${color?.color} subtitle2 ${selectedColorBadge?.includes(color?.id) ? 'active-badge' : ''} ${
                        connectTemplateLoading ? 'disabled-pill' : ''
                      }`}
                      onClick={() => onClickColorBadge(color?.id)}
                      style={{ width: 'min-content' }}>
                      {color?.name}
                    </span>
                  )
                } else {
                  return
                }
              })}
            </div>
          </Row>
          {gridNote && gridNote?.length > 0 && gridNote[0] && (
            <Row>
              <Col
                md={12}
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}>
                <GridNote gridNote={gridNote[0]} />
              </Col>
            </Row>
          )}
        </Col>
      )}

      <Col md={12}>
        <GridAdvancedFilter gridRelatedData={gridRelatedData}></GridAdvancedFilter>
      </Col>

      <Col md={12}>
        {gridRelatedData?.mainGrid?.grid_filter_cols?.map((filterCol) => {
          return (
            <span
              key={filterCol?.field_id}
              className={`badge badge-pill badge-${gridRelatedData?.activeFilterBy === filterCol?.field_id ? 'success' : 'primary'}`}
              onClick={() => onClickFilterBy(filterCol?.field_id)}
              style={{ width: 'min-content', marginRight: '0.6rem', cursor: 'pointer' }}>
              {filterCol?.name}
            </span>
          )
        })}
      </Col>
      <Col md={6}>
        <RenderGlobalFilter gridRelatedData={gridRelatedData}></RenderGlobalFilter>
      </Col>
      <Col md={6}>{/* <RenderGlobalFilter gridRelatedData={gridRelatedData}></RenderGlobalFilter> */}</Col>
      <Col lg='4'>
        {gridData?.meta && setting && (
          <div className='table-top-info subtitle2'>
            <span className='-pageInfo'>
              {setting.records_before_cms_value ? (
                <span onClick={() => editCMS(setting.records_before_cms_value)}>{cms(setting.records_before_cms_value)}</span>
              ) : (
                'Showing'
              )}
              {' ' + filteredTableRow?.length + ' '}
              {setting.records_after_cms_value ? (
                <span onClick={() => editCMS(setting.records_after_cms_value)}>{cms(setting.records_after_cms_value)}</span>
              ) : (
                'out of'
              )}
              <span className='-totalPages'>
                {' '}
                {' ' + gridData?.meta?.total < filteredTableRow?.length ? filteredTableRow?.length : gridData?.meta?.total + ' '}{' '}
              </span>

              {setting.records_end_cms_value ? (
                <span onClick={() => editCMS(setting.records_end_cms_value)}>{cms(setting.records_end_cms_value)}</span>
              ) : (
                'result'
              )}
            </span>
          </div>
        )}
      </Col>
      <Col>
        {grid.component_call_grid_buttons && (
          <div className='float-right'>
            {(gridNotSyncWithDB || connectTemplateLoading) && (
              <ReactButton className={`btn-light border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`} onClick={() => updateRefreshGrid(true)}>
                <IconSet icon='fa-solid fa-upload fa-bounce' color='dark' />
              </ReactButton>
            )}

            {/* <IconSet
                    color={Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0 ? 'light' : 'dark'}
                    icon={Object.keys(queue.grid).length > 0 || hasQueue.queues.length > 0 ? 'fa-solid fa-upload fa-bounce' : 'fa-solid fa-upload'}
                  /> */}

            {checkPermission('print', pageName, parentPageName, isTab) && (
              <>
                <ReactButton
                  className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
                  onClick={onPrintClick}
                  id={'printSelectedRow'}
                  disabled={gridSelectedRow.length > 0 ? false : true}>
                  <IconSet icon='fa-regular fa-print' color='light' />
                </ReactButton>
                <UncontrolledTooltip placement='bottom' target={'printSelectedRow'}>
                  Print Selected Rows
                </UncontrolledTooltip>
              </>
            )}

            {email_modal_open && (
              <EmailModal
                set_email_modal_open={set_email_modal_open}
                mainGrid={mainGrid}
                tableGrid={tableGrid}
                pageName={pageName}
                parentPageName={parentPageName}
              />
            )}

            {/* <i class='fa-regular fa-envelope'></i> */}

            <GridButton
              grid_buttons={grid_buttons}
              getDefaultRowOption={getDefaultRowOption}
              mainGrid={mainGrid}
              content={tableGrid}
              gridSelectedRow={gridSelectedRow}
              setting={setting}
              triggerButtonActions={triggerButtonActions}
              gridFilters={gridFilters}
              currentTabData={currentTabData}
              updatePreferenceModal={updatePreferenceModal}
              updateTempPreferenceColumns={updateTempPreferenceColumns}
              tempPreferenceColumns={tempPreferenceColumns}
              visiblePreference={visiblePreference}
              preferenceColumns={preferenceColumns}
              UpdatePreferenceColumn={UpdatePreferenceColumn}
              parentPageName={parentPageName}
              pageName={pageName}
              isTab={isTab}
              isModel={isModel}
              recPerPage={recPerPage}
              setRecPerPage={setRecPerPage}
              set_email_modal_open={set_email_modal_open}
              gridRelatedData={gridRelatedData}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}

export default GridAction
