import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Collapse } from 'reactstrap'
import PublicSection from './publicSection'

import './Styles/publicSidebar.scss' // Create a separate CSS file for styling if needed
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'
import { primary_color } from '../../../Helper/uiHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { setToggleLeftSideBar } from '../../../Redux/Reducers/sideBar'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useHistory } from 'react-router-dom'

function getDifferenceInSeconds(dateString2) {
  // Convert date strings to Date objects
  const date1 = new Date()
  const date2 = new Date(dateString2)

  // Calculate the difference in milliseconds
  const difference = Math.abs(date1 - date2)

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(difference / 1000)

  return differenceInSeconds
}

function PublicSidebar({
  pages,
  parentData,
  searchData,
  setSearchData,
  setParentData,
  setActivePage,
  filterData,
  setFilterData,
  activePage,
  pageData,
  onGoBackToHomePage,
  couponCode,
  setCouponCode,
  bookingDetails,
  setBookingDetails,
  formsData,
  unitData,
  setUnitData,
  setFormData,
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const isDesktop = useMediaQuery('(min-width:900px)')
  const [collapsed, setCollapsed] = useState(false)
  const [isFirstToggle, setIsFirstToggle] = useState(true)
  const isSideBarOpen = useSelector((state) => state?.SideBar?.isHeaderSideBarOpen)
  const {isTimerChanged} = useSelector((state) => state?.TimerStatus?.isTimerChanged)
  const [timeLeft, setTimeLeft] = useState(12 * 60)
  const toggleSidebar = () => {
    setCollapsed(!collapsed)
    setIsFirstToggle(false)
  }

 //* Close Sidebar If header Sidebar Open
 useEffect(() => {
    isSideBarOpen === true && setCollapsed(true)
 } , [isSideBarOpen])

 useEffect(() => {
  setCollapsed(isDesktop ? false : true)
 } , [isDesktop])

 useEffect(() => {
  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  let current_storage_timer = storage_timer?.[parentData?.id]
   let interval
   if (parentData?.id && storage_timer && current_storage_timer) {
      if (getDifferenceInSeconds(current_storage_timer?.time) <= parseInt(current_storage_timer?.duration)) {
        interval = setInterval(() => {
        const timeLeft = parseInt(current_storage_timer?.duration) - getDifferenceInSeconds(current_storage_timer?.time)
        if (timeLeft <= 0) {
          request(`expire-reservation-session/${current_storage_timer?.[parentData?.id]?.session_uuid}`, 'GET')
          clearInterval(interval)
          dispatch(setNotification({ type: 'error', message: 'Time is expired!' }))
          history.push(window.location.pathname)
          return null
        }

        if(timeLeft == 60){
          setCollapsed(false)
        }
        if(timeLeft === 120){
          setCollapsed(false)
        }
        }, 1000)
      }
    }
    return () => clearInterval(interval)
 } , [isTimerChanged])


  return (
    <div
      className={`pt-5 sidebar sidebar ${collapsed ? 'collapsed' : ''} ${isFirstToggle ? 'first-toggle' : ''}`}
      style={
        isDesktop
          ? { borderRight: '1px solid #dee2e6' }
          : {
              borderRight: '1px solid #dee2e6',
              position: 'fixed',
              background: 'white',
              height: '95%',
              zIndex: 2,
              paddingBottom: '4rem',
              overflow: 'hidden',
            }
      }>
      <button
        className={`btn h2 toggle-btn ${collapsed ? 'collapsed' : ''}`}
        style={{ color: primary_color, fontSize: isDesktop ? '2rem' : '1.5rem' }}
        onClick={toggleSidebar}>
        {collapsed ? <BiArrowToRight /> : <BiArrowToLeft />}
      </button>
      {!collapsed ? (
        <div className='sidebar-content' style={isDesktop ? {} : { height: '90vh', overflow: 'auto' }}>
          {pages?.map((page) => {
            return (
              <>
                {page?.component_call_sections?.map((section, index) => {
                  return (
                    <Col key={index} md={parseInt(section?.col) || 6} style={{ padding: '0px' }}>
                      <PublicSection
                        filterData={filterData}
                        setFilterData={setFilterData}
                        section={section}
                        searchData={searchData}
                        setSearchData={setSearchData}
                        parentData={parentData}
                        setParentData={setParentData}
                        setActivePage={setActivePage}
                        activePage={activePage}
                        pageData={pageData}
                        onGoBackToHomePage={onGoBackToHomePage}
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                        bookingDetails={bookingDetails}
                        formsData={formsData}
                        setFormData={setFormData}
                        setBookingDetails={setBookingDetails}
                        unitData={unitData}
                        setUnitData={setUnitData}
                        isSidebar={true}
                      />
                    </Col>
                  )
                })}
              </>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default PublicSidebar
