const UPDATE_EMAIL_TEMPLATE_RESOURCE = 'UPDATE_EMAIL_TEMPLATE_RESOURCE'
const CLEAR_EMAIL_TEMPLATE_RESOURCE = 'CLEAR_EMAIL_TEMPLATE_RESOURCE'
const UPDATE_EMAIL_TEMPLATE_WIDGET_DESGIN = 'UPDATE_EMAIL_TEMPLATE_WIDGET_DESGIN'
const CLEAR_EMAIL_TEMPLATE_WIDGET_DESGIN = 'CLEAR_EMAIL_TEMPLATE_WIDGET_DESGIN'

const initialState = {
  email_template_resources: null,
  email_template_widget_design: {},
}

export const updateEmailTemplateResources = (data) => ({
  type: UPDATE_EMAIL_TEMPLATE_RESOURCE,
  data,
})
export const clearEmailTemplateResources = () => ({
  type: CLEAR_EMAIL_TEMPLATE_RESOURCE,
})
export const updateEmailTemplateWidgetDesign = (data) => ({
  type: UPDATE_EMAIL_TEMPLATE_WIDGET_DESGIN,
  data,
})
export const clearEmailTemplateWidgetDesign = () => ({
  type: CLEAR_EMAIL_TEMPLATE_WIDGET_DESGIN,
})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMAIL_TEMPLATE_RESOURCE:
      return {
        ...state,
        email_template_resources: action.data,
      }
    case CLEAR_EMAIL_TEMPLATE_RESOURCE:
      return {
        ...state,
        email_template_resources: null,
      }
    case UPDATE_EMAIL_TEMPLATE_WIDGET_DESGIN:

      let newDesign = state.email_template_widget_design
      newDesign[action.data.uuid] = action.data.data

      return {
        ...state,
        email_template_widget_design: newDesign,
      }
    case CLEAR_EMAIL_TEMPLATE_WIDGET_DESGIN:
      return {
        ...state,
        email_template_widget_design: {},
      }
    default:
  }
  return state
}
