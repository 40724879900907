import { AvForm } from 'availity-reactstrap-validation'
import { Col, Row } from 'reactstrap'
import AdvanceFilterFields from './fields'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateGridAdvancedFilter } from '../../../../../Redux/Reducers/CMS'
import { clearConnectTemplateReduxData } from '../gridHelper'

const RenderForm = ({ form, page_name, gridRelatedData }) => {
  const dispatch = useDispatch()
  const gridAdvacedFilter = useSelector((state) => state.CMS.gridAdvancedFilter)
  const [formFieldValue, setFormFieldValue] = useState({})

  useEffect(() => {
    let pageRelatedFilter = gridAdvacedFilter?.[page_name]
    
    if (pageRelatedFilter) {
      pageRelatedFilter?.map((item) => {
        if (item.uuid === form?.uuid) {
          let obj = {}
          item?.fields?.map((field) => {
            obj[field?.field_id] = field?.value
            if (field?.label) {
              obj[`${field?.field_id}_label`] = field?.label
            }
          })
          setFormFieldValue(obj)
        }
      })
    } else {
      setFormFieldValue({})
    }
  }, [JSON.stringify(gridAdvacedFilter || {})])

  const updateFilter = (field_id, value, label) => {

    clearConnectTemplateReduxData(gridRelatedData)
    let newFilter = {
      ...gridAdvacedFilter,
    } 

    let fields = gridAdvacedFilter[page_name]?.find((item) => item?.uuid === form?.uuid)?.fields || []
    let newFields = []
    let isFieldFound = false
    fields?.map((field) => {
      if (field?.field_id === field_id) {
        isFieldFound = true

        newFields?.push({
          field_id: field?.field_id,
          value,
          label,
        })
      } else {
        newFields?.push({
          ...field,
        })
      }
    })
    if (!isFieldFound) {
      newFields?.push({
        field_id,
        value,
        label,
      })
    }

    let newFormFilter = {
      uuid: form?.uuid,
      fields: newFields,
    }

    newFilter[page_name] = [newFormFilter]
    dispatch(updateGridAdvancedFilter(newFilter))
    setTimeout(() => {
      gridRelatedData.updateRefreshGrid(true)
    }, [500])
  }


  return (
    <AvForm>
      <Row>
        {form?.component_call_fields?.map((field) => {
          return (
            <Col md={field?.col || 6}>
              <AdvanceFilterFields
                formFieldValue={formFieldValue}
                setFormFieldValue={setFormFieldValue}
                field={field}
                updateFilter={updateFilter}></AdvanceFilterFields>
            </Col>
          )
        })}
      </Row>
    </AvForm>
  )
}

const GridAdvancedFilter = ({ gridRelatedData }) => {

  return (
    <div>
      {gridRelatedData?.adnvacedFilter?.map((item, key) => {
        return <RenderForm form={item} key={key} page_name={gridRelatedData?.content?.name} gridRelatedData={gridRelatedData}></RenderForm>
      })}
    </div>
  )
}

export default GridAdvancedFilter
