import { useState } from 'react'
import CustomCheckbox from './CustomCheckbox'
import { primary_color } from '../../Helper/uiHelper'

const filterData = [
  { id: 1, label: 'Checkbox 1', checked: false },
  { id: 2, label: 'Checkbox 2', checked: false },
  { id: 3, label: 'Checkbox 3', checked: false },
]

const PermissionFilters = ({ filterPage, setFilterPage }) => {
  const handleCheckboxChange = (id) => {
    // const { id } = event.target
    const updatedCheckboxes = filterPage.map((item) => {
      if(item?.id === id){
        return {
          ...item,
          checked: !item?.checked
        }
      }else{
        return item
      }
    })
      
    setFilterPage(updatedCheckboxes)
  }

  let isAllFilteredChecked = true

  const uncheckedFilter = filterPage?.filter((item) => !item.checked)
  if (uncheckedFilter?.length > 0) {
    isAllFilteredChecked = false
  }

  const handleAllCheckBox = () => {
    const updatedCheckboxes = filterPage.map((cb) => ({
      ...cb,
      checked: !isAllFilteredChecked,
    }))
   
    setFilterPage(updatedCheckboxes)
  }




  return (
    <div className='d-flex flex-column '>
      <div
        className='w-100 py-3 d-flex align-items-center px-4 justify-content-between'
        style={{ borderBottom: '1px solid #E3E3E3', borderTop: '1px solid #E3E3E3' }}>
        <CustomCheckbox
          id={'All'}
          text={'Filters'}
          value={'all'}
          onChange={handleAllCheckBox}
          checked={isAllFilteredChecked}
          activeTextColor={isAllFilteredChecked ? primary_color : '#8E8E8E'}
        />
        <i className='fa-solid fa-bars-filter' style={{ color: isAllFilteredChecked ? primary_color : '#8E8E8E', fontSize: 20 }}></i>
      </div>
      <div className='pt-3'>
        {filterPage?.map((item) => {
          return (
            <div className='px-4 py-3'>
              <CustomCheckbox
                id={item?.id}
                text={item?.label}
                value={item?.id}
                onChange={() => handleCheckboxChange(item?.id)}
                checked={item?.checked}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PermissionFilters
