import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Col, Row, Button, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import SliderFormWrapper from '../../Components/Form/sliderFormWrapper'

import { authLogin, setCredentials } from '../../Redux/Reducers/user'
import { setLoader } from '../../Redux/Reducers/loader'
import { fetchThemeOptions } from '../../Redux/Reducers/themeOptions'
import { renderBlockColorClass, renderTextColorClass, renderBlockColorStyle, renderBlockStyle } from '../../helper'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { primary_color } from '../../Helper/uiHelper'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const themeOptions = useSelector((state) => state.ThemeOptions)

  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')

  const handleSubmit = (e, values) => {
    if (!values?.email) {
      dispatch(
        setNotification({
          type: 'error',
          message: 'Please enter email',
        })
      )
      return
    }
    if (!values?.password) {
      dispatch(
        setNotification({
          type: 'error',
          message: 'Please enter password',
        })
      )
      return
    }

    dispatch(setCredentials({ ...values, history }))
    dispatch(authLogin())
  }

  useEffect(() => {
    dispatch(setLoader(false))
    if (themeOptions.updatedAt === null) {
      dispatch(fetchThemeOptions())
    }
  }, [])
  const linkClassName = themeOptions?.primaryColor && renderTextColorClass(themeOptions?.primaryColor)?.split(' ')[0] + '-text'

  // const enableRegisterButton = themeOptions?.find((item) => item?.name === 'enableRegisterButton')
  const enableRegisterButton = themeOptions?.['enableRegisterButton']

  let showSignup = false
  if (typeof enableRegisterButton === 'string') {
    showSignup = parseInt(enableRegisterButton)
  } else {
    showSignup = enableRegisterButton
  }
  // return
  return (
    <SliderFormWrapper
      sliderTitle={themeOptions?.sliderTitle}
      sliderSubTitle={themeOptions?.sliderSubTitle}
      title={themeOptions?.logInTitle || 'Log in'}
      titleColor={themeOptions?.authTitleColor}
      messageColor={themeOptions?.authSubTitleColor}
      message={themeOptions?.logInSubTitle}>
      <AvForm onValidSubmit={(e, v) => handleSubmit(e, v)}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <AvField name='email' label='Email or Username' type='text' required className='subtitle1' />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label className='subtitle1'>PASSWORD</Label>
            <InputGroup>
              <Input
                type={`${showPassword ? 'text' : 'password'}`}
                name='password'
                label='password'
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroupAddon addonType='append' onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                <InputGroupText>{showPassword ? <i className='fa-light fa-eye-slash'></i> : <i className='fa-light fa-eye'></i>}</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <AvField type='hidden' name='password' value={password} />
          </Col>
        </Row>
        <FormGroup check style={{ paddingLeft: '4px' }}>
          <AvField className='subtitle1' type='checkbox' name='keepme' label='Keep me logged in' />
        </FormGroup>

        <Row className='divider' />
        <Row>
          <Col md={12}>
            <div className='d-flex'>
              <div className=''>
                <Button
                  size='lg subtitle1'
                  style={{ background: primary_color, border: 'none' }}
                  // className={renderBlockColorClass(themeOptions?.primaryColor)}
                  // style={renderBlockStyle(themeOptions?.primaryColor)}
                >
                  Login
                </Button>{' '}
                <Link
                  to='/forgot-password'
                  className={`${linkClassName} btn-lg btn btn font-weight-bold subtitle1`}
                  // style={renderBlockColorStyle(themeOptions?.primaryColor)}
                  style={{ color: primary_color }}>
                  Recover Password
                </Link>
              </div>
            </div>
            {/* {showSignup && (
              <div className='my-4 subtitle1'>
                Don't have an account yet?{' '}
                <Link to='register' className={`btn font-weight-bold ${linkClassName}`} style={renderBlockColorStyle(themeOptions?.primaryColor)}>
                  Sign up
                </Link>
              </div>
            )} */}
            <div className='my-4 subtitle1'>
              Don't have an account yet?{' '}
              <Link
                to='register'
                className={`btn font-weight-bold ${linkClassName}`}
                //  style={renderBlockColorStyle(themeOptions?.primaryColor)}
                style={{ color: primary_color }}>
                Sign up
              </Link>
            </div>
          </Col>
        </Row>
      </AvForm>
    </SliderFormWrapper>
  )
}
export default Login
