import React, { Suspense, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Loader from 'react-loaders'
import { connect, useDispatch } from 'react-redux'

import { clearSubmittedChangedField, clearSyncRowInInRedux, fetchUpdateApiData, submitRequest } from '../../Redux/Reducers/CMS'
import { clearCommonQueue, clearGridQueue } from '../../Redux/Reducers/queue'
import { removeLocalStorageItem, setLocalStorageItem } from '../../Helper/cookieHelper'

const select = (state) => ({
  isAuth: state.User.isAuth,
  queue: state.Queue,
})

const AuthRoute = ({ isAuth, queue, component: Component, ...rest }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuth) {
      removeLocalStorageItem('currentURL')
      const interval = setInterval(() => {
        if (Object.keys(queue.grid).length > 0) {
          dispatch(
            submitRequest({
              url: 'update-grid-column',
              data: { queue: queue.grid },
              queue: true,
            })
          )
          dispatch(clearGridQueue())
        }
        if (queue.queues?.length > 0) {
          queue.queues.map((item) => {
            dispatch(
              submitRequest({
                url: item.url,
                ...item.payload,
                load: false,
              })
            )
          })
          dispatch(clearCommonQueue())
          // dispatch(clearChangedFieldIdByFormName())
          dispatch(clearSubmittedChangedField())
          dispatch(clearSyncRowInInRedux())
          setTimeout(() => {
            dispatch(fetchUpdateApiData())
          }, 1500)
        }
      }, 1000 * 60)
      return () => clearInterval(interval)
    }
  }, [queue])

  return (
    <Suspense
      fallback={
        <div className='loader-container'>
          <div className='loader-container-inner'>
            <div className='text-center'>
              <Loader type='line-scale-party' />
            </div>
            <h6 className='mt-3'>Please wait while we load all the Pages</h6>
          </div>
        </div>
      }>
      <Route
        {...rest}
        render={(props) => (isAuth ? <Component /> : <Redirect to={{ pathname: '/welcome/login', sate: { from: props.location } }} />)}
      />
    </Suspense>
  )
}

export default connect(select)(AuthRoute)
