import Loader from 'react-loaders'
import { primary_color } from '../../../Helper/uiHelper'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logTime, setIsAuth, setPublicUser } from '../../../Redux/Reducers/user'
import { reduxLoad, reduxUnload } from '../../../Redux/Reducers'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNav } from '../../../Redux/Reducers/navigations'
import { getNavFilterList } from '../../../helper'

const RedirectDirectToDashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    request('get-navigation').then((res) => {
      if (res?.data) {
        dispatch(setNav(res?.data))
        dispatch(logTime(new Date()))
        dispatch(setIsAuth(true))
        dispatch(reduxLoad())
        const main_page = getNavFilterList(res?.data?.InnerSidebarNav)?.find((item) => item?.main_page)
        if (main_page) {
          history.push('/' + main_page.path)
        } else {
          const first_page = getNavFilterList(res?.data?.InnerSidebarNav) && getNavFilterList(res?.data?.InnerSidebarNav)[0]
          history.push(first_page?.path)
        }
      }
    })
  }, [])
  return (
    <div style={{ height: '100vh', width: '100vw' }} className='d-flex justify-content-center align-items-center'>
      <Loader type='ball-pulse' color={primary_color} />
    </div>
  )
}

export default RedirectDirectToDashboard
