import Loader from 'react-loaders'
import { primary_color } from '../../../Helper/uiHelper'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setPublicUser } from '../../../Redux/Reducers/user'
import { reduxUnload } from '../../../Redux/Reducers'

const RedirectToPublic = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector((state) => state?.User?.user)
  useEffect(() => {
    if (user) {
      dispatch(setPublicUser(user))
      dispatch(reduxUnload())
      history.push('/')
    }
  }, [user])
  return (
    <div style={{ height: '100vh', width: '100vh' }} className='d-flex justify-content-center align-items-center'>
      <Loader type='ball-pulse' color={primary_color} />
    </div>
  )
}

export default RedirectToPublic
