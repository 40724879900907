import React, { useEffect } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Container } from 'reactstrap'
import { connect, useDispatch, useSelector } from 'react-redux'

import { Tab as ReactTab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import PageTitle from '../Layout/AppMain/pageTitle'
import { can, cms, editCMS, getFormTypeToRemoveByName } from '../helper'
import Icon from '../CMSComponent/icon'
import Form from './Forms/form'
import Grid from './Grid/grid'
import BasicGrid from './Grid/basicGrid'
import AdvancedGrid from './Grid/advancedGrid'
import { setSearch } from '../Redux/Reducers/page'
import FormPage from './EditCMS/ContentForms/formPage'
import { removeItemByName } from '../Redux/Reducers/CMS'
import ConfirmButton from './Common/confirmButton'
import SubmitField from './EditCMS/ContentForms/submitField'
import { getFromUpdateApi } from '../helper'
import ChatPage from '../Components/PageSpecificComponents/Chat/chat'
import MailBox from '../Components/PageSpecificComponents/MailBox/mailBox'
import PermissionTable from '../Components/Permissions/PermissionTable'
import EditEmailTemplate from '../Pages/EmailTemplate/editEmailTemplate'
import ManageWidget from '../Pages/EmailTemplate/ManageWidget/templateWidgetListing'
import TemplateWidgetListing from '../Pages/EmailTemplate/ManageWidget/templateWidgetListing'
import ManageEmailTemplateTag from '../Pages/EmailTemplate/ManageTag/manageEmailTemplateTag'
import TemplatePreview from '../Pages/EmailTemplate/templatePreview'
import EmailLogs from '../Pages/EmailTemplate/emailLogs'
import TableLogs from '../Pages/Logs/logs'

const getTemplateForPage = (page) => {
  switch (page?.template_name) {
    case 'CHAT':
      return <ChatPage page={page} />
    case 'MAILBOX':
      return <MailBox page={page} />
    case 'system-permissions':
    case 'SYSTEM_PERMISSIONS':
      return <PermissionTable />
    default:
      return null
  }
}

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  allPermissions: state.Permissions,
  coreData: state.CMS.coreData,
  downloadData: state.CMS?.updateApiData?.downloads,
})

const Tab = ({
  content,
  sourceTable = null,
  noTitle = false,
  tabContent = [],
  coreData,
  tabName,
  fullPage = false,
  hideButtons = false,
  pageName,
  parentPageName,
  editPageMode,
  allPermissions,
  isDatabaseDropdownVisible = false,
  isModel,
  hideEditIcon,
  gridData,
  cmsTabKey,
  isModalAction,
  currentTabData,
  addDataDetail,
  setAddDataDetail,
  setQuestionnaireAction,
  questionnaireAction,
  activeTabNumber,
  isTab = false,
  pageData,
}) => {
  const dispatch = useDispatch()
  const reduxMenuList = useSelector((state) => state.CMS.menuList)

  useEffect(() => {
    content.map((form) => {
      if (form.main_grid && form.component_name === 'advanced_grid') dispatch(setSearch(true))
    })
  }, [coreData, content, allPermissions, sourceTable])

  const currentRowData = useSelector((state) => state.CMS.currentRow)

  

  if (pageData?.template_name === 'system-permissions' || pageData?.template_name === 'SYSTEM-PERMISSIONS') return <PermissionTable />
  return (
    <div style={{ minHeight: '50px' }}>
      {!hideEditIcon && content[0]?.name !== 'profiles' && (
        <FormPage
          source_table={sourceTable}
          tabName={tabName}
          pageName={pageName}
          parentPageName={parentPageName}
          content={content.length === 1 && content[0]?.component_name === 'advanced_grid' ? content[0] : null}
          profileForm={content.length == 1 && content[0]?.name === 'profiles' && content[0]}
          currentTabData={currentTabData}
        />
      )}
      {content.length == 1 && content[0]?.name === 'profiles' && (
        <SubmitField pageName={pageName} parentPageName={parentPageName} formName={'profiles'} fields={content[0]?.component_call_fields} />
      )}

      {content.length === 0 && <div style={{ textAlign: 'center' }}>Content Not Found</div>}
      {content.length === 1 ? (
        content.map((form, key) => (
          <React.Fragment key={key}>
            {editPageMode && !hideEditIcon && currentRowData?.length === 0 && (
              <ConfirmButton
                color='danger'
                id={form.name}
                clickEvent={() => dispatch(removeItemByName({ name: form.name, type: getFormTypeToRemoveByName(form.component_name) }))}>
                <Icon icon={'fas fa-trash'} color='light' /> Remove {cms(form.cms_header_key) || cms(form.name)} ({cms(form.component_name)})
              </ConfirmButton>
            )}
            {getTemplateForPage(pageData)}

            {!form.is_database && !getTemplateForPage(pageData) && (
              <SingleForm
                source_table={sourceTable}
                form={form}
                editPageMode={editPageMode}
                tabName={tabName}
                tabContent={tabContent}
                fullPage={fullPage}
                noTitle={noTitle}
                pageName={pageName}
                parentPageName={parentPageName}
                gridData={gridData}
                isPrintVisible={isModel ? false : true}
                cmsTabKey={cmsTabKey}
                isModalAction={isModalAction}
                isModel={isModel}
                content={content}
                currentTabData={currentTabData}
                addDataDetail={addDataDetail}
                setAddDataDetail={setAddDataDetail}
                setQuestionnaireAction={setQuestionnaireAction}
                questionnaireAction={questionnaireAction}
                activeTabNumber={activeTabNumber}
                isTab={isTab}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <ReactTabs className='mt-4 ml-1'>
          <TabList>
            {content.map((form, key) => (
              <ReactTab key={key} onClick={() => editCMS(form.cms_header_key)}>
                {cms(form.cms_header_key)}{' '}
                {(form?.name?.includes('download') || form?.name?.includes('email')) && (
                  <span className='badge badge-primary'>{getFromUpdateApi(form?.component_call_data_connection)?.length}</span>
                )}
              </ReactTab>
            ))}
          </TabList>
          {content.map((form, key) => (
            <TabPanel key={key}>
              {editPageMode && noTitle && (
                <ConfirmButton color='danger' id={form.name} clickEvent={() => dispatch(removeItemByName({ name: form.name, type: 'form' }))}>
                  <Icon icon={'fas fa-trash'} color='light' /> Remove {cms(form.cms_header_key) || form.name} ({form.component_name})
                </ConfirmButton>
              )}
              <SingleForm
                form={form}
                source_table={sourceTable}
                editPageMode={editPageMode}
                tabName={tabName}
                tabContent={tabContent}
                fullPage={fullPage}
                noTitle={noTitle}
                pageName={pageName}
                parentPageName={parentPageName}
                cmsTabKey={cmsTabKey}
                isModalAction={isModalAction}
                isModel={isModel}
                content={content}
                currentTabData={currentTabData}
                addDataDetail={addDataDetail}
                setAddDataDetail={setAddDataDetail}
                setQuestionnaireAction={setQuestionnaireAction}
                questionnaireAction={questionnaireAction}
                activeTabNumber={activeTabNumber}
                isTab={isTab}
              />
            </TabPanel>
          ))}
        </ReactTabs>
      )}
    </div>
  )
}

const SingleForm = ({
  gridData = {},
  form,
  editPageMode,
  tabName,
  tabContent,
  fullPage,
  noTitle,
  pageName,
  parentPageName,
  source_table = null,
  isPrintVisible,
  cmsTabKey,
  isModalAction,
  isModel,
  content,
  currentTabData,
  addDataDetail,
  setAddDataDetail,
  setQuestionnaireAction,
  questionnaireAction,
  activeTabNumber,
  isTab,
}) => {
  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.User.user)
  const {
    allowed,
    permission,
    cms_header_key,
    cms_header_name,
    cms_sub_header_key,
    cms_sub_header_name,
    icon,
    component_name,
    component_type,
    component_call_data_connection,
    component_call_fields,
    cols,
    component_call_colls,
    component_call_cols,
    name,
  } = form

  const renderComponent = () => {
    switch (component_name) {
      case 'static_form':
        let defaultFormValue
        if (form?.name === 'profiles') {
          defaultFormValue = { ...userProfile }
        }

        if (form?.name === 'emails_email_template_update') {
          return <EditEmailTemplate parentPageName={parentPageName} />
        }
        if (form?.name === 'manage_widgets_email_template_update') {
          return <TemplateWidgetListing />
        }
        if (form?.name === 'manage_tags_email_template_update') {
          return <ManageEmailTemplateTag />
        }

        if(form?.name === 'logs_user_update'){
          return <TableLogs parentPageName={parentPageName} />
        }


        if (form?.name === 'preemail_email_template_update'){
          return <TemplatePreview/>
        }
        if (form?.name === 'logs_email_template_update'){
          return <EmailLogs/>
        }
          return (
            <Form
              sourceTable={source_table}
              formData={form}
              dataConnection={component_call_data_connection || []}
              allFields={component_call_fields}
              cols={cols}
              isPrintVisible={isPrintVisible}
              cmsTabKey={cmsTabKey}
              isModalAction={isModalAction}
              isModel={isModel}
              content={content}
              currentTabData={currentTabData}
              parentPageName={parentPageName}
              pageName={pageName}
              defaultFormValue={defaultFormValue}
            />
          )
      case 'form_grid':
        return (
          <Grid
            dataConnection={component_call_data_connection}
            allCols={component_call_colls || component_call_cols}
            isModel={isModel}
            currentTabData={currentTabData}
          />
        )
      case 'basic_grid':
        return (
          <BasicGrid
            type={component_type}
            dataConnection={component_call_data_connection}
            allCols={component_call_colls || component_call_cols}
            currentTabData={currentTabData}
          />
        )
      case 'advanced_grid':
        return (
          <AdvancedGrid
            content={form}
            tabContent={tabContent}
            source_table={source_table}
            gridData={gridData}
            parentPageName={parentPageName}
            pageName={pageName}
            isModel={isModel}
            currentTabData={currentTabData}
            addDataDetail={addDataDetail}
            setAddDataDetail={setAddDataDetail}
            setQuestionnaireAction={setQuestionnaireAction}
            questionnaireAction={questionnaireAction}
            activeTabNumber={activeTabNumber}
            isTab={isTab}
            key={source_table}
          />
        )
      default:
        return null
    }
  }

  if (!allowed || !tabName) return null

  return (
    <React.Fragment>
      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Container fluid>
          <br />
          {!noTitle && cms(cms_header_key || cms_header_name) && (
            <PageTitle
              fullPage={fullPage}
              onTitleClick={() => editCMS(cms_header_key || cms_header_name)}
              onSubTitleClick={() => editCMS(cms_sub_header_key || cms_sub_header_name)}
              heading={cms(cms_header_key || cms_header_name)}
              subheading={cms(cms_sub_header_key || cms_sub_header_name)}
              headingButton={
                editPageMode &&
                !gridData?.gridData && (
                  <ConfirmButton color='danger' id={form.name} clickEvent={() => dispatch(removeItemByName({ name: form.name, type: 'form' }))}>
                    <Icon icon={'fas fa-trash'} color='light' />
                  </ConfirmButton>
                )
              }
              footerButton={
                !gridData.gridData && (
                  <SubmitField formName={name} parentPageName={parentPageName} pageName={pageName} fields={component_call_fields} form={form} />
                )
              }
              icon={icon}
              component_name={component_name}
              hideBreadCrumbs
            />
          )}
          {renderComponent()}
        </Container>
      </CSSTransitionGroup>
    </React.Fragment>
  )
}

export default connect(select)(Tab)
