import { useEffect, useState } from 'react'
import { triggerBase64Download } from 'common-base64-downloader-react'

import IconSet from '../../../../../icon'
import { downloadFile, download, downloadFiles } from '../../../../../../helper'
import moment from 'moment'

const getFileIcon = (type, size) => {
  switch (type) {
    case 'pdf':
      return <IconSet icon='fa-solid fa-file-pdf' color='primary' style={{ fontSize: size ? size : '3rem' }} />
    case 'png':
    case 'jpeg':
    case 'svg':
    case 'jpg':
      return <IconSet icon='fa-sharp fa-solid fa-file-image' color='primary' style={{ fontSize: size ? size : '3rem' }} />
    case 'docx':
    case 'doc':
      return <IconSet icon='fa-solid fa-file' color='primary' style={{ fontSize: size ? size : '3rem' }} />
    case 'csv':
      return <IconSet icon='fa-solid fa-file-csv' color='primary' style={{ fontSize: size ? size : '3rem' }} />
    case 'xls':
    case 'xlsx':
      return <IconSet icon='fa-sharp fa-solid fa-file-excel' color='primary' style={{ fontSize: size ? size : '3rem' }} />
    default:
      return <IconSet icon='fa-sharp fa-solid fa-file-excel' color='primary' style={{ fontSize: size ? size : '3rem' }} />
  }
}

const ShowUploadFiles = ({ addedFiles, onSubmitForm, content, onValueChange, submittedFormData, allForm, onDeleteFiles }) => {
  const [selectedFile, setSelectedFile] = useState({ file: null, index: null })

  const imageType = ['png', 'jpg', 'jpeg', 'svg']

  useEffect(() => {
    setSelectedFile({ file: addedFiles[0], index: 0 })
  }, [addedFiles?.length])

  const onDelete = () => {
    onDeleteFiles(selectedFile?.index, selectedFile?.file?.id)
  }

  const onDownLoadImage = () => {
    if (selectedFile?.file?.isBase64) {
      triggerBase64Download(selectedFile?.file?.file, selectedFile?.file?.file_name)
    } else {
      downloadFiles(`${process.env.REACT_APP_API_URL}/download-upload/${selectedFile?.file?.id}`, selectedFile?.file?.file_name)
    }
    //
  }
  if (addedFiles?.length === 0) return
  return (
    <div className='show_uploads'>
      <div className='show_uploads_preview_container'>
        <div className='selected_file_preview'>
          {/* {!imageType?.includes(selectedFile?.file?.ext) && <div className='title'>{selectedFile?.file?.file_name}</div>} */}

          {imageType?.includes(selectedFile?.file?.ext) && (
            <>
              {' '}
              {selectedFile?.file?.isBase64 ? (
                <img src={selectedFile?.file?.file} style={{ width: '8rem', height: '7rem',  objectFit:'cover' }}></img>
              ) : (
                <img
                  src={`${process.env.REACT_APP_STORAGE}files/${selectedFile?.file?.upload_id}/${selectedFile?.file?.file_name}`}
                  style={{ maxWidth: '8rem', height: '6.5rem', marginTop: '1rem' }}></img>
              )}
            </>
          )}
          {!imageType?.includes(selectedFile?.file?.ext) && <div className='file_type_icon'>{getFileIcon(selectedFile?.file?.ext, '7rem')}</div>}

          <div className='action-container'>
            {imageType?.includes(selectedFile?.file?.ext) || selectedFile?.file?.isBase64 ? (
              <span onClick={() => onDownLoadImage()}>
                {' '}
                <IconSet icon='fa-light fa-download' color='info' className='action' />
              </span>
            ) : (
              <a href={`${process.env.REACT_APP_STORAGE}${selectedFile?.file?.upload_id}/${selectedFile?.file?.file_name}`} target='_blank' download>
                <IconSet icon='fa-light fa-download' color='info' className='action' />
              </a>
            )}

            <IconSet icon='fa-solid fa-trash' color='danger' className='action' onClick={() => onDelete()} />
          </div>
        </div>
      </div>

      <div className='show_uploads_file_container'>
        {addedFiles?.map((file, index) => {
          return (
            <div
              key={index}
              className={`file_container ${JSON.stringify(selectedFile?.file) === JSON.stringify(file) && 'selected_file_container'}`}
              onClick={() => setSelectedFile({ file: file, index: index })}>
              <div style={{ marginRight: '1rem' }}>{getFileIcon(selectedFile?.file?.ext, '1.5rem')}</div>
              <div style={{ flex: 1 }}> {file.file_name}</div>
              <div>{file?.isBase64 ? moment(new Date()).format('D MMM YYYY h:mm a') : moment(file?.created_at).format('D MMM YYYY h:mm a')}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ShowUploadFiles
