import PageHeader from './PageHeader'
import '../../assets/permissions/permissions.scss'
import PermissionTab from './PermissionTab'
import { checkPermissionToEditPermission } from '../../helper'

const PermissionTable = () => {
  if (!checkPermissionToEditPermission()) return <div className='text-center body1'>Permission Denied</div>
  return (
    <>
      {/* <PageHeader text={'Permissions'} /> */}
      <div className='mt-5'>
        <PermissionTab />
      </div>

      {/* <div className='permission-table d-sm-flex w-100 mt-5'>
        <div className='w-100 left-col' style={{ borderRight: '1px solid #E3E3E3' }}>
          <div className='permission-header w-100' style={{ color: primary_color }}>
            Roles
          </div>
          <div className='w-12 px-4 py-3'>
            <RoleBlock isActive={true} text='Admin' />
            <RoleBlock text='Guest' />
          </div>
        </div>
        <div className='w-100 right-col'>
          <div className='permission-header w-100' style={{ color: primary_color }}>
            Query Ticket
          </div>
          <div>
            <PermissionGroup title='page' />
            <PermissionGroup title='grid' />
          </div>
        </div>
      </div> */}
    </>
  )
}

export default PermissionTable
