import { useEffect, useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'


const CustomCheckbox = ({ id, text, onChange, value, checked, activeTextColor = '#8E8E8E' }) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleChange = (e) => {
    onChange?.(e)
    setIsChecked(!isChecked)
  }

  return (
    <label className='d-flex' style={{ cursor: 'pointer', marginBottom: 0 }}>
      <input id={id} type='checkbox' name='check' style={{ opacity: 0, position: 'absolute' }} onChange={(e) => handleChange(e)} value={value} />
      <div className='position-relative'>
        <div className='border' style={{ height: 18, width: 18, borderRadius: '5px', backgroundColor: isChecked ? primary_color : '#FFF' }}></div>
        {isChecked ? (
          <div className='position-absolute text-white' style={{ top: '-3px', left: '3px' }}>
            <i className='fa-solid fa-check subtitle1'></i>
          </div>
        ) : null}
      </div>
      <span className='label-text ml-3 subtitle1'  style={{ fontWeight: 600, color: activeTextColor }}>
        {text}
      </span>
    </label>
  )
}

export default CustomCheckbox
