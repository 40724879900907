import { useEffect, useState } from 'react'
import useUpdateEmailTemplateWidget from '../../../../Queries/EmailTemplate/useUpdateEmailTemplateWidget'
import { useDispatch } from 'react-redux'
import { primary_color } from '../../../../Helper/uiHelper'
import Switch from 'react-switch'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { FaTemperatureHigh } from 'react-icons/fa'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'

const common_setting = [
  {
    component_name: 'social_icon',
    field_id: 'instagram',
    View: true,
    Value: 'https://www.instagram.com/',
    icon: 'fa-brands fa-instagram',
  },
  {
    component_name: 'social_icon',
    field_id: 'linkedin',
    View: true,
    Value: 'https://www.linkedin.com/',
    icon: 'fa-brands fa-linkedin-in',
  },
  {
    component_name: 'social_icon',
    field_id: 'facebook',
    View: true,
    Value: 'https://www.facebook.com/',
    icon: 'fa-brands fa-facebook-f',
  },
  {
    component_name: 'social_icon',
    field_id: 'twitter',
    View: true,
    Value: 'https://www.twitter.com/',
    icon: 'fa-brands fa-twitter',
  },
  {
    component_name: 'copyright',
    View: true,
    Value: 'Copyright © 2022. All Rights Reserved.',
  },
  {
    component_name: 'domain',
    View: true,
    Value: 'beta.locker.rentals',
    link: 'https://beta.locker.rentals/',
  },
  {
    component_name: 'terms&condition',
    View: true,
    Value: 'Terms & Condition',
    link: 'https://beta.locker.rentals/terms-and-condition-list',
  },
  {
    component_name: 'privacy-policy',
    View: true,
    Value: 'Privacy Policy',
    link: 'https://beta.locker.rentals/privacy-policies-list',
  },
  {
    component_name: 'unsubscribe',
    View: true,
    Value: 'unsubscribe',
    link: '/unsubscribe',
  },
]

const FooterEdit = ({ widget_data , closeModal}) => {
  const [data, setData] = useState(common_setting)
  const [editableCell, setEditableCell] = useState({ row: null, col: null })

  const updateWidget = useUpdateEmailTemplateWidget()
  const dispatch = useDispatch()

  useEffect(() => {
    if (widget_data && widget_data?.common_setting) {
      setData(JSON.parse(widget_data?.common_setting))
    }
  }, [JSON.stringify(widget_data)])

  const social_links = data?.filter((item) => item.component_name === 'social_icon')
  const copyright = data?.find((item) => item.component_name === 'copyright')
  const domain = data?.find((item) => item.component_name === 'domain')
  const unsubscribe = data?.find((item) => item.component_name === 'unsubscribe')
  const terms_condition = data?.find((item) => item.component_name === 'terms&condition')
  const privacy_policy = data?.find((item) => item.component_name === 'privacy-policy')

  const onChangeSocialToggle = (field_id) => {
    setData((oldData) => {
      const newData = oldData?.map((item) => {
        if (item?.component_name === 'social_icon' && item?.field_id === field_id) {
          return {
            ...item,
            View: !item?.View,
          }
        } else {
          return item
        }
      })
      return newData
    })
  }

  const onChangeSocialText = (field_id, text) => {
    setData((oldData) => {
      const newData = oldData?.map((item) => {
        if (item?.component_name === 'social_icon' && item?.field_id === field_id) {
          return {
            ...item,
            Value: text,
          }
        } else {
          return item
        }
      })
      return newData
    })
  }

  const onChangeOtherField = (value, component_name, isLink) => {
    setData((oldData) => {
      const newData = oldData?.map((item) => {
        if (item?.component_name === component_name) {
          if (isLink) {
            return {
              ...item,
              link: value,
            }
          } else {
            return {
              ...item,
              Value: value,
            }
          }
        } else {
          return item
        }
      })
      return newData
    })
  }

  const onSaveWidget = () => {
    let payload = {
      ...widget_data,
      common_setting: data,
      type: 'widgets',
    }
    updateWidget.mutate(payload, {
      onSuccess: (data) => {
        //add failed scenario also
        dispatch(setNotification({ type: 'success', message: 'Email Sent Successfully!' }))
        closeModal()
      },
      onError: (error) => {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        closeModal()
      },
    })
  }
  return (
    <div>
      <div className='mb-4 mt-2' style={{ width: '70%' }}>
        <div className='d-flex mb-4'>
          <div className='font-weight-bold body1 mr-4' style={{ flex: 1, color: primary_color }}>
            Social Icons
          </div>
          <div className='body1 font-weight-bold' style={{ color: primary_color }}>
            View
          </div>
        </div>
        <AvForm>
          {social_links?.map((item) => {
            return (
              <div className='d-flex mb-4'>
                <div style={{ flex: 1 }} className='mr-4'>
                  <div>
                    <i className={`${item?.icon} body1`} style={{ border: '1px solid', borderRadius: '5px', padding: '6px 9px' }}></i>
                  </div>
                  <AvField
                    type={'text'}
                    name={item?.field_id}
                    placeholder='link'
                    value={item?.Value}
                    className='mt-4'
                    onChange={(e) => {
                      onChangeSocialText(item?.field_id, e.target.value)
                    }}></AvField>
                </div>
                <div>
                  <Switch
                    onChange={() => {
                      onChangeSocialToggle(item?.field_id)
                    }}
                    checked={item.View}
                    onColor='#049FDB'
                    offColor='#cccccc'
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              </div>
            )
          })}
        </AvForm>
        <div className='subtitle1 mr-4 mb-3' style={{ flex: 1, color: primary_color }}>
          Copyright
        </div>
        <AvForm className='mb-4'>
          <AvField disabled={true} name='copyright' value={copyright?.Value}></AvField>
        </AvForm>
        <div className='subtitle1 mr-4 mb-3 ' style={{ flex: 1, color: primary_color, marginTop: '3rem' }}>
          Domain
        </div>
        <AvForm className='mb-4'>
          <AvField
            name='domain_value'
            value={domain?.Value}
            placeholder='text here'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'domain', false)
            }}></AvField>
          <AvField
            name='domainlink'
            value={domain?.link}
            placeholder='link'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'domain', true)
            }}></AvField>
        </AvForm>
        <div className='subtitle1 mr-4 mb-3 ' style={{ flex: 1, color: primary_color, marginTop: '3rem' }}>
          Unsubscribe
        </div>
        <AvForm className='mb-4'>
          <AvField
            name='unsubscribe_value'
            value={unsubscribe?.Value}
            placeholder='text here'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'unsubscribe', false)
            }}></AvField>
          <AvField
            name='unsubscribelink'
            value={unsubscribe?.link}
            placeholder='link'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'unsubscribe', true)
            }}></AvField>
        </AvForm>
        <div className='subtitle1 mr-4 mb-3 ' style={{ flex: 1, color: primary_color, marginTop: '3rem' }}>
          Terms & Condition
        </div>
        <AvForm className='mb-4'>
          <AvField
            name='terms_value'
            value={terms_condition?.Value}
            placeholder='text here'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'terms&condition', false)
            }}></AvField>
          <AvField
            name='termslink'
            value={terms_condition?.link}
            placeholder='link'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'terms&condition', true)
            }}></AvField>
        </AvForm>
        <div className='subtitle1 mr-4 mb-3 ' style={{ flex: 1, color: primary_color, marginTop: '3rem' }}>
          Privacy Policy
        </div>
        <AvForm className='mb-4'>
          <AvField
            name='privacy_value'
            value={privacy_policy?.Value}
            placeholder='text here'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'privacy-policy', false)
            }}></AvField>
          <AvField
            name='privacylink'
            value={privacy_policy?.link}
            placeholder='link'
            onChange={(e) => {
              onChangeOtherField(e.target.value, 'privacy-policy', true)
            }}></AvField>
        </AvForm>
      </div>
      <div className='d-flex justify-content-between' style={{ width: '60%' }}>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger'
          type='button'
          onClick={() => {
            closeModal()
          }}
          style={{ fontWeight: 700, width: '48%' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={() => {
            onSaveWidget()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
          Save
        </button>
      </div>
    </div>
  )
}

export default FooterEdit
