import { Button } from 'reactstrap'

const GoBackButton = ({ onCloseForm }) => {
  return (
    <Button
      color='dark'
      onClick={() => {
        onCloseForm()
      }}>
      <i class='fa-solid fa-arrow-left'></i>
    </Button>
  )
}

export default GoBackButton
