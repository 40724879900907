import { AvForm } from 'availity-reactstrap-validation'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'

import { covertFileIntoBase64 } from '../../../../../../helper'
import IconSet from '../../../../../icon'

const fileTypes = ['PDF', 'EPS', 'JPG', 'JPEG', 'TIFF', 'GIF', 'PNG', 'doc', 'docx', 'xls', 'xlsx']

const UploadFileForm = ({ onSubmitForm }) => {
  const [files, setFiles] = useState()
  const onSubmit = () => {
    onSubmitForm()
  }

  const handleChange = (files) => {
    covertFileIntoBase64(files).then((file) => {
      const values = {
        file: file,
        file_name: files?.name,
        original_file_name: files?.name,
        ext: files?.type?.split('/')[1],
        isBase64: true,
      }
      onSubmitForm(values)
    })

    setFiles(files)
    // onSubmitForm(formData)
  }

  return (
    <AvForm style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FileUploader handleChange={handleChange} name='file' types={fileTypes} multiple={false} fileOrFiles={files} classes={'upload-file-form'}>
        <div className='upload_container subtitle1'>
          <span style={{ marginRight: '1rem', fontSize: '2rem' }}>
            <IconSet icon='fa-solid fa-folder-arrow-up' color='info' />
          </span>
          Upload or Drop file here!
        </div>
      </FileUploader>
    </AvForm>
  )
}

export default UploadFileForm
