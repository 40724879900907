export const configEnv = {
  dev: {
    grid: 'https://dev-cms.ionicbox.com/v1',
    page: 'https://dev-cms.ionicbox.com/v1',
    cms: 'https://dev-cms.ionicbox.com/v1',
    theme: 'https://dev-api.ionicbox.com/v1',
    connectTemplate: 'https://dev-cms.ionicbox.com/v1',
    payment: 'https://dev-payment.ionicbox.com/v1',
  },
  test: {
    grid: 'https://test-locker-cms.ionicbox.com/v1',
    page: 'https://test-locker-cms.ionicbox.com/v1',
    cms: 'https://test-locker-cms.ionicbox.com/v1',
    theme: 'https://test-locker-api.ionicbox.com/v1',
    connectTemplate: 'https://test-locker-cms.ionicbox.com/v1',
    payment: 'https://test-locker-payment.ionicbox.com/v1',
  },
  beta: {
    grid: 'https://beta-cms.ionicbox.com/v1',
    page: 'https://beta-cms.ionicbox.com/v1',
    cms: 'https://beta-cms.ionicbox.com/v1',
    theme: 'https://beta-api.ionicbox.com/v1',
    connectTemplate: 'https://beta-cms.ionicbox.com/v1',
    payment: 'https://beta-payment.ionicbox.com/v1',
  },
}
