import { AvField } from 'availity-reactstrap-validation'
import { cms, editCMS } from '../../../helper'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'

const TagField = ({ data, isDisabled, onChange }) => {
  const cmsEditMode = useSelector((state) => state.CMS.editMode)
  const setting = data?.common_setting ? JSON.parse(data?.common_setting)?.[0] : {}

  const [commonSetting, setCommonSetting] = useState({})

  useEffect(() => {
    setCommonSetting(setting)
  }, [JSON.stringify(setting)])

  const onChangeValue = (e) => {
    setCommonSetting({
      ...commonSetting,
      Value: e.target.value,
    })

    onChange(
      {
        ...commonSetting,
        Value: e.target.value,
      },
      data?.uuid
    )
  }

  return (
    <div>
      <label
        style={{ color: primary_color }}
        className='subtitle1'
        onClick={() => (cmsEditMode && setting.cms_key ? editCMS(setting.cms_key) : null)}>
        {cms(setting?.cms_key) || data?.name}
      </label>
      <AvField
        className='subtitle1'
        name={data?.name}
        onChange={onChangeValue}
        disabled={isDisabled}
        value={commonSetting?.Value}
        placeholder={cms(setting?.cms_key) || data?.name}></AvField>
    </div>
  )
}

export default TagField
