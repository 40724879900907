import { useDispatch } from 'react-redux'
import { cms, tidyName } from '../../../helper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import './roundedInput.scss'
import Form from '../../../CMSComponent/Forms/form'

const RoundedInput = ({
  onChange,
  field_id,
  disabled,
  search_keyword_exclude = [],
  search_limit_error = 'Please search proper keyword',
  setFormFieldValue,
  formFieldValue,
  data,
  inputPlaceholder,
  blackBorder,
  validationError,
  setValidationError,
  setFormData=()=>{},
  formIndex
}) => {
  const onChangeValue = (value) => {
    if (validationError && setValidationError) setValidationError(false)

    // let updatedValue = {
    //   ...formFieldValue,
    // }
    // updatedValue[data?.field_id] = value
    // setFormFieldValue(updatedValue)
     setFormData((prevFormsData) => {
       return prevFormsData.map((item, i) => {
         if (formIndex === i) {
           let newdata = {
             ...item,
           }
           newdata[data?.field_id] = value
           return newdata
         } else {
           return item
         }
       })
     })
  }

  const value = formFieldValue?.[data?.field_id]
  const placeholder = inputPlaceholder ? inputPlaceholder : `search ${data?.cms_key ? cms(data?.cms_key) : 'something'}`
  return (
    <>
      <div>{data?.cms_key && cms(data?.cms_key)}</div>
      <div
        className='rounded-input-container d-flex align-items-center justify-content-center'
        style={validationError && !value ? { border: '1px solid red' } : blackBorder ? { border: '1px solid' } : {}}>
        <input
          placeholder={tidyName(placeholder)}
          className={validationError && !value ? 'red-placeholder rounded-input' : 'rounded-input'}
          disabled={disabled}
          type='text'
          value={value ? value : ''}
          onChange={(e) => onChangeValue(e.target.value)}></input>
      </div>
      {validationError && !value && <div className='text-danger subtitle1 mt-1'>This field is required.</div>}
    </>
  )
}

export default RoundedInput
