import React, { useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap'

import {
  addChangedFieldIdInReduxByFormName,
  capitalize,
  checkAndClearQueueForGivenSourceTable,
  checkFieldExistInSyncToUpdate,
  cms,
  dataValidationForField,
  editCMS,
  extractYears,
  getFromRedux,
  getHomePageDropdownOptions,
  removeChangedFieldIdInReduxByFormName,
  removeDropdownOptionRefetchTable,
  storeUserSecurityOverviewDropdown,
  tidyName,
  validateForAv,
} from '../../../helper'
import ToggleField from './toggleField'
import { request } from '../../../Redux/Sagas/requests/api'
import { enabledOrDisabledMethodTabAction, setDropdownOptionsByPage, setSelectedDropdownData } from '../../../Redux/Reducers/CMS'
import { AsyncPaginate } from 'react-select-async-paginate'
import usePrevious from '../../../CustomHook/usePrevious'
import { primary_color } from '../../../Helper/uiHelper'
import LinkCategory from './linkCategoryModel'

export const getDependDataPayload = (allFields, data_connection, value, depend_field_source_table) => {
  let payload = {
    field_data: data_connection?.data,
    source_field: data_connection?.source_field,
    depend_field_value: typeof value === 'number' ? value : value.value,
    depend_field_source_table: depend_field_source_table,
  }

  allFields?.forEach((field) => {
    if (field?.field_id === data_connection?.source_field) {
      if (field?.component_call_data_connection?.source_table) {
        payload = {
          ...payload,
          depend_field_source_table: field?.component_call_data_connection?.source_table,
        }
      }
    }
  })
  return payload
}

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

export const getDropdownOptionsToUpdateInRedux = (
  dropdownOptionsByPageRedux,
  parentPageName,
  field_id,
  source_table,
  options,
  paginationData,
  hasMore,
  optionsData
) => {
  let dropdownOptionsByPageReduxToUpdate = { ...dropdownOptionsByPageRedux }
  dropdownOptionsByPageReduxToUpdate[parentPageName] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],
  }

  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],

    // options: [...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id]['options'], ...options],
    paginationData: paginationData,
    hasMore: hasMore,
  }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['updatedAt'] = new Date()
  if (dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options']) {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = [
      ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'],
      ...options,
    ]
  } else {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = [...options]
  }
  if (dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data']) {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'] = [
      ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'],
      ...optionsData,
    ]
  } else {
    dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options_data'] = [...optionsData]
  }

  return dropdownOptionsByPageReduxToUpdate
}

export const getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown = (dropdownOptionsByPageRedux, parentPageName, field_id, source_table) => {
  let dropdownOptionsByPageReduxToUpdate = { ...dropdownOptionsByPageRedux }
  dropdownOptionsByPageReduxToUpdate[parentPageName] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] = { ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id] }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table] = {
    ...dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table],
  }
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['options'] = []
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['paginationData'] = {}
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['hasMore'] = true
  dropdownOptionsByPageReduxToUpdate[parentPageName][field_id][source_table]['updatedAt'] = null
  return dropdownOptionsByPageReduxToUpdate
}

const select = (state) => ({
  coreData: state.CMS.coreData,
  cmsEditMode: state.CMS.editMode,
  currentFormData: state.CMS.coreData.currentFormData,
  currentRow: state.CMS.currentRow,
  selectedDropdownData: state.CMS.selectedDropdownData,
  updateApiData: state.CMS?.updateApiData?.updated_data,
  domain_id: state.CMS.selected_domain_id,
  domains: state.CMS.coreData.domains,
  refetchTableList: state.CMS.dropdownOptionRefetchTable,
})

const CMSSelect = ({
  data,
  updateKeysForReduxData,
  fieldKey,
  repeatingFormItem,
  allFieldsInForm,
  isTable,
  isRepeatingForm,
  cmsEditMode,
  toggleActions,
  value,
  inlineEditActive = true,
  inputLabel,
  currentFormData = {},
  onChangeAction = () => {},
  allFields,
  index,
  id,
  currentRow,
  selectedDropdownData,
  extraDefaultFormValue,
  parentPageName,
  pageName,
  updateApiData = {},
  formName,
  domain_id,
  isFieldUsedDirectly,
  parentRowIdRequiredInPayload,
  domains,
  formData,
  formFieldValue,
  setFormFieldValue = () => {},
  depend_value,
  refetchTableList,
  depend_field_source_table,
  noCache,
  onChangeValue = () => {},
  disabled_field,
  isClearable,
}) => {
  const [selectedOption, setSelectedOption] = useState()
  const [searchKey, setSearchKey] = useState()
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    hasMore: true,
  })

  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const enabledOrDisabledMethodTab = useSelector((state) => state.CMS.enabledOrDisabledMethodTab)

  const [reduxData, updateReduxData] = useState([])
  const [optionClear, setOptionClear] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])
  const clearAllFieldInForm = useSelector((state) => state.CMS.clearAllFieldInForm)
  const dropdownOptionsByPage = useSelector((state) => state.CMS.dropdownOptionsByPage)
  const [cachedReduxDataUsed, setCachedReduxDataUsed] = useState(false)
  const [optionsNotSyncWithDB, setOptionsNotSyncWithDB] = useState(false)

  const [linkCategoryModel, setLinkCategoryModel] = useState(false)

  const dispacth = useDispatch()
  const selected_domains = domains?.filter((domain) => domain?.selected)
  const data_connection = data?.component_call_data_connection

  const [subtitleFontSize, setSubtitleFontSize] = useState('16px') // Default font size

  useEffect(() => {
    // Dynamically fetch font size from the subtitle1 class
    const subtitleElement = document.querySelector('.subtitle1')
    if (subtitleElement) {
      const computedStyle = getComputedStyle(subtitleElement)
      setSubtitleFontSize(computedStyle.fontSize)
    }
  }, [])

  useEffect(() => {
    checkAndClearQueueForGivenSourceTable(data_connection?.source_table)
  }, [])
  useEffect(() => {
    if (
      updateApiData[data_connection?.source_table] &&
      updateApiData[data_connection?.source_table]?.length > 0 &&
      dropdownOptionsByPage[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.['updatedAt']
    ) {
      const lastUpdatedTimeInRedux = dropdownOptionsByPage[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.['updatedAt']
      const gridUpdatedDate = lastUpdatedTimeInRedux && new Date(lastUpdatedTimeInRedux)
      var offset = gridUpdatedDate.getTimezoneOffset()
      const utcGridUpdated = new Date(gridUpdatedDate.getTime() + offset * 60000)
      const lastSyncDate = utcGridUpdated && new Date(utcGridUpdated)
      const lastUpdatedDateInDB = new Date(updateApiData[data_connection.source_table])
      if (lastSyncDate - lastUpdatedDateInDB < 0) {
        setOptionsNotSyncWithDB(true)
        const optionsObjectToStoreInRedux = getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown(
          dropdownOptionsByPage,
          parentPageName,
          data?.field_id,
          data_connection?.source_table
        )

        dispacth(setDropdownOptionsByPage(optionsObjectToStoreInRedux))
      } else {
        setOptionsNotSyncWithDB(false)
      }
    }
  }, [updateApiData, dropdownOptionsByPage[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.['updatedAt']])

  const loadOptions = async (search) => {
    let options = []

    if (data?.field_id === 'homepage') {
      return {
        options: getHomePageDropdownOptions(),
        hasMore: false,
      }
    }

    const data_connection = data?.component_call_data_connection || data?.field_setting?.component_call_data_connection

    if (data_connection) {
      if (data_connection.length > 0) {
        const reduxOptions = []
        data_connection?.map((connection) => {
          if (connection.component_name === 'redux_data') {
            const getReduxData = getFromRedux(connection.redux_data)
            updateReduxData(getReduxData)
            let options = []

            if (getReduxData && typeof getReduxData.length === 'number') {
              getReduxData.map((item) => {
                reduxOptions.push({
                  value: item[connection.value],
                  label: capitalize(item[connection.data]),
                })
              })
            }

            updateKeysForReduxData({ value: connection.value, data: connection.data }, fieldKey)
          }
        })

        return {
          options: reduxOptions,
          hasMore: false,
        }
      } else if (data_connection.component_name === 'template_data') {
        let payload = { ...data_connection, page: paginationData?.pageNo ? paginationData?.pageNo : 1 }
        const clauseData = data_connection?.clause_data
        const new_clause_data = []
        const a = data?.field_id

        clauseData?.forEach((data) => {
          if (data?.ref_for_form_field && repeatingFormItem) {
            const field = data?.value?.split('.')
            let new_clause
            if (field?.length === 0) {
              new_clause = {
                ...data,
                value: repeatingFormItem[field[0]],
              }
            } else {
              new_clause = {
                ...data,
                value: repeatingFormItem[field[0]][field[1]],
              }
            }
            new_clause_data?.push(new_clause)
          } else {
            new_clause_data?.push(data)
          }
        })

        payload = { ...payload, clause_data: new_clause_data }

        if (formData?.template_name === 'active_block_main_details' && data?.field_id === 'template_id') {
          if (!formFieldValue.item_date_time_id) {
            return {
              options: [],
              hasMore: false,
            }
          }
          payload = {
            ...payload,
            item_date_time_id: formFieldValue?.item_date_time_id,
          }
        }
        if (search && search?.length > 0) {
          // const with_data = checkDependDataExist(data?.field_id, allFieldsInForm)
          // if (with_data) {
          //   payload = { ...payload, with: with_data }
          // }
          payload = {
            ...payload,
            searchKey: search,
          }
          const optionsObjectToStoreInRedux = getDropdownOptionsToUpdateInReduxAfterClearCurrentDropdown(
            dropdownOptionsByPage,
            parentPageName,
            data?.field_id,
            data_connection?.source_table
          )
          dispacth(setDropdownOptionsByPage(optionsObjectToStoreInRedux))
        }
        if (data?.field_id === 'template_group_location_id') {
          payload = {
            ...payload,
            template_group_id: currentRow && currentRow[currentRow?.length - 1]?.id,
          }
        }
        if (data?.field_id === 'location_resource_id') {
          payload = {
            ...payload,
            template_group_location_id: currentFormData && currentFormData['template_group_location_id'],
          }
          if (!payload?.template_group_location_id) {
            return {
              options: [],
              hasMore: false,
            }
          }
        }

        if (
          data?.field_id === 'upload_document_type_id' ||
          data?.field_id === 'upload_document_id' ||
          (data?.field_id === 'upload_document_types' && !parentPageName)
        ) {
          if (currentRow && parentPageName === 'contractors') {
            payload = {
              ...payload,
              top_parent_table_row_id: currentRow[0]?.id,
              top_parent_table: parentPageName,
            }
          }
        }
        if (
          data?.field_id === 'template_id' ||
          data?.field_id === 'user_id' ||
          (parentTableDataInRedux?.[0]?.source_table === 'domains' && data?.field_id === 'categories_id')
        ) {
          payload = {
            ...payload,
            top_parent_table_row_id: currentRow[0]?.id,
            top_parent_table: 'domains',
          }
        }
        if (
          dropdownOptionsByPage?.[parentPageName]?.[data?.field_id]?.[data_connection?.source_table] &&
          !search &&
          !cachedReduxDataUsed &&
          !optionsNotSyncWithDB &&
          !noCache
        ) {
          setPaginationData(dropdownOptionsByPage[parentPageName][data?.field_id][data_connection?.source_table]?.paginationData)
          setCachedReduxDataUsed(true)

          return {
            options: dropdownOptionsByPage[parentPageName][data?.field_id][data_connection?.source_table]?.options,
            hasMore: dropdownOptionsByPage[parentPageName][data?.field_id][data_connection?.source_table]?.hasMore,
          }
        }

        if (dropdownOptionsByPage?.[parentPageName]?.[data?.field_id]?.[data_connection?.source_table] && !noCache) {
          if (payload?.page < dropdownOptionsByPage?.[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.paginationData?.pageNo) {
            setPaginationData(dropdownOptionsByPage[parentPageName][data?.field_id][data_connection?.source_table]?.paginationData)
            payload = {
              ...payload,
              page: dropdownOptionsByPage?.[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.paginationData?.pageNo,
            }
          }
        }
        payload = { ...payload, domain_id }
        if (parentRowIdRequiredInPayload) {
          payload = {
            ...payload,
            parent_row_id: currentRow[currentRow?.length - 1]?.id,
            parent_source_table: parentTableDataInRedux?.[parentTableDataInRedux?.length - 1]?.source_table,
          }
        }
        payload = { ...payload, page: paginationData?.pageNo ? paginationData?.pageNo : 1 }
        const response = await request('connect-template-data', 'post', payload)

        if (response?.data?.data?.data && response?.data?.data?.data?.length !== 0) {
          setSelectOptions([...selectOptions, ...response?.data?.data?.data])
          const is_signle_field = data_connection.fields?.split(',')?.length === 1 ? true : false
          response?.data?.data?.data.map((item) => {
            if (is_signle_field) {
              if (data?.field_id === 'item_date_times_id' || data?.field_id === 'item_date_time_id') {
                let title = item?.name || item?.title
                options.push({
                  value: item?.id,
                  label: title ? extractYears(title) : title,
                })
              } else {
                options.push({
                  value: item?.id,
                  label: capitalize(item[data_connection.fields] || item['name'] || item[data_connection.fields.split('.').pop()]),
                })
              }
            } else {
              let label_field = data_connection.fields?.split(',')[1]
              if (data?.field_id === 'item_date_times_id' || data?.field_id === 'item_date_time_id') {
                let title = item?.name || item?.title
                options.push({
                  value: item[data_connection.fields?.split(',')[0]],
                  label: title ? extractYears(title) : title,
                })
              } else {
                options.push({
                  value: item[data_connection.fields?.split(',')[0]],
                  label:
                    capitalize(item[`${label_field}_label`]) ||
                    capitalize(item[data_connection.fields?.split(',')[1] || item[data?.field_id.split('.').pop()]]),
                })
              }
            }
          })
          setPaginationData({
            pageNo: response?.data?.data?.current_page + 1,
            hasMore: response?.data?.data?.next_page_url ? true : false,
          })

          let filteredOptions = []
          if (!search) {
            filteredOptions = options
            const optionsObjectToStoreInRedux = getDropdownOptionsToUpdateInRedux(
              dropdownOptionsByPage,
              parentPageName,
              data?.field_id,
              data_connection?.source_table,
              options,
              {
                pageNo: response?.data?.data?.current_page + 1,
                hasMore: response?.data?.data?.next_page_url ? true : false,
              },
              response?.data?.data?.next_page_url ? true : false,
              response?.data?.data?.data
            )

            dispacth(setDropdownOptionsByPage(optionsObjectToStoreInRedux))
          } else {
            const searchLower = search.toLowerCase()

            filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower))
          }

          return {
            options: filteredOptions,
            hasMore: response?.data?.data?.next_page_url ? true : false,
          }
        } else if (response?.data?.data?.data?.length === 0) {
          setPaginationData({
            pageNo: paginationData?.pageNo + 1,
            hasMore: false,
          })
          return {
            options: options,
            hasMore: false,
          }
        }
      } else if (data_connection.component_name === 'dependent_data') {
        const dependent_value = depend_value || currentFormData?.[data?.component_call_data_connection?.source_field]

        let payload = getDependDataPayload(allFieldsInForm, data_connection, dependent_value, depend_field_source_table)

        payload = {
          ...payload,
          page: paginationData?.pageNo,
        }
        if (search && search?.length > 0) {
          payload = {
            ...payload,
            searchKey: search,
          }
        }
        payload = { ...payload, domain_id }
        if (parentRowIdRequiredInPayload) {
          payload = {
            ...payload,
            parent_row_id: currentRow[currentRow?.length - 1]?.id,
          }
        }

        const response = await request('connect-template-data-depend', 'post', payload)

        if (response?.data?.data?.data && response?.data?.data?.data?.length !== 0) {
          const field = data_connection?.data?.split('.')[1]

          response?.data?.data?.data.map((item) => {
            options.push({
              value: item[field]?.id,
              label: capitalize(item[field]?.name),
            })
          })

          setPaginationData({
            pageNo: response?.data?.data?.current_page + 1,
            hasMore: true,
          })

          let filteredOptions = []
          if (!search) {
            filteredOptions = options
          } else {
            const searchLower = search.toLowerCase()

            filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower))
          }
          return {
            options: filteredOptions,
            hasMore: !response?.data?.data?.next_page_url ? false : true,
          }
        } else if (response?.data?.data?.data?.length === 0) {
          setPaginationData({
            pageNo: response?.data?.data?.current_page + 1,
            hasMore: false,
          })
          return {
            options: options,
            hasMore: false,
          }
        } else {
          return {
            options: [],
            hasMore: false,
          }
        }
      } else if (data_connection.component_name === 'manual') {
        if (data_connection?.default_options) {
          let default_options = []

          if (Array.isArray(data_connection?.default_options)) {
            default_options = data_connection?.default_options?.map((item) => {
              return {
                value: item,
                label: tidyName(capitalize(item)),
              }
            })
          } else {
            Object.keys(data_connection?.default_options)?.map((item) => {
              default_options?.push({
                value: item,
                label: tidyName(capitalize(data_connection?.default_options[item])),
              })
            })
          }
          return {
            options: default_options,
            hasMore: false,
          }
        } else {
          return {
            options: [],
            hasMore: false,
          }
        }
      } else {
        // return {
        //   options: [],
        //   hasMore: false,
        // }
      }
    } else if (data?.component_name === 'text_data_col') {
      if (data?.field_setting?.values) {
        if (Array.isArray(data?.field_setting?.values)) {
          const default_options = data?.field_setting?.values?.map((item) => {
            return {
              value: item,
              label: item,
            }
          })
          return {
            options: default_options,
            hasMore: false,
          }
        } else {
          const default_options = Object.keys(data?.field_setting?.values)?.map((key) => {
            return {
              value: key,
              label: data?.field_setting?.values[key],
            }
          })
          return {
            options: default_options,
            hasMore: false,
          }
        }
      } else {
        return {
          options: [],
          hasMore: false,
        }
      }
    }
    return {
      options: [],
      hasMore: false,
    }
  }
  const [inputValue, onInputChange] = useState('')
  const [clearOptions, setClearOptions] = useState(false)
  const dependent_value = currentFormData && currentFormData[data?.component_call_data_connection?.source_field]
  const prev_dependent_value = usePrevious(dependent_value)
  const prevItemDateTimeId = usePrevious(formFieldValue?.item_date_time_id)
  const prevTemplateId = usePrevious(formFieldValue?.template_id)
  useEffect(() => {
    //applicable only for block main details: need to enhance the code
    if (
      formData?.template_name === 'active_block_main_details' &&
      data?.field_id === 'template_id' &&
      prevItemDateTimeId !== formFieldValue?.item_date_time_id
    ) {
      setClearOptions(!clearOptions)
      // setSelectedOption(null)
    }
    if (
      formData?.template_name === 'active_block_main_details' &&
      data?.field_id === 'template_id' &&
      formFieldValue?.template_id !== prevTemplateId &&
      prevTemplateId
    ) {
      // setClearOptions(!clearOptions)
      setSelectedOption(null)
    }
  }, [formFieldValue])

  useEffect(() => {
    if (prev_dependent_value) {
      //current row will be updated after rendering this component

      setClearOptions(!clearOptions)
      setSelectedOption(null)
    }
  }, [dependent_value])

  useEffect(() => {
    if (data?.field_id === 'location_resource_id') {
      setOptionClear(!optionClear)
      setSelectedOption(null)
    }
    if (refetchTableList?.includes(data_connection?.source_table)) {
      setOptionClear(!optionClear)
      removeDropdownOptionRefetchTable(data_connection?.source_table)
    }
  }, [currentFormData?.template_group_location_id, refetchTableList])

  const onInputValueChange = (e) => {
    onInputChange(e)

    setPaginationData({
      pageNo: 1,
      hasMore: true,
    })
  }

  useEffect(() => {
    if (value && inputLabel) {
      if (data?.format?.is_multi_select) {
        const option = value?.map((item) => {
          return {
            value: item?.value,
            label: capitalize(item?.label),
          }
        })
        setSelectedOption(option) // for multi select direct array need to pass
      } else {
        if (data?.field_id === 'homepage') {
          const val = getHomePageDropdownOptions()?.find((item) => item?.value === value)
          setSelectedOption(val)
        } else {
          setSelectedOption({ label: tidyName(capitalize(inputLabel || value)), value: value })
          if (parentPageName === 'user_security' || parentPageName?.includes('security')) {
            storeUserSecurityOverviewDropdown(data?.field_id, value)
          }
        }

        let newSelectedDropdownData = {
          ...selectedDropdownData,
        }

        newSelectedDropdownData[data?.field_id] = {
          //  ...a[0],
          label: inputLabel || value,
          value: value,
          has_expiry: extraDefaultFormValue?.has_expiry,
        }

        dispacth(setSelectedDropdownData(newSelectedDropdownData))
      }
    } else {
      let newSelectedDropdownData = {
        ...selectedDropdownData,
      }
      newSelectedDropdownData[data?.field_id] = {
        //  ...a[0],
        label: inputLabel || value,
        value: value,
        has_expiry: extraDefaultFormValue?.has_expiry,
      }
      setSelectedOption(null)
      if (parentPageName?.includes('security')) {
        storeUserSecurityOverviewDropdown(data?.field_id, null)
      }

      dispacth(setSelectedDropdownData(newSelectedDropdownData))
    }
  }, [value, currentRow?.length])

  useEffect(() => {
    if (clearAllFieldInForm) {
      setSelectedOption(null)
    }
  }, [clearAllFieldInForm])

  const changeOption = (value, actions, field) => {
    let a
    if (dropdownOptionsByPage[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.options_data) {
      a = dropdownOptionsByPage[parentPageName]?.[data?.field_id]?.[data_connection?.source_table]?.options_data?.filter(
        (item) => item?.id === value?.value
      )
    }
    let new_selected_value

    // if (parentPageName?.includes('security')) {
    //   storeUserSecurityOverviewDropdown(data?.field_id, value?.value)
    // }

    if (value) {
      setSelectedOption(value)
      if (a?.length > 0) {
        let newSelectedDropdownData = {
          ...selectedDropdownData,
        }

        newSelectedDropdownData[data?.field_id] = {
          ...a[0],
        }

        dispacth(setSelectedDropdownData(newSelectedDropdownData))
      } else if (data_connection?.component_name === 'manual') {
        let newSelectedDropdownData = {
          ...selectedDropdownData,
        }

        newSelectedDropdownData[data?.field_id] = {
          ...value,
        }
        dispacth(setSelectedDropdownData(newSelectedDropdownData))
      }
      if (data?.format?.is_multi_select) {
        // let selectedData
        // if (reduxData) {
        //   selectedData = reduxData.filter((item) => item && item.id == value)
        // }
        onChangeAction('', actions, field, value)
      } else {
        onChangeAction('', actions, field, value?.value, value?.label)
      }
      onChangeValue(data.field_id, value?.value, value?.label)
      if (data?.field_id === 'item_date_time_id') {
        setFormFieldValue((data) => {
          return {
            ...data,
            template_id: null,
            template_id_label: null,
          }
        })
      }
    } else {
      setSelectedOption({ value: null, label: '' })
      onChangeAction('', actions, field, null, '')
      onChangeValue(data.field_id, null, '')
    }
  }
  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)
  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)

  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (selectedOption?.value === value && selectedOption?.value) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (selectedOption?.value) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }

    if (data?.component_call_actions) {
      if (Array.isArray(data?.component_call_actions)) {
        const newTabsToDisabled = [...enabledOrDisabledMethodTab]
        data?.component_call_actions?.forEach((action) => {
          if (
            action?.component_name === 'enable_or_disable_method_tab' &&
            selectedOption?.value === action?.if_value &&
            action?.enable_field &&
            currentRow?.[currentRow?.length - 1]?.[data?.field_id] === action?.if_value
          ) {
            const index = newTabsToDisabled?.indexOf(action.method_name)

            if (index !== -1) {
              newTabsToDisabled?.splice(index, 1)
            }
          } else if (
            action?.component_name === 'enable_or_disable_method_tab' &&
            selectedOption?.value !== action?.if_value &&
            currentRow?.[currentRow?.length - 1]?.[data?.field_id] !== action?.if_value &&
            action?.enable_field
          ) {
            if (!newTabsToDisabled?.includes(action.method_name)) {
              newTabsToDisabled?.push(action.method_name)
            }

            //  dispacth(enabledOrDisabledMethodTabAction(newTabsToDisabled))
          }
        })
        if (JSON.stringify(enabledOrDisabledMethodTab) !== JSON.stringify(newTabsToDisabled)) {
          dispacth(enabledOrDisabledMethodTabAction(newTabsToDisabled))
        }
      }
    }
  }, [selectedOption?.value, currentRow?.[currentRow?.length - 1]?.[data?.field_id]])
  useEffect(() => {
    if (setFormFieldValue) {
      setFormFieldValue((oldValue) => {
        let newValue = { ...oldValue }
        newValue[data?.field_id] = selectedOption?.value
        newValue[`${data?.field_id}_label`] = selectedOption?.label

        return newValue
      })
    }
  }, [selectedOption?.value])

  const prev_domain_id = usePrevious(domain_id)
  useEffect(() => {
    onChangeAction('', data?.component_call_actions, data?.field_id, value, value, true)
    // clear selected value when domain change and default value is not available

    if (prev_domain_id !== domain_id && prev_domain_id && isFieldUsedDirectly) {
      setSelectedOption(null)
      onChangeAction('', '', '', null, null, true)
    }
  }, [domain_id])

  if (!inlineEditActive) {
    return inputLabel || value
  }

  let isFieldVisible = true

  if (
    data?.field_setting?.depend_fields &&
    data?.field_setting?.depend_fields?.length > 0 &&
    ((currentRow && currentRow[currentRow?.length - 1]) || currentFormData)
  ) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      if (currentFormData && Object.keys(currentFormData)?.includes(dependForm?.dependent_field)) {
        return checkSingleCondition(dependForm?.condition, currentFormData[dependForm?.dependent_field], parseInt(dependForm?.value))
      } else {
        if (currentRow && currentRow[currentRow?.length - 1]) {
          return checkSingleCondition(
            dependForm?.condition,
            currentRow[currentRow?.length - 1][dependForm?.dependent_field],
            parseInt(dependForm?.value)
          )
        }
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return
  return (
    <div>
      {(!isTable || (isTable && isRepeatingForm)) && (
        <label onClick={() => (cmsEditMode && data?.cms_key ? editCMS(data.cms_key) : null)} className='subtitle1'>
          {cms(data.cms_key)} {!data?.cms_key && tidyName(data?.name)}{' '}
          {(data.component_call_fields || data?.field_setting?.component_call_fields) && (
            <ToggleField
              data={data}
              toggleActions={toggleActions}
              parentPageName={parentPageName}
              pageName={pageName}
              toggled_field_id={data?.field_id}
            />
          )}
          {parentTableDataInRedux?.[0]?.source_table === 'domains' && data?.field_id === 'categories_id' && (
            <i
              className='fa-duotone fa-list ml-2 cursor-pointer'
              style={{ color: primary_color }}
              onClick={() => {
                setLinkCategoryModel(true)
              }}></i>
          )}
        </label>
      )}
      {linkCategoryModel && <LinkCategory setLinkCategroyModel={setLinkCategoryModel}></LinkCategory>}

      <AsyncPaginate
        id={id}
        isDisabled={disabled_field?.includes(data?.field_id)} // not working, need to fix
        loadOptions={loadOptions}
        value={selectedOption?.value ? selectedOption : data?.format?.is_multi_select ? selectedOption : null}
        onChange={(e) => {
          changeOption(e, data.component_call_actions, data.field_id)
        }}
        isMulti={data?.format && data?.format?.is_multi_select ? true : false}
        classNamePrefix='select'
        styles={{
          menu: (base) => ({ ...base, zIndex: 15000, fontSize: subtitleFontSize }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isFieldInSync ? primary_color : '#ced4da',
            fontSize: subtitleFontSize,
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: 'hsl(0, 0%, 70%)',
              fontSize: subtitleFontSize,
            }
          },
        }}
        inputValue={inputValue}
        onInputChange={(e) => onInputValueChange(e)}
        cacheUniqs={[inputValue, clearOptions, optionClear, domain_id, selected_domains?.length, linkCategoryModel]}
        cacheOptions={false}
        debounceTimeout={1000}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        isClearable={isClearable || data?.format?.is_multi_select ? false : true}
      />
      {data?.format?.is_multi_select ? (
        <AvField
          id={data.field_id || data?.name || 'no_name'}
          type='hidden'
          value={selectedOption}
          name={data.field_id || data?.name || 'no_name'}
          validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
        />
      ) : (
        <>
          <AvField
            id={data.field_id || data?.name || 'no_name'}
            type='hidden'
            value={selectedOption?.value ? selectedOption?.value : ''}
            name={data.field_id || data?.name || 'no_name'}
            validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
          />
          <AvField
            id={data.field_id || data?.name || 'no_name'}
            type='hidden'
            value={selectedOption?.label ? selectedOption?.label : ''}
            name={data.field_id + '_label' || data?.name + '_label' || 'no_name'}
          />
        </>
      )}
    </div>
  )
}

export default connect(select)(CMSSelect)
