import React, { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { clearNotification, setNotification } from '../../../Redux/Reducers/notificationHandling'
import countries from '../../../assets/utils/data/country-cities'
import { primary_color } from '../../../Helper/uiHelper'
import Select from 'react-select'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import '../../Form/Fields/roundedInput.scss'
const AddressForm = ({ setShowAddressFields, userAddress, setUserAddress, additionalSubmit, isGuestCheckout, showValidation, setShowValidation }) => {
  const [cities, setCities] = useState([])
  const isDesktop = useMediaQuery('(min-width:768px)')


  const dispatch = useDispatch()
  useEffect(() => {
    if (userAddress?.country) setCities(countries.find((element) => element.country === userAddress.country)?.cities || [])
  }, [])

  const formChange = (e) => {
    dispatch(clearNotification())
    setUserAddress((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const countryChange = (e) => {
    const country = e.target.value
    setCities(countries.find((element) => element?.country === country)?.cities || [])
    formChange(e)
  }
  const resetForm = () => {
    setCities([])
    setUserAddress({
      address1: '',
      country: '',
      display: 1,
      city: '',
      post_code: '',
    })
    setShowAddressFields(false)
  }
  const saveAddress = (e) => {
    e.preventDefault()
    request('user/address/store', 'POST', userAddress)
      .then((res) => {
        dispatch(setNotification({ type: 'success', message: 'Address saved successfully' }))
      })
      .catch((e) => {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
      })
      .finally(() => {
        setUserAddress({
          address1: '',
          country: '',
          display: 1,
          city: '',
          post_code: '',
        })
        if (additionalSubmit) additionalSubmit(false)
      })
  }

  return (
    <div>
      <form onSubmit={saveAddress} className='d-flex flex-column mt-3' style={{ gap: '0.75rem' }}>
        <div>
          <input
            className={`form-control subtitle1 rounded-pill rounded-input rounded-input-container subtitle1 ${
              showValidation && !userAddress?.address1 ? 'error-input' : ''
            }`}
            name={'address1'}
            value={userAddress?.address1}
            onChange={formChange}
            placeholder='Billing Address'
            required
          />
          {showValidation && !userAddress?.address1 && <div className='text-danger subtitle1 subtitle1 text-left pl-2'>Please enter Email</div>}
        </div>
        <div>
          <input
            className={`form-control rounded-input-container rounded-input subtitle1 subtitle1 rounded-pill ${
              showValidation && !userAddress?.city ? 'error-input' : ''
            }`}
            placeholder='City'
            name={'city'}
            type='text'
            value={userAddress?.city}
            onChange={formChange}
            required
          />

          {showValidation && !userAddress?.city && <div className='text-danger subtitle1 text-left pl-2'>Please select city</div>}
        </div>
        <div>
          <input
            className={`form-control rounded-input-container rounded-input subtitle1 subtitle1 rounded-pill ${
              showValidation && !userAddress?.county ? 'error-input' : ''
            }`}
            placeholder='County'
            name={'county'}
            type='text'
            value={userAddress?.county}
            onChange={formChange}
            required
          />

          {showValidation && !userAddress?.county && <div className='text-danger subtitle1 text-left pl-2'>Please select county</div>}
        </div>
        <div>
          <input
            className={`form-control subtitle1 rounded-input-container rounded-input rounded-pill subtitle1 ${
              showValidation && !userAddress?.post_code ? 'error-input' : ''
            }`}
            placeholder='Postal code'
            name={'post_code'}
            type='text'
            value={userAddress?.post_code}
            onChange={formChange}
            required
          />
          {showValidation && !userAddress?.post_code && <div className='text-danger subtitle1 subtitle1 text-left pl-2'>Please enter post code</div>}
        </div>

        <div>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '50px',
                borderColor: showValidation && !userAddress?.country ? 'red' : baseStyles.borderColor,
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: showValidation && !userAddress?.country ? 'red' : 'rgba(0, 0, 0, 0.2)',
              }),
            }}
            defaultValue={{ value: 'United Kingdom', label: 'United Kingdom' }}
            classNamePrefix='rounded-select'
            placeholder='Country'
            onChange={(e) => {
              const country = e.value
              setCities(countries.find((element) => element.country === country)?.cities || [])
              setUserAddress((prev) => {
                return { ...prev, country: e.value }
              })
            }}
            options={countries?.map((e) => {
              return { label: e.country, value: e.country }
            })}
            className='rounded-select subtitle1 subtitle1'
          />
          {showValidation && !userAddress?.country && <div className='text-danger subtitle1 text-left pl-2'>Please select country</div>}
        </div>

        <div className=' d-flex' style={{ gap: '1rem' }}>
          {!isGuestCheckout && (
            <button
              type='submit'
              className='btn mt-3 text-white'
              style={{
                borderRadius: '20px',
                background: primary_color,
                width: isDesktop ? '25%' : '100%',
              }}>
              SAVE
            </button>
          )}
          {!isGuestCheckout && (
            <button
              // type='submit'
              onClick={resetForm}
              className='btn mt-3 text-danger d-inline'
              style={{
                borderRadius: '20px',
                background: 'white',
                border: '1px solid red',
                width: isDesktop ? '25%' : '100%',
              }}
              variant='outline-primary'>
              CANCEL
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default AddressForm
