import PermisionBlock from './PermissionBlock'

const PermissionGroup = ({
  title,
  item,
  isMainPageViewEnabled,
  role_id,
  isCreatingNewRole,
  onClickPermissionForNewRole,
  selectedPermissionToCreateRole,
}) => {
  const permissions = item?.data
  const viewPermission = item?.data?.find((permission) => permission?.permission === 'view')

  if (title?.toLowerCase()?.includes('spacer')) return

  return (
    <div className='permission-group py-3'>
      <div className='mb-4 text-capitalize subtitle1 font-weight-bold' style={{ color: '#8E8E8E' }}>
        {title}
      </div>
      <div className='d-flex flex-wrap' style={{ gap: '24px' }}>
        {permissions?.map((permission) => {
          return (
            <PermisionBlock
              text={permission?.permission}
              item={permission}
              viewPermission={viewPermission}
              isMainPageViewEnabled={isMainPageViewEnabled}
              role_id={role_id}
              isCreatingNewRole={isCreatingNewRole}
              onClickPermissionForNewRole={onClickPermissionForNewRole}
              selectedPermissionToCreateRole={selectedPermissionToCreateRole}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PermissionGroup
