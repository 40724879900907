import React, { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import { IoIosRadioButtonOn, IoIosRadioButtonOff } from 'react-icons/io'
import { connect, useDispatch } from 'react-redux'
import cx from 'classnames'

import { clearConnectTemplateReduxData, clearLastDataConnectionData, setDomain, setMenuList, submitApi } from '../../Redux/Reducers/CMS'

import { can, cms, editCMS, dataAction, tidyName } from '../../helper'
import bg4 from '../../assets/utils/images/dropdown-header/abstract4.jpg'
import NoData from './noData'
import { reduxLoad } from '../../Redux/Reducers'
import { setDomains } from '../../Redux/Reducers/CMS'
import { request } from '../../Redux/Sagas/requests/api'
import { setNotification } from '../../Redux/Reducers/notificationHandling'

const select = (state) => ({
  lastDataConnectionData: state.CMS.lastDataConnectionData,
  reduxMenuList: state.CMS.menuList,
  loader: state.Loader.loader,
  domains: state.CMS.coreData.domains,
})

const HorizontalMenu = ({ lastDataConnectionData, page, reduxMenuList, loader, domains }) => {
  const dispatch = useDispatch()
  const [menuData, updateMenuData] = useState({
    name: '',
    updatedAt: null,
  })

  const handleClick = (item, row) => {
    dispatch(clearLastDataConnectionData())
    let newPayload
    let oldPayload
    item.component_call_actions.map((action) => {

      switch (action.component_name) {
        case 'submit_api':
          newPayload = [...action.payload]
          oldPayload = action.payload[1]
          if (Array.isArray(action.payload) && action.payload[1]) {
            if (typeof action.payload[1] === 'string') {
              newPayload[1] = row[newPayload[1].replace(/[{}]/g, '')]
            }
          }
          dispatch(submitApi({ url: action.url, payload: newPayload, noLoad: true }))
          if (action.url === 'database-switch') {
            const newDomains = domains?.map((domain) => {
              if (domain?.id === newPayload?.[1]) {
                return {
                  ...domain,
                  selected: !domain?.selected,
                }
              } else {
                return { ...domain }
              }
            })
            dispatch(setDomains(newDomains))
          }

          setTimeout(() => {
            // action.payload[1] = oldPayload

            if (action.url !== 'database-switch') {
              dataAction(item.component_call_pill_data_connection?.[0], item.name)
              dispatch(setMenuList({ ...reduxMenuList, [item.name]: '' }))
            }

            dispatch(clearConnectTemplateReduxData())
          }, 1500)

          break
        case 'refresh_cms':
          dispatch(reduxLoad())
        default:
      }
    })
  }

  const onToggleDomain = (id, value) => {
    if (!value) return
    dispatch(setDomain(id))
    let newDomains = domains?.map((domain) => {
      if (domain?.domain_id === id) {
        return {
          ...domain,
          active: true,
        }
      } else {
        return {
          ...domain,
          active: false,
        }
      }
    })
    dispatch(setDomains(newDomains))
    request('set-active-domain', 'POST', { domain_id: id })
  }

  useEffect(() => {
    if (page.allowed && can(page.permission)) {
      page.component_call_buttons.map((item) => {
        switch (item.component_name) {
          case 'radio_nav':
            if (item?.component_call_pill_data_connection?.length > 0) {
              if (!reduxMenuList[item.name] && item.name !== 'switch_database_radio_nav') {
                dataAction(item.component_call_pill_data_connection[0], item.name)
                if (lastDataConnectionData.name === item.name) {
                  dispatch(
                    setMenuList({
                      ...reduxMenuList,
                      [item.name]: lastDataConnectionData.data.data,
                    })
                  )
                  updateMenuData({
                    name: item.name,
                    updatedAt: lastDataConnectionData.updatedAt,
                  })
                }
              }
            }
            break
          default:
            break
        }
      })
    }
  }, [lastDataConnectionData])

  const renderList = (page) => {
    return page.component_call_buttons.map((item, key) => {
      let heading = null
      let list = null
      switch (item.component_name) {
        case 'radio_nav':
          heading = (
            <h6 tabIndex='-1' className='dropdown-header'>
              {tidyName(item.name)}
            </h6>
          )
          if (reduxMenuList[item.name] && item.name !== 'switch_database_radio_nav') {
            list = reduxMenuList[item.name].map((item1, key) => {
              return (
                <DropdownItem key={key} onClick={(e) => handleClick({ ...item }, item1)}>
                  {item1.selected ? (
                    <IoIosRadioButtonOn className='mr-3 opacity-8' color='#00d627' fontSize='23px' />
                  ) : (
                    <IoIosRadioButtonOff className='mr-3 opacity-8' fontSize='23px' />
                  )}
                  {item1.name}
                </DropdownItem>
              )
            })
          } else if (item.name === 'switch_database_radio_nav') {
            list = domains?.map((item1, key) => {
              return (
                <div
                  key={key}
                  className='d-flex justify-content-between px-3 py-2 cursor-pointer dropdown-item'
                  onClick={(e) => {
                    if (item1?.active) {
                      e.stopPropagation()
                      e.preventDefault()
                    } else {
                      handleClick(item, item1)
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  }}>
                  <div className='d-flex'>
                    <span
                      onClick={(e) => {
                        if (item1?.selected) {
                          onToggleDomain(item1?.domain_id, !item1?.active)
                        }
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                      style={{ opacity: !item1?.selected && '0.1' }}>
                      {item1.active ? (
                        <IoIosRadioButtonOn className='mr-3 opacity-8' color='#00d627' fontSize='23px' />
                      ) : (
                        <IoIosRadioButtonOff className='mr-3 opacity-8' fontSize='23px' />
                      )}
                    </span>
                    <div>{item1.domain_name}</div>
                  </div>

                  <div
                    className='switch has-switch float-right'
                    style={{ marginBottom: '-5px', marginLeft: 'auto', height: 'fit-content' }}
                    data-on-label='ON'
                    data-off-label='OFF'
                    onClick={(e) => {}}>
                    <div
                      className={cx('switch-animate', {
                        'switch-on': item1?.selected,
                        'switch-off': !item1?.selected,
                      })}>
                      <input type='checkbox' />
                      <span className='switch-left bg-info'>ON</span>
                      <label>&nbsp;</label>
                      <span className='switch-right bg-info'>OFF</span>
                    </div>
                  </div>
                </div>
              )
            })
          }

          break
        default:
          break
      }

      return (
        can(item.permission) && (
          <React.Fragment key={key}>
            {heading}
            {list}
          </React.Fragment>
        )
      )
    })
  }

  if (page.allowed && can(page.permission)) {
    return (
      <React.Fragment>
        <div className='dropdown-menu-header tesst'>
          <div className='dropdown-menu-header-inner bg-plum-plate'>
            <div
              className='menu-header-image'
              style={{
                backgroundImage: 'url(' + bg4 + ')',
              }}
            />
            <div className='menu-header-content text-white'>
              <h5 className='menu-header-title' onClick={() => editCMS(page.cms_header_name)}>
                {cms(page.cms_header_name)}
              </h5>
              <h6 className='menu-header-subtitle' onClick={() => editCMS(page.cms_sub_header_name)}>
                {cms(page.cms_sub_header_name)}
              </h6>
            </div>
          </div>
        </div>
        {renderList(page)}
      </React.Fragment>
    )
  } else {
    return <NoData message={page.not_allowed_message ? page.not_allowed_message : 'You do not have permission to see this.'} />
  }
}
export default connect(select)(HorizontalMenu)
