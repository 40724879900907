import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

import { cms, createFieldByFieldIdNtype, tidyName } from '../../helper'
import CMSField from './Fields'
import { setCoreData } from '../../Redux/Reducers/CMS'
import { AvField } from 'availity-reactstrap-validation'

const select = (state) => ({
  pages: state.CMS.coreData.page,
  coreData: state.CMS.coreData,
  grid_selector: state.CMS.coreData.grid_selector,
  tabFormData: state.Page.tabFormData,
})

const RenderFields = ({
  allForm,
  coreData,
  twoCol,
  noTitle = true,
  tabFormData,
  isModalAction,
  isModel,
  content,
  defaultFormValue,
  onValueChange,
  submittedFormData,
  parentPageName,
  pageName,
  source_table,
  formFieldValue,
  setFormFieldValue,
  validationErrorOnFieldValueChange,
  setValidationErrorOnFieldValueChange,
  noCache,
  disabled_field,
}) => {

  const dispatch = useDispatch()
  const [render, re_render] = useState(false)
  const [layout, setLayout] = useState()

  const renderSection = (item, key) => {
    const toggleActions = (action) => {
      item.component_call_fields = item.component_call_fields.map((item_field) => {
        if (item_field.field_id === action.field_id) {
          setTimeout(() => {
            re_render(!render)
          }, 200)
          return { ...item_field, hidden: action.show_field ? 0 : 1 }
        } else {
          return item_field
        }
      })
    }

    const updateValues = (field, newCoreData) => {
      let updatedFields = item.component_call_fields
      updatedFields = updatedFields.map((item, key) => {
        if (item.field_id === field && item.component_call_data_connection && item.component_call_data_connection.length > 0) {
          for (const item1 of item.component_call_data_connection) {
            if (item1.component_name === 'dependant_data_source') {
              if (newCoreData.currentFormFullData && newCoreData.currentFormFullData[item1.field_field_match]) {
                if (newCoreData[item1.display_linked_data] && item.dataKeys) {
                  updatedFields[key]['selectData'] = []
                  for (const data of newCoreData[item1.display_linked_data]) {
                    if (
                      data[item1.redux_path_match_field] &&
                      newCoreData.currentFormFullData[item1.field_field_match][item1.display_linked_data].includes(data[item1.redux_path_match_field])
                    ) {
                      if (updatedFields[key].selectData) {
                        let selectData = updatedFields[key]['selectData']
                        updatedFields[key]['selectData'] = [...selectData, data]
                      } else {
                        updatedFields[key]['selectData'] = [data]
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return item
      })
      item.component_call_fields = updatedFields
    }

    const updateKeysForReduxData = (keys, key) => {
      let updatedFields = item.component_call_fields
      if (updatedFields[key]) {
        updatedFields[key].dataKeys = keys
      }
      item.component_call_fields = updatedFields
    }

    const fieldActions = (data, actions, field, value) => {
      // store form values in store
      const oldData = coreData.currentFormData ? coreData.currentFormData : {}
      const oldFullFormData = coreData.currentFormFullData ? coreData.currentFormFullData : {}
      let v = value
      if (value === true) {
        value = 1
      } else if (value === false) {
        value = 0
      } else {
        value = value
      }
      const updatedCoreData = {
        ...coreData,
        currentFormData: { ...oldData, [field]: value },
        currentFormFullData: { ...oldFullFormData, [field]: data },
      }
      dispatch(setCoreData(updatedCoreData))

      let updatedFields = item.component_call_fields
      let reversed_action = []
      if (actions?.length > 0) {
        reversed_action = [...actions]
      }
      reversed_action?.reverse()

      reversed_action?.map((action, index) => {
        switch (action.component_name) {
          case 'enable_or_disable':
            if (action?.if_value) {
              const newFields = []
              updatedFields?.forEach((item) => {
                if (item?.field_id !== field && item?.field_id !== action?.field_id) {
                  newFields?.push({ ...item })
                } else if (item?.field_id === action?.field_id) {
                  if (value?.includes(action?.if_value)) {
                    newFields?.push({ ...item })
                  }
                } else if (item?.field_id === field) {
                  newFields?.push({ ...item })
                  if (value?.includes(action?.if_value)) {
                    const checkIfFieldAlreadyExist = updatedFields?.find((item) => item?.field_id === action?.field_id)
                    const checkIfFieldAlreadyAdded = newFields?.find((item) => item?.field_id === action?.field_id)
                    if (!checkIfFieldAlreadyExist && !checkIfFieldAlreadyAdded) {
                      const newField = createFieldByFieldIdNtype(action?.field_id, action?.field, action)
                      newFields?.push(newField)
                    }
                  }
                }
              })
              updatedFields = [...newFields]
            } else {
              updatedFields = updatedFields.map((item) => {
                if (item.field_id === action.field_id) {
                  if (item.hidden || item.switchable) {
                    if (action.if_value === value) {
                      return { ...item, hidden: false, switchable: true }
                    } else {
                      return { ...item, hidden: true, switchable: true }
                    }
                  }
                  return { ...item, disabled: action.enable_field ? 0 : 1 }
                } else {
                  return item
                }
              })
            }

            break
          case 'refresh':
            updateValues(action.field_id, updatedCoreData)
            break
          case 'show_or_hide':
            updatedFields = updatedFields.map((item) => {
              if (item.field_id === action.field_id) {
                return { ...item, hidden: action.show_field ? 0 : 1 }
              } else {
                return item
              }
            })
            break
          default:
            break
        }
      })
      item.component_call_fields = updatedFields
    }

    //add additional field based on selected value and component_call_action

    let fields = []
    // item.component_call_fields?.map((item, key) => {
    //   // if (item.field_setting && item.field_setting?.title) {
    //   if (false) {
    //     const title = item.field_setting.title.replace(' ', '_').toLowerCase()
    //     if (fields[title]) {
    //       fields[title].push(item)
    //     } else {
    //       fields[title] = [item]
    //     }
    //   } else {
    //     if (fields[0]) {
    //       fields[0].push(item)
    //     } else {
    //       fields[0] = [item]
    //     }
    //   }

    // }
    // )
    return (
      <div key={key}>
        {!noTitle && <h4 className='card-header mt-4 mb-3'>{cms(item.cms_header_key)}</h4>}
        <div>
          <>
            <Row>
              {item?.component_call_fields
                ?.filter((field) => !field?.block_index && !field?.row_index)
                ?.map((field, key) => {
                  if (field.component_name === 'icon_button' || field.hidden === 1) return null
                  if (field?.component_name === 'hidden_data') return
                  return (
                    <Col md={field?.col || 6} key={key + (field?.field_id || field?.name)}>
                      <div className='mb-2'>
                        <CMSField
                          id={'fieldToolTip' + key + field.field_id}
                          toggleActions={toggleActions}
                          fieldKey={key}
                          data={field}
                          updateValues={() => updateValues()}
                          updateKeysForReduxData={(keys, key) => updateKeysForReduxData(keys, key)}
                          fieldAction={(data, action, field, value) => fieldActions(data, action, field, value)}
                          onChange={(id, value) => (item[id] = value)}
                          formData={item}
                          isModalAction={isModalAction}
                          isModel={isModel}
                          defaultFormValue={defaultFormValue}
                          onValueChange={onValueChange}
                          allFieldsInForm={item?.component_call_fields}
                          submittedFormData={submittedFormData}
                          parentPageName={parentPageName}
                          pageName={pageName}
                          source_table={source_table}
                          formFieldValue={formFieldValue}
                          setFormFieldValue={setFormFieldValue}
                          validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
                          setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
                          noCache={noCache}
                          disabled_field={disabled_field}
                        />
                        {field.cms_tooltip && field.cms_tooltip !== null && (
                          <UncontrolledTooltip placement='left' target={'fieldToolTip' + key + field.field_id}>
                            {cms(field.cms_tooltip)}
                          </UncontrolledTooltip>
                        )}
                      </div>
                    </Col>
                  )
                })}
            </Row>

            <Row>
              {layout?.map((row, rowIndex) => {
                return (
                  <Col md={12} key={rowIndex}>
                    <Row>
                      {row?.column?.map((col, blockIndex) => {
                        return (
                          <Col md={col?.col} key={rowIndex + blockIndex}>
                            <Row>
                              {item?.component_call_fields
                                ?.filter((field) => field?.is_layout === 1 && field?.block_index && field?.row_index)
                                ?.filter((field) => field?.row_index - 1 === rowIndex)
                                ?.filter((field) => field?.block_index - 1 === blockIndex)
                                ?.map((field, i) => {
                                  if (field?.hidden === true || field?.hidden === 1) return
                                  if (field?.component_name === 'hidden_data') return
                               
                                  return (
                                    <Col md={12} key={i}>
                                      <div className='mb-2'>
                                        <CMSField
                                          id={'fieldToolTip' + key + field.field_id}
                                          toggleActions={toggleActions}
                                          fieldKey={key}
                                          data={field}
                                          updateValues={() => updateValues()}
                                          updateKeysForReduxData={(keys, key) => updateKeysForReduxData(keys, key)}
                                          fieldAction={(data, action, field, value) => fieldActions(data, action, field, value)}
                                          onChange={(id, value) => (item[id] = value)}
                                          formData={item}
                                          isModalAction={isModalAction}
                                          isModel={isModel}
                                          defaultFormValue={defaultFormValue}
                                          onValueChange={onValueChange}
                                          allFieldsInForm={item?.component_call_fields}
                                          submittedFormData={submittedFormData}
                                          parentPageName={parentPageName}
                                          pageName={pageName}
                                          source_table={source_table}
                                          formFieldValue={formFieldValue}
                                          setFormFieldValue={setFormFieldValue}
                                          validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
                                          setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
                                          noCache={noCache}
                                          disabled_field={disabled_field}
                                        />
                                        {field.cms_tooltip && field.cms_tooltip !== null && (
                                          <UncontrolledTooltip placement='left' target={'fieldToolTip' + key + field.field_id}>
                                            {cms(field.cms_tooltip)}
                                          </UncontrolledTooltip>
                                        )}
                                      </div>
                                    </Col>
                                  )
                                })}
                            </Row>
                          </Col>
                        )
                      })}
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </>

          {tabFormData.main_source_table && !item?.template_name && (
            <>
              <AvField type='hidden' name='source_table' value={source_table} />
              <AvField type='hidden' name='api_template' value={true} />
              <AvField
                type='hidden'
                name='id'
                value={tabFormData.main_source_table === source_table ? tabFormData.data.id : tabFormData['data'][source_table]?.id}
              />
              <AvField type='hidden' name='parent_current_row_id' value={tabFormData.data.id} />
            </>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (content && content[0]?.layout) {
      setLayout(JSON.parse(content[0]?.layout))
    }
  }, [content[0]?.layout])

  return allForm.map((item, key) => renderSection(item, key))
}

export default connect(select)(RenderFields)
