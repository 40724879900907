import { useState } from 'react'
import { primary_color } from '../../Helper/uiHelper'
import RoundedInput from '../Form/Fields/roundedInput'
import ProfileBanner from './profileBanner'
import { request } from '../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../Redux/Reducers/notificationHandling'

const ProfileAddress = ({ handleAddressBackClick, defaultAddress, addresses, handleAddressSubmit, fetchAllAddress }) => {
  const dispatch = useDispatch()
  const [address, setAddress] = useState({})
  const [addAddress, setAddAddress] = useState(false)

  const onAddressChange = (e) => {
    let { name, value } = e.target
    setAddress((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const onDeleteAddress = (id) => {
    request('user/address/destroy', 'POST', { uuid: id }).then((res) => {
      if (res?.status == 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Address deleted successfully!' }))
        fetchAllAddress()
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
    })
  }

  const handleDefaultAddress = (id) => {
    request('user/address/set-default', 'POST', { uuid: id }).then((res) => {
      if (res?.status == 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Default address set successfully!' }))
        fetchAllAddress()
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
    })
  }

  const onSaveAddress = () => {
    if (!address?.city) {
      dispatch(setNotification({ type: 'error', message: 'City is required' }))
      return
    }
    if (!address?.country) {
      dispatch(setNotification({ type: 'error', message: 'country is required' }))
      return
    }
    if (!address?.post_code) {
      dispatch(setNotification({ type: 'error', message: 'post code is required' }))
      return
    }
    if (!address?.address1) {
      dispatch(setNotification({ type: 'error', message: 'Please add address' }))
      return
    }
    request('user/address/store', 'POST', address).then((res) => {
      setAddAddress(false)
      setAddress({})
      if (res?.status == 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Address added successfully!' }))
        fetchAllAddress()
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong!' }))
      }
    })
  }

  const onEditAddress = (addr) => {
    setAddAddress(true)
    setAddress(addr)
  }
  return (
    <>
      <ProfileBanner heading='<h2>Residential Details</h2>' />{' '}
      <div className='d-flex align-items-center justify-content-center p-5 mt-5' style={{ width: '100vw', overflowX: 'hidden' }}>
        <div
          className='container'
          style={{ width: '60vw', height: '100%', display: 'flex', flexDirection: 'column', gap: '50px', justifyContent: 'center' }}>
          <div className='d-flex align-items-center gap-5'>
            <i
              className='fa-solid fa-arrow-left h6 cursor-pointer font-weight-bold'
              onClick={handleAddressBackClick}
              style={{ color: primary_color }}></i>
            <div className='d-flex flex-column ml-4'>
              <div className='subtitle1' style={{ color: primary_color }}>
                Address
              </div>
              <p className='mb-0 body1'>{defaultAddress}</p>
            </div>
          </div>
          <div className='d-flex' style={{ flexDirection: 'column', gap: '20px' }}>
            {addresses?.map((item, id) => (
              <div key={id} className='d-flex justify-content-between'>
                <p className='body2 text-muted'>{item?.address1 + ', ' + item?.city + ', ' + item?.country + ', ' + item?.post_code}</p>
                <div style={{ gap: '10px' }} className='d-flex align-items-center'>
                  {!item?.default ? (
                    <button
                      onClick={() => handleDefaultAddress(item?.uuid)}
                      style={{ borderColor: primary_color, border: '2px solid', color: primary_color }}
                      className={`btn  ${item?.default && 'active'} px-4 rounded-pill subtitle1`}>
                      Set As Default
                    </button>
                  ) : (
                    <button
                      onClick={() => handleDefaultAddress(id)}
                      style={{ background: primary_color, border: '2px solid', color: '#fff' }}
                      className={`btn btn-primary ${item?.default && 'active'} px-4 rounded-pill subtitle1`}>
                      Default
                    </button>
                  )}

                  <i
                    onClick={() => onEditAddress(item)}
                    className='fa-solid fa-pencil cursor-pointer'
                    style={{ color: primary_color, fontWeight: 'bold', fontSize: '1.3rem' }}></i>
                  <span className='border border-danger ml-2' style={{ borderRadius: '50%', padding: '0.2rem 0.6rem' }}>
                    <i
                      className='fa-solid fa-xmark text-danger cursor-pointer'
                      onClick={() => {
                        onDeleteAddress(item?.uuid)
                      }}></i>
                  </span>
                </div>
              </div>
            ))}
          </div>
          {!addAddress && (
            <div>
              <button
                className='btn btn-primary rounded-pill'
                style={{ background: primary_color, paddingInline: '2rem' }}
                onClick={() => {
                  setAddress({})
                  setAddAddress(true)
                }}>
                + New Address
              </button>
            </div>
          )}

          {addAddress && (
            <>
              <form onSubmit={handleAddressSubmit} style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                  <div className='d-inline w-100'>
                    <input
                      onChange={onAddressChange}
                      value={address?.address1}
                      className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                      placeholder='Billing Address'
                      name='address1'
                      type='text'
                      required
                    />
                    {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                  </div>
                  <div className='d-inline w-100'>
                    <input
                      onChange={onAddressChange}
                      value={address?.city}
                      className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                      placeholder='City'
                      name='city'
                      type='text'
                      required
                    />
                    {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                  </div>
                  <div className='d-inline w-100'>
                    <input
                      onChange={onAddressChange}
                      value={address?.post_code}
                      className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                      placeholder='Postal Code'
                      name='post_code'
                      type='text'
                      required
                    />
                    {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                  </div>
                  <div className='d-inline w-100'>
                    <input
                      onChange={onAddressChange}
                      value={address?.country}
                      className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                      placeholder='Country'
                      name='country'
                      type='text'
                      required
                    />
                    {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                  </div>
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                  <button
                    className='btn btn-outline-danger rounded-pill py-4 '
                    style={{ paddingInline: '4rem' }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setAddAddress(false)
                    }}>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    style={{ background: primary_color, paddingInline: '4rem' }}
                    className='btn btn-primary rounded-pill py-4'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onSaveAddress()
                    }}>
                    {address?.id ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProfileAddress
