import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCookie } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { setPublicUser } from '../../../Redux/Reducers/user'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'
import Register from './Register'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Loader from 'react-loaders'
import { useHistory } from 'react-router-dom'
import './Login.scss'
import { clearNav, setNav } from '../../../Redux/Reducers/navigations'
import ForgotPassword from './ForgotPassword'

const inputIconStyle = {
  position: 'absolute',
  fontSize: '1rem',
  top: '50%',
  left: '15px',
  transform: 'translate(-0%, -50%)',
  color: 'grey',
}

const LineStyles = {
  text: {
    width: '80%',
    textAlign: 'center',
    borderBottom: '1px solid ' + primary_color,
    color: primary_color,
    lineHeight: '0.1em',
    margin: '30px auto',
  },
  line: {
    background: '#fff',
    padding: '0 30px',
  },
}

export const LoginForm = ({
  onLogin = () => {},
  hideForgotPassword = false,
  showValidation = false,
  setShowValidation = () => {},
  setIsForgotPassword,
}) => {
  const [formValues, setFormValues] = useState({ email: '', password: '' })
  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const _getLogin = (e) => {
    e.preventDefault()
    setIsLoading(true)
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer') || '')
    const parent_id = searchParams.get('parent_id')
    let current_storage_timer = parent_id ? storage_timer?.[parent_id] : ''
    const session_uuid = current_storage_timer ? current_storage_timer?.session_uuid : ''
    // setShowValidation('Success')
    request('login', 'POST', { ...formValues }).then((res) => {
      const { data } = res
      if (data.status === 1) {
        setCookie('AUTH_TOKEN', data?.token)
        onLogin()
        dispatch(setNotification({ type: 'success', message: data.message, status: data.status }))
        // dispatch(logTime(new Date()))
        // dispatch(setIsAuth(true))

        if (session_uuid && data?.token) {
          request('update-booking-user', 'POST', { uuid: session_uuid })
        }

        request('profile').then((res) => {
          if (res?.status === 200 || res?.status === 202) {
            dispatch(setPublicUser(res?.data?.data))
          }
          request('get-navigation').then((res) => {
            setIsLoading(false)
            if (res?.data) {
              // dispatch(setNav(res?.data))
            }
          })
          // dispatch(clearNav())
        })
        setIsLoading(false)
      } else {
        dispatch(setNotification({ type: 'error', message: data.message, status: data.status }))
        setIsLoading(false)
      }
    })
  }

  const _handleChange = (e) => {
    setShowValidation(false)
    setFormValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  return (
    <form onSubmit={_getLogin}>
      <div className=' mb-3'>
        <div className='position-relative'>
          <input
            className={`form-control subtitle1 rounded-input-icon rounded-pill border border-black pl-5 ${
              showValidation && !formValues.email ? 'error-input' : ''
            }`}
            type='text'
            name='email'
            onChange={_handleChange}
            placeholder='Email'
            required
            value={formValues.email}
          />
          <i style={inputIconStyle} className='fa-regular fa-envelope'></i>
        </div>
        {showValidation && !formValues.email && <div className='text-danger subtitle1 text-left pl-2'>Please enter email.</div>}
      </div>
      <div className='mb-2'>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill rounded-input-icon subtitle1 border border-black pl-5 ${
              showValidation && !formValues.password ? 'error-input' : ''
            }`}
            type='password'
            name='password'
            onChange={_handleChange}
            placeholder='Password'
            required
            value={formValues.password}
          />
          <i style={inputIconStyle} className='fa-solid fa-hashtag'></i>
        </div>
        {showValidation && !formValues.password && <div className='text-danger subtitle1 text-left pl-2'>Please enter password.</div>}
      </div>

      {/* <AvField type='email' name='email' label='email'></AvField> */}
      {/* <AvField type='password' name='password' label='password'></AvField> */}
      {!hideForgotPassword && (
        <div
          className='mb-4 subtitle1 cursor-pointer'
          style={{ textAlign: 'end', color: primary_color }}
          onClick={() => {
            setIsForgotPassword(true)
            // history.push('/forgot-password')
          }}>
          Forgot Password?
        </div>
      )}
      <button className='btn rounded-pill font-weight-bold mt-2  w-100 text-white subtitle1' style={{ background: primary_color }}>
        <span className='d-flex justify-content-center align-items-center ' style={{ height: '20px' }}>
          Login {isLoading && <Loader type='line-scale' color='#FFF' style={{ transform: 'scale(0.3)' }} />}
        </span>
      </button>
    </form>
  )
}

const PublicLogin = ({ form, data, isLastWidget }) => {
  const [formFieldValue, setFormFieldValue] = useState({})
  const publicUser = useSelector((state) => state.User.publicUser)
  const [isRegister, setIsRegister] = useState(false)
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  const content = [
    {
      layout: '[{"column":[{"col":"12"},{"col":"6"},{"col":"12"}]}]',
    },
  ]

  if (publicUser?.email) return
  return (
    <div className='mt-2' style={{ padding: '2.5rem 0', borderBottom: isLastWidget ? '' : '1px solid lightgrey' }}>
      <div className='text-center mb-2'>
        {!isForgotPassword && (
          <div className=' text-left font-weight-bold body1 mb-4' style={{ color: primary_color, fontWeight: '800' }}>
            {isRegister ? 'Sign up' : 'Login'} to save your booking details !
          </div>
        )}
        {isForgotPassword && (
          <div className=' text-left font-weight-bold body1 mb-4' style={{ color: primary_color, fontWeight: '800' }}>
            Forgot password?
          </div>
        )}
        {!isForgotPassword && <>{isRegister ? <Register title={''} /> : <LoginForm setIsForgotPassword={setIsForgotPassword} />}</>}
        {isForgotPassword && <ForgotPassword></ForgotPassword>}
      </div>
      <div style={LineStyles.text}>
        <b style={LineStyles.line}>or</b>
      </div>
      {isForgotPassword ? (
        <button
          className='btn rounded-pill font-weight-bold subtitle1 w-100'
          onClick={() => {
            setIsForgotPassword(false)
          }}
          style={{ background: 'white', border: `1px solid ${primary_color}`, color: primary_color }}>
          Sign In
        </button>
      ) : (
        <button
          className='btn rounded-pill font-weight-bold subtitle1 w-100'
          onClick={() => {
            setIsRegister((prev) => !prev)
          }}
          style={{ background: 'white', border: `1px solid ${primary_color}`, color: primary_color }}>
          {isRegister ? 'Login' : 'Register'}
        </button>
      )}
    </div>
  )
}

export default PublicLogin
