import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Switch from 'react-switch'
import { primary_color } from '../../../../Helper/uiHelper'
import useUpdateEmailTemplateWidget from '../../../../Queries/EmailTemplate/useUpdateEmailTemplateWidget'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { useDispatch } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'

const FieldText = ({ data: widget_data, closeModal }) => {
  const [data, setData] = useState([])
  const updateWidget = useUpdateEmailTemplateWidget()
  const dispatch = useDispatch()

  useEffect(() => {
    if (widget_data && widget_data?.common_setting) {
      const fields = JSON.parse(widget_data?.common_setting)?.[0]
      setData(fields)
    }
  }, [JSON.stringify(widget_data)])

  const onSaveWidget = () => {
    let payload = {
      ...widget_data,
      common_setting: data,
      type: 'widgets',
    }
    updateWidget.mutate(payload, {
      onSuccess: (data) => {
        //add failed scenario also
        dispatch(setNotification({ type: 'success', message: 'Email Sent Successfully!' }))
        closeModal()
      },
      onError: (error) => {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        closeModal()
      },
    })
  }

  return (
    <>
      <AvForm>
        <label>Refund note</label>
        <AvField
          name='text-data'
          value={data?.Value}
          onChange={(e) => {
            setData({
              ...data,
              Value: e.target.value,
            })
          }}></AvField>
      </AvForm>
      <div className='d-flex justify-content-between' style={{ width: '60%' }}>
        <button
          className='btn text-white rounded-pill mt-2'
          type='button'
          onClick={() => {
            onSaveWidget()
          }}
          style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
          Yes
        </button>
        <button
          className='btn rounded-pill mt-2 text-danger border-danger'
          type='button'
          onClick={() => {
            closeModal()
          }}
          style={{ fontWeight: 700, width: '48%' }}>
          Cancel
        </button>
      </div>
    </>
  )
}

export default FieldText
