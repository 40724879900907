import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { primary_color } from "../../../Helper/uiHelper"

const CodeGeneratedWarning = ({ setIsCodeGenerated }) => {
  return (
    <Modal
      isOpen={true}
      centered={true}
      toggle={() => {
        // setIsCodeGenerated()
      }}
      style={{ width: '25rem', boxShadow: 'none', maxWidth: '50rem' }}>
      <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
        <div className='body1 font-weight-bold' style={{ color: primary_color }}>
          Currently, you can't make any changes to your booking.
        </div>
        <div className='text-danger subtitle1 mt-2'>If you need any support we discussed this booking, please raise a ticket.</div>
      </ModalHeader>
      <ModalBody></ModalBody>
    </Modal>
  )
}

export default CodeGeneratedWarning