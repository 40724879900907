import { cms } from '../../../helper'
import { AvField } from 'availity-reactstrap-validation'

import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import { FileUploader } from 'react-drag-drop-files'

const fileTypes = ['JPG', 'JPEG', 'PNG']

const FileUpload = ({ data, form, onChange, defaultValue, formFieldValue, noLabel, setFormFieldValue }) => {
  const [file, setFile] = useState(null)
  const [base64File, setBase64File] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [crop, setCrop] = useState({ aspect: 1 })
  const [croppedImage, setCroppedImage] = useState(null)
  const imageRef = useRef()

  const convertUrlToBase64 = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json',
        //     // Additional headers if needed
        // },
        body: {
          path: url,
        },
        // mode: 'cors', // Ensure CORS mode is enabled
      })
      const blob = await response.blob()
      const reader = new FileReader()
      reader.onloadend = () => {
        setBase64File(reader.result)
        setCroppedImage(reader.result)
      }
      reader.readAsDataURL(blob)
    } catch (error) {
      console.error('Error converting URL to Base64:', error)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue?.includes('base64')) {
        setBase64File(defaultValue)
        setCroppedImage(defaultValue)
      } else {
        convertUrlToBase64(process.env.REACT_APP_STORAGE + defaultValue)
      }
    }
  }, [defaultValue])
  
  const handleChange = (uploadedFile) => {
    const reader = new FileReader()
    reader.onload = () => {
      setBase64File(reader.result)
      setCroppedImage(reader.result)
      setFile(uploadedFile)
    }
    reader.readAsDataURL(uploadedFile)
  }

  const onCropChange = (newCrop) => setCrop(newCrop)

  const onImageLoaded = (image) => {
    imageRef.current = image
  }

  const onCropComplete = useCallback(() => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement('canvas')
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height

      canvas.width = crop.width
      canvas.height = crop.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(imageRef.current, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

      const croppedBase64 = canvas.toDataURL('image/jpeg')
      setCroppedImage(croppedBase64)
    }
  }, [crop])

  const handleCropClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsModalOpen(true)
  }

  const handleRemoveClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setFile(null)
    setBase64File(null)
    setCroppedImage(null)
  }

  const handleRotateClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const image = new Image()
    image.src = croppedImage || base64File
    image.onload = () => {
      canvas.width = image.height
      canvas.height = image.width
      ctx.translate(image.height / 2, image.width / 2)
      ctx.rotate((90 * Math.PI) / 180)
      ctx.drawImage(image, -image.width / 2, -image.height / 2)
      const rotatedBase64 = canvas.toDataURL('image/jpeg')
      setCroppedImage(rotatedBase64)
    }
  }

  return (
    <div>
      {!noLabel && <label className='subtitle1'>{cms(data.cms_key)}</label>}
      {!croppedImage && (
        <FileUploader
          handleChange={handleChange}
          name='file'
          types={fileTypes}
          multiple={form?.component_name === 'advanced_grid' || form?.component_name === 'static_form' ? false : true}
          fileOrFiles={file}
          classes='file-upload-field'
        />
      )}

      {croppedImage && (
        <div className='file-preview-container mb-5' style={{ marginTop: '20px' }}>
          <div
            style={{
              width: '150px',
              height: '150px',
              border: '1px solid #ddd',
              position: 'relative',
              borderRadius: '10px',
            }}>
            <img
              src={croppedImage || base64File}
              alt='Preview'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />

            <div
              className='action-buttons'
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                top: -12,
                right: -20,
                width: '100%',
              }}>
              <button onClick={handleRemoveClick} className='btn btn-danger btn-sm mx-1'>
                <i class='fa-solid fa-trash'></i>
              </button>
              <button onClick={handleCropClick} className='btn btn-warning btn-sm mx-1'>
                <i class='fa-light fa-crop-simple'></i>
              </button>
              <button onClick={handleRotateClick} className='btn btn-primary btn-sm mx-1'>
                <i class='fa-solid fa-rotate'></i>
              </button>
            </div>
          </div>
        </div>
      )}
      <AvField type='hidden' name={data?.field_id || data?.name || 'no_name'} value={croppedImage}></AvField>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>Crop Image</ModalHeader>
        <ModalBody>
          <ReactCrop src={base64File} crop={crop} onChange={onCropChange} onImageLoaded={onImageLoaded} onComplete={onCropComplete} />
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              setIsModalOpen(false)
            }}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default FileUpload
