import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip } from 'reactstrap'
import { Col, Row, Button } from 'reactstrap'
import DatePicker from 'react-datepicker'

import CMSRichText from '../../Forms/Fields/richText'
import CMSSelect from '../../Forms/Fields/select'
import CMSDate from '../../Forms/Fields/date'
import {
  callInsertRepeatingGridData,
  can,
  checkPermission,
  cms,
  createFieldByFieldIdNtype,
  editCMS,
  getButtonActionByIcon,
  getConnectTemplateReduxDataAfterDeleteClientRow,
  getFromRedux,
  insertChildTableAddedDataInSync,
  isEqual,
  removeInsertDataQueueByReactId,
} from '../../../helper'
import IconSet from '../../icon'
import CMSButton from '../../Forms/Fields/button'
import { request } from '../../../Redux/Sagas/requests/api'
import {
  addDataStatusClear,
  addDataStatusSet,
  clearCurrentRowData,
  clearParentTableData,
  setConnectTemplateDataRedux,
  setCurrentRow,
  setParentTableData,
  submitRequest,
} from '../../../Redux/Reducers/CMS'

import DateTimeRangePicker from '../../Forms/Fields/dateTimeRangePicker'
import DateTimePicker from '../../Forms/Fields/dateTimePicker'

import CMSTimer from '../../Forms/Fields/timepicker.js'

import SubmitButton from '../../Forms/Fields/submitButton.js'
import switchButton from '../../Forms/Fields/switchButton.js'
import CMSSwitch from '../../Forms/Fields/toggle.js'
import CMSMultiCheckbox from '../../Forms/Fields/multiCheckbox.js'
import FilerUpload from '../../Forms/Fields/fileUpload.js'
import SectionHeading from '../../Forms/Fields/sectionHeading.js'
import Spacer from '../../Forms/Fields/spacer.js'
import CMSRadio from '../../Forms/Fields/multiRadio'
import CMSTabs from '../../tabs'
import PreTime from '../../Forms/Fields/preTime'
import { setGrid } from '../../../Redux/Reducers/queue'
import PostTime from '../../Forms/Fields/postTime'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import InputReadOnly from './inputReadOnly'

const dummy_tabs = [
  {
    component_type: 'buttons',
    component_name: 'show_tabs_on_load',
    name: 'items_item_questions',
    source_table: 'item_questions',
    is_grid: 0,
    default_tab_name: 'Questions',
    slug: false,
    permission: 'edit_items_item_questions',
    allowed: true,
    cms_tab_key: 'nav.questions',
    tab_color: '',
    icon_type: null,
    icon: null,
    component_call_pill_data_connection: null,
    tab_pill_color_theme: '',
    component_call_form: [
      {
        component_type: 'form',
        component_name: 'static_form',
        name: 'items_item_questions',
        layout: null,
        permission: 'edit_items_item_questions',
        allowed: true,
        cols: 1,
        template_name: false,
        icon_type: false,
        icon: false,
        cms_header_key: 'heading.questions',
        cms_sub_header_key: 'sub.items_item_questions_info',
        component_call_fields: null,
        component_call_data_connection: [
          {
            component_type: 'data_conection',
            action: 'connect_edit_template',
            api: '',
          },
        ],
      },
    ],
    component_call_actions: [
      {
        component_type: 'actions',
        component_name: 'open_page',
        page_name: 'outstanding_jobs',
        url: 'outstandingjobs-edit',
        post_data: null,
      },
    ],
    id: 1,
  },
]

const RenderFields = ({ data, item, index, sourceTable, repeatingRowIndex, fieldActions = () => {} }) => {
  const itemdataForDropDown = {
    ...data,
    ...data?.field_setting,
  }

  const dispatch = useDispatch()

  // const onValueChange = (new_value) =>{
  //       dispatch(
  //         setGrid({
  //           source_table: sourceTable,
  //           data: {
  //             id: item?.id,
  //             value: new_value,
  //             name: data.field_id,
  //           },
  //           //child_table:
  //           //parent_table_id:
  //           //source_table:#545cd8
  //         })
  //       )
  // }
  let fieldType
  if (data?.field_setting?.type === 'pre_time') {
    fieldType = 'pre_time'
  } else if (data?.field_setting?.type === 'post_time') {
    fieldType = 'post_time'
  } else {
    fieldType = data?.field_setting?.field || data?.field
  }
  switch (fieldType) {
    case 'input':
      return <AvField name={data.field_id} label={cms(data.field_id)} value={item[data.field_id]} />
    case 'rich_text':
      return <CMSRichText id={data.field_id + index} data={data} value={item[data.field_id]} />
    // case 'datetime':
    case 'pre_time':
      if (data?.field_setting?.type || data?.field_setting?.time_field_id) {
        return (
          <PreTime
            data={data}
            time_field_id={data?.field_setting?.time_field_id}
            item={item}
            onChange={(value) => {}}
            defaultValue={item ? item[data?.field_id] : ''}
          />
        )
      }

    // case 'datetime':
    case 'post_time':
      if (data?.field_setting?.time_field_id) {
        return (
          <PostTime
            data={data}
            time_field_id={data?.field_setting?.time_field_id}
            item={item}
            onChange={(value) => {}}
            defaultValue={item ? item[data?.field_id] : ''}
          />
        )
      }
    case 'date':
      return <CMSDate data={data} id={data?.field_id} defaultValue={item[data.field_id] ? item[data.field_id] : null} />
    case 'select':
    case 'dropdown':
      return (
        <CMSSelect
          data={itemdataForDropDown}
          id={data?.field_id}
          value={item[data.field_id]}
          repeatingFormItem={item}
          index={repeatingRowIndex}
          inputLabel={item[`${data.field_id}_label`]}
          isTable
          isRepeatingForm={true}
          onChangeAction={fieldActions}
        />
      )
    case 'date':
      return <CMSDate data={data} id={data?.field_id} defaultValue={item[data.field_id] ? item[data.field_id] : null} />
    case 'time':
      return <CMSTimer data={data} id={data?.field_id} value={item[data.field_id] ? item[data.field_id] : null} />
    case 'switch':
      return <CMSSwitch data={data} id={data?.field_id} value={item[data.field_id] ? item[data.field_id] : null} />
    case 'toggle_switch':
      return <CMSSwitch data={data} id={data?.field_id} value={item[data.field_id] ? item[data.field_id] : null} />
    case 'multi_checkbox':
      return <CMSMultiCheckbox data={itemdataForDropDown} id={data?.field_id} value={item[data.field_id]} />
    case 'multi_radio':
      return <CMSRadio data={itemdataForDropDown} id={data?.field_id} value={item[data.field_id]} />
    case 'multi_select':
      return <CMSSwitch data={itemdataForDropDown} id={data?.field_id} value={item[data.field_id]} />
    // return <CMSMultiCheckbox data={data} id={id} value={dataConnectionValue} />
    case 'static_text':
      return <p id={data?.field_id}>{cms(data.cms_key)}</p>

    // case 'submit_api_button':
    //   return <SubmitButton data={data} onSubmit={onSubmit} id={id} />
    // case 'color_picker':
    //   return <ColorPicker data={data} onChange={onChange} defaultValue={isCurrentRow ? isCurrentRowValue : formData.cms_value} />
    case 'date_time_picker':
      return (
        <DateTimePicker
          data={data}
          id={data?.field_id}
          defaultValue={item[data.field_id] ? item[data.field_id] : null}
          onChange={() => {}}
          setFormFieldValue={() => {}}
        />
      )
    case 'datetime':
      return (
        <DateTimePicker
          data={data}
          id={data?.field_id}
          defaultValue={item[data.field_id] ? item[data.field_id] : null}
          onChange={() => {}}
          setFormFieldValue={()=>{}}
        />
      )
    case 'date_time_range_picker':
      return (
        <DateTimeRangePicker data={data} id={data?.field_id} defaultValue={item[data.field_id] ? item[data.field_id] : null} onChange={() => {}} />
      )
    case 'file_upload':
      return <FilerUpload data={data} defaultValue={item[data.field_id]} />
    case 'section_heading':
      return <SectionHeading data={data} />
    case 'spacer':
      return <Spacer data={data} />
    case 'input_read_only':
      return <InputReadOnly data={data} value={item[data.field_id]} />

    default:
      return <AvField name={data.field_id} label={cms(data.field_id)} value={item[data.field_id]} />
  }
}

const RenderButtons = ({ data, item, onDelete, updategridSelectedRow, gridSelectedRow, onEdit, tableGrid, isTab, pageName, parentPageName }) => {
  const dispatch = useDispatch()

  const onCheck = (e) => {
    if (gridSelectedRow.includes(item)) {
      const index = gridSelectedRow.indexOf(item)
      const selectedRows = [...gridSelectedRow]
      selectedRows.splice(index, 1)
      updategridSelectedRow(selectedRows)
    } else {
      updategridSelectedRow([...gridSelectedRow, { original: item }])
    }
  }

  const onClickButton = () => {
    if (data?.icon === 'pe-7s-trash') {
      if (item?.id) {
        onDelete(item?.id)
      } else if (!item?.id && item?.temporary_id) {
        onDelete(item?.temporary_id, true)
      }
    } else {
      onEdit()
      dispatch(setCurrentRow(item))
      dispatch(
        setParentTableData({
          source_table: tableGrid?.source_table,
          parent_page_name: tableGrid?.name,
          child_table: tableGrid?.child_tabs?.component_call_buttons[0]?.source_table,
        })
      )
    }
  }
  if (data.component_name === 'check_col') {
    return <input type='checkbox' onChange={onCheck}></input>
  }
  if (data.component_name === 'icon_col_button') {
    if (getButtonActionByIcon(data?.icon) && !checkPermission(getButtonActionByIcon(data?.icon), pageName, parentPageName, isTab)) {
      return
    }
    return (
      <div onClick={() => onClickButton()}>
        <CMSButton item={data} singleRow={true} noConfirmation={true} />
      </div>
    )
  }
}

const RepeatingForm = ({
  mainGrid,
  sourceTable,
  selectedGrid,
  page_data,
  setting,
  meta,
  changeRowsPerPage,
  getByUrl,
  initialPayload,
  updategridSelectedRow,
  gridSelectedRow,
  data,
  source_table,
  childTabs,
  pageName,
  parentPageName,
  tableGrid,
  isTab,
}) => {
  const dispatch = useDispatch()

  const lastDataConnectionData = useSelector((state) => state.CMS.lastDataConnectionData)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const queues = useSelector((state) => state.Queue.queues)
  const current_row_id = useSelector((state) => state.CMS?.currentRow[state.CMS.currentRow?.length - 1]?.id)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const [form, updateForm] = useState(data)
  const [grid, setGrid] = useState()
  const [render, ReRender] = useState(false)

  const [rowsPerPage, updateRowsPerPage] = useState(initialPayload ? initialPayload.default_rec_per_page : 10)

  const columns = grid?.component_call_cols?.filter((item) => item.component_name === 'text_data_col')

  useEffect(() => {
    ReRender(!render)
  }, [grid?.component_call_cols?.length])

  const parent_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')

  const layout_string = grid?.column_layout
  let layout
  if (layout_string && layout_string?.length > 0) {
    layout = JSON.parse(layout_string)
  }

  const button_list = grid?.component_call_cols?.filter((item) => item.component_name !== 'text_data_col')
  const coreData = useSelector((state) => state.CMS.coreData)
  useEffect(() => {
    if (data) {
      updateForm(data)
    }
  }, [data])

  useEffect(() => {
    const a = page_data?.component_call_buttons.filter(
      (item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid
    )
    if (selectedGrid === 'restore_grid') {
      setGrid(a?.[0]?.component_call_form[0]?.restore_grid)
    } else {
      setGrid(mainGrid)
    }
  }, [JSON.stringify(mainGrid), selectedGrid])
  const pagination = grid?.component_call_pagination

  const onDelete = (id, isTemporaryId) => {
    const currentTabData = page_data?.component_call_buttons.filter(
      (item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid
    )

    let data
    if (current_row_id) {
      data = {
        source_table: source_table,
        ids: [id],
        parent_table_row_id: current_row_id,
        parent_table: parent_table[0]?.source_table,
      }
    } else {
      data = {
        source_table: source_table,
        ids: [id],
      }
    }
    if (!isTemporaryId) {
      dispatch(submitRequest({ url: 'connect-template-delete', data }))
    } else {
      const parentTable = parentTableDataInRedux?.[0]?.source_table
      removeInsertDataQueueByReactId(id, tableGrid.source_table, parentTable, currentRow)
      const dataToUpdate = getConnectTemplateReduxDataAfterDeleteClientRow(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        tableGrid?.source_table,
        currentRow,
        currentRow?.length > 0,
        [id]
      )

      dispatch(setConnectTemplateDataRedux(dataToUpdate))
    }

    setTimeout(() => {
      dispatch(addDataStatusSet())
    }, 500)
  }

  const onUpdate = (values, temporary_id) => {
    const currentTabData = page_data?.component_call_buttons.filter(
      (item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid
    )
    if (temporary_id) {
      let submittedFormData = {}
      submittedFormData[currentTabData[0].source_table] = { ...values, temporary_id }
      if (parent_table && !currentRow?.[currentRow?.length - 1]?.id && currentRow?.[currentRow?.length - 1]?.temporary_id && queues?.length > 0) {
        insertChildTableAddedDataInSync(
          currentRow?.[currentRow?.length - 1]?.temporary_id,
          parent_table,
          source_table,
          submittedFormData[currentTabData[0].source_table]
        )
      } else {
        callInsertRepeatingGridData(submittedFormData, source_table, null, false, domain_id, parent_table, current_row_id)
      }
    } else {
      const data = {
        source_table: currentTabData[0].source_table,
        values: { ...values, domain_id: domain_id },
        api_template: true,
        id: current_row_id || currentRow[currentRow?.length - 1]?.temporary_id,
      }

      request('save-default-form-data', 'POST', data).then((res) => {
        if (res.status === 200 || res.status === 202) {
          dispatch(setNotification({ type: 'success', message: 'Form updated successfully!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
    }
  }

  const layoutFields = columns?.filter((field) => field?.field_setting.block_index && field?.field_setting.row_index)
  const commonFields = columns?.filter((field) => !field?.field_setting.block_index && !field?.field_setting.row_index)

  return (
    <>
      {form?.length === 0 && <div style={{ margin: '2rem', display: 'flex', justifyContent: 'center' }}>No Data Found</div>}
      {form?.map((item, index) => {
        return (
          <RenderSingleForm
            key={index}
            onUpdate={onUpdate}
            button_list={button_list}
            item={item}
            onDelete={onDelete}
            updategridSelectedRow={updategridSelectedRow}
            gridSelectedRow={gridSelectedRow}
            commonFields={commonFields}
            layout={layout}
            layoutFields={layoutFields}
            source_table={source_table}
            childTabs={childTabs}
            pageName={pageName}
            parentPageName={parentPageName}
            isTab={isTab}
            repeatingRowIndex={index}
            tableGrid={tableGrid}></RenderSingleForm>
        )
      })}

      {pagination && (
        <div className='ReactTable no-border'>
          <div className='pagination-bottom'>
            <div className='-pagination no-border'>
              <div className='-previous'></div>
              {pagination.page_control && (
                <div className='-center'>
                  <span onClick={() => editCMS(setting?.page_before_cms_value)}>{cms(setting?.page_before_cms_value)}</span>
                  <Pagination className='mt-3 mr-2 ml-2'>
                    {meta?.links?.map((item, key) => {
                      return (
                        <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                          <PaginationLink first onClick={() => getByUrl(item.url)}>
                            {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    })}
                  </Pagination>
                  {getFromRedux(pagination.redux_name) && (
                    <>
                      <span onClick={() => editCMS(setting?.page_after_cms_value)}>{cms(setting?.page_after_cms_value)}</span>
                      <span className='m-2'>{meta?.last_page}</span>
                      <span onClick={() => editCMS(setting?.page_end_cms_value)}>{cms(setting?.page_end_cms_value)}</span>
                      <span className='select-wrap -pageSizeOptions'>
                        <select
                          aria-label='rows per page'
                          selected={rowsPerPage}
                          onChange={(e) => {
                            changeRowsPerPage(e.target.value)
                            updateRowsPerPage(e.target.value)
                          }}>
                          {getFromRedux(pagination.redux_name).map((item, key) => {
                            return (
                              <option key={key} value={item.value}>
                                {item.value} Rows
                              </option>
                            )
                          })}
                        </select>
                      </span>
                    </>
                  )}
                </div>
              )}
              <div className='-next'></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const RenderSingleForm = ({
  onUpdate,
  button_list,
  item,
  onDelete,
  updategridSelectedRow,
  gridSelectedRow,
  commonFields,
  layout,
  layoutFields,
  source_table,
  childTabs,
  pageName,
  repeatingRowIndex,
  tableGrid,
  parentPageName,
  isTab,
}) => {
  const [editOpen, setEditOpen] = useState(false)
  const [commonColumns, setCommonColumns] = useState([])

  useEffect(() => {
    setCommonColumns(commonFields)
  }, [commonFields])

  const dispatch = useDispatch()

  const onEdit = () => {
    setEditOpen(!editOpen)
  }

  const fieldActions = (data, actions, field, value) => {
    // store form values in store

    let updatedFields = commonColumns ? [...commonColumns] : []
    let reversed_action = []
    if (actions?.length > 0) {
      reversed_action = [...actions]
    }
    reversed_action?.reverse()

    reversed_action?.map((action, index) => {
      switch (action.component_name) {
        case 'enable_or_disable':
          if (action?.if_value) {
            const newFields = []
            updatedFields?.forEach((item) => {
              if (item?.field_id !== field && item?.field_id !== action?.field_id) {
                newFields?.push({ ...item })
              } else if (item?.field_id === action?.field_id) {
                if (value?.includes(action?.if_value)) {
                  newFields?.push({ ...item })
                }
              } else if (item?.field_id === field) {
                newFields?.push({ ...item })
                if (value?.includes(action?.if_value)) {
                  const checkIfFieldAlreadyExist = updatedFields?.find((item) => item?.field_id === action?.field_id)
                  const checkIfFieldAlreadyAdded = newFields?.find((item) => item?.field_id === action?.field_id)
                  if (!checkIfFieldAlreadyExist && !checkIfFieldAlreadyAdded) {
                    const newField = createFieldByFieldIdNtype(action?.field_id, action?.field, action)
                    newFields?.push(newField)
                  }
                }
              }
            })
            updatedFields = [...newFields]
          } else {
            updatedFields = updatedFields.map((item) => {
              if (item.field_id === action.field_id) {
                if (item.hidden || item.switchable) {
                  if (action.if_value === value) {
                    return { ...item, hidden: false, switchable: true }
                  } else {
                    return { ...item, hidden: true, switchable: true }
                  }
                }
                return { ...item, disabled: action.enable_field ? 0 : 1 }
              } else {
                return item
              }
            })
          }

          break
        case 'refresh':
          // updateValues(action.field_id, updatedCoreData)
          break
        case 'show_or_hide':
          updatedFields = updatedFields.map((item) => {
            if (item.field_id === action.field_id) {
              return { ...item, hidden: action.show_field ? 0 : 1 }
            } else {
              return item
            }
          })
          break
        default:
          break
      }
    })
    setCommonColumns(updatedFields)
  }

  return (
    <>
      <AvForm
        onValidSubmit={(event, values) => {
          if (!item?.id && item?.temporary_id) {
            const { id, ...rest } = values
            onUpdate(rest, item?.temporary_id)
          } else {
            onUpdate(values)
          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {button_list?.map((data, i) => (
            <RenderButtons
              key={i}
              data={data}
              item={item}
              onDelete={onDelete}
              updategridSelectedRow={updategridSelectedRow}
              gridSelectedRow={gridSelectedRow}
              onEdit={onEdit}
              tableGrid={tableGrid}
              pageName={pageName}
              parentPageName={parentPageName}
              isTab={isTab}
            />
          ))}
        </div>

        <AvField name='id' value={item?.id} type='hidden' />
        <Row>
          {commonColumns?.map((data, index) => {
            if (data?.field_setting?.field === 'hidden') return
            return (
              <Col md={data?.field_setting?.col ? data?.field_setting?.col : 12} key={index}>
                <RenderFields
                  data={data}
                  item={item}
                  index={index}
                  sourceTable={source_table}
                  repeatingRowIndex={repeatingRowIndex}
                  fieldActions={fieldActions}
                />
              </Col>
            )
          })}
        </Row>

        {layout?.map((row, rowIndex) => {
          return (
            <Row key={rowIndex}>
              {row?.column?.map((col, columnIndex) => {
                return (
                  <Col md={col?.col} key={rowIndex + columnIndex}>
                    {layoutFields
                      ?.filter((field) => field?.field_setting?.row_index === rowIndex + 1 && field?.field_setting?.block_index === columnIndex + 1)
                      ?.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <RenderFields
                              data={data}
                              index={rowIndex + columnIndex + index}
                              item={item}
                              sourceTable={source_table}
                              repeatingRowIndex={repeatingRowIndex}
                            />
                          </React.Fragment>
                        )
                      })}
                  </Col>
                )
              })}
            </Row>
          )
        })}
        {checkPermission('edit', pageName, parentPageName, isTab) && <Button style={{ marginTop: '1rem' }}>Save</Button>}
      </AvForm>
      <Modal
        isOpen={editOpen}
        toggle={() => {
          setEditOpen(!editOpen)
          dispatch(clearCurrentRowData())
          dispatch(clearParentTableData())
        }}
        style={{ width: 2500 }}
        scrollable={true}>
        <ModalHeader
          toggle={() => {
            setEditOpen(!editOpen)
            dispatch(clearCurrentRowData())
            dispatch(clearParentTableData())
          }}>
          Edit Tab
        </ModalHeader>
        <ModalBody>
          <div style={{ flex: 1, margin: '1rem' }}>
            <CMSTabs content={childTabs?.component_call_buttons} fullPage={true} pageName={parentPageName}></CMSTabs>
          </div>
        </ModalBody>
      </Modal>
      <hr></hr>
    </>
  )
}

export default RepeatingForm
