import { AvForm } from 'availity-reactstrap-validation'
import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { useEffect, useRef, useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { isSuccessResponse } from '../../../helper'
import Loader from 'react-loaders'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'
import { Button } from 'reactstrap'

const BlockMainDetails = ({ setBlocketails, blockDetails, form, onCloseForm, isPlannedBlock, isTemplateImported, setIsTemplateImported }) => {
  const dispatch = useDispatch()
  const saveNclose = useRef()
  const rowData = useSelector((state) => state.CMS.currentRow)
  const [formFieldValue, setFormFieldValue] = useState()
  const [importFormFieldValue, setImportFormFieldValue] = useState()
  const [loading, setLoading] = useState(false)
  const [mainForm, setMainForm] = useState({})
  const [importForm, setImportForm] = useState({})
  const [isMainFormDisabled, setIsMainFormDisabled] = useState(false)
  const [isImportingLoading, setIsImportingLoading] = useState(false)
  const [isImportError, setIsImportError] = useState(false)
  const [showImportForm, setShowImportForm] = useState(false)

  useEffect(() => {
    if (isPlannedBlock || rowData?.length === 2 || isTemplateImported) {
      setMainForm(form)
    } else {
      setIsMainFormDisabled(true)
      setShowImportForm(true)
      setMainForm(form)
      const importForm = form && JSON.parse(JSON.stringify(form))
      importForm['component_call_fields'] = importForm?.component_call_fields?.filter(
        (item) => item?.field_id === 'item_date_time_id' || item?.field_id === 'template_id'
      )
      setImportForm(importForm)
    }
  }, [rowData?.length])

  const onSubmitBlockDetails = (values) => {
    const domain_id = rowData?.[0]?.id
    request('location-manage', 'POST', {
      locations: [{ ...values, domain_id, id: blockDetails?.id }],
      is_planned_block: isPlannedBlock,
      copy_from_template: blockDetails?.id ? false : true,
    }).then((res) => {
      if (res.status === 200 || res.status === 202 || res.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Form submitted successfully!' }))
        request('get-location-data', 'POST', { location_id: res?.data?.id, is_planned_block: isPlannedBlock }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
          if (saveNclose.current) {
            onCloseForm()
          }
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    setIsImportError(false)
    if (!importFormFieldValue?.template_id || !importFormFieldValue?.item_date_time_id) {
      return
    }
    const domain_id = rowData?.[0]?.id
    setIsImportingLoading(true)
    request('get-existing-locations-data', 'POST', {
      template_id: importFormFieldValue?.template_id,
      item_date_time_id: importFormFieldValue?.item_date_time_id,
      domain_id,
    }).then((res) => {
      if (isSuccessResponse(res)) {
        if (res?.data?.length === 0) {
          request('get-location-data', 'POST', { location_id: importFormFieldValue?.template_id, is_planned_block: true }).then((res) => {
            if (isSuccessResponse(res)) {
              const { id, ...rest } = res?.data
              setBlocketails({ ...rest, ...importFormFieldValue })
              setImportForm({})
              setIsImportingLoading(false)
              setIsMainFormDisabled(false)
              setShowImportForm(false)
              setIsTemplateImported(true)
            }
          })
          // request('save-location-data', 'POST', { ...importFormFieldValue, domain_id: rowData?.[0] }).then((res) => {
          //   if (isSuccessResponse(res)) {
          //     request('get-location-data', 'POST', { location_id: res?.data?.id, is_planned_block: false }).then((res) => {
          //       if (isSuccessResponse(res)) {
          //         setBlocketails(res?.data)
          //         setImportForm({})
          //         setIsImportingLoading(false)
          //         setIsMainFormDisabled(false)
          //         setShowImportForm(false)
          //         setIsTemplateImported(true)
          //       }
          //     })
          //   }
          // })
        } else {
          setIsImportError(true)
        }
        setIsImportingLoading(false)
      } else {
        setIsImportingLoading(false)
      }
    })
  }, [importFormFieldValue])

  const onSyncTemplate = () => {
    request('sync-template', 'POST', { template_id: blockDetails?.template_id, block_id: blockDetails?.id }).then((res) => {
      isSuccessResponse(res,'Template synced successfully!')
    })
  }
  return (
    <div className='m-5'>
      {showImportForm && (
        <div className='mb-5'>
          <AvForm
            onValidSubmit={(e, values) => {
              setLoading(true)
              onSubmitBlockDetails(values)
            }}>
            <RenderFields
              allForm={[importForm]}
              content={[importForm]}
              twoCol={true}
              // defaultFormValue={defaultSingleFormValue}
              formFieldValue={importFormFieldValue}
              setFormFieldValue={setImportFormFieldValue}
              defaultFormValue={{}}
              noCache={true}
            />
          </AvForm>
        </div>
      )}

      {isImportingLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Loader type='ball-pulse' color='blue' />
        </div>
      )}
      {isImportError && (
        <div class='alert alert-danger' role='alert'>
          Block already exist
        </div>
      )}

      {!isPlannedBlock && !isMainFormDisabled && formFieldValue?.template_id && (
        <Button
          className='mb-4 subtitle1'
          color='primary'
          onClick={() => {
            onSyncTemplate()
          }}>
          Sync
        </Button>
      )}

      <div>
        <AvForm
          className={`${isMainFormDisabled && 'disabled-pill'}`}
          onValidSubmit={(e, values) => {
            setLoading(true)
            onSubmitBlockDetails(values)
          }}>
          <RenderFields
            allForm={[mainForm]}
            content={[mainForm]}
            twoCol={true}
            // defaultFormValue={defaultSingleFormValue}
            formFieldValue={formFieldValue}
            setFormFieldValue={setFormFieldValue}
            defaultFormValue={blockDetails || {}}
            noCache={true}
            disabled_field={['template_id']}
          />
          <div className='d-flex justify-content-end mt-5'>
            {' '}
            <button
              className='btn rounded-pill mt-2 text-danger border-danger mr-3'
              type='button'
              id='close-btn'
              onClick={(e) => {
                onCloseForm()
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ fontWeight: 700, width: '12rem' }}>
              Close
            </button>
            <button
              className='btn text-white rounded-pill mt-2 mr-3'
              disabled={!!loading}
              type='submit'
              id='save-button'
              style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              className='btn text-white rounded-pill mt-2'
              disabled={!!loading}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                saveNclose.current = true
                const ele = document.getElementById('save-button')
                if (ele) {
                  ele.click()
                }
              }}
              type='submit'
              style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
              Save & Close
            </button>
          </div>
        </AvForm>
      </div>
    </div>
  )
}

export default BlockMainDetails
