import Loader from 'react-loaders'
import useGetTemplateDesign from '../../Queries/EmailTemplate/useGetTemplateDesign'
import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

const TemplatePreview = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let uuid = currentRow?.[currentRow?.length - 1]?.uuid

  const { data, isLoading } = useGetTemplateDesign(uuid)

  const [iframeHeight, setIframeHeight] = useState('0px')

  let html = ''
  if (data?.emailTemplate) {
    html = data?.emailTemplate
  }

  const iframeRef = useRef(null)
  const updateIframeHeight = () => {
    const iframe = iframeRef.current
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
    setIframeHeight(`${iframeDoc.body.scrollHeight}px`)
  }

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc = iframeRef.current.contentDocument
      iframeDoc.open()
      iframeDoc.write(html)
      iframeDoc.close()

      iframeRef.current.addEventListener('load', updateIframeHeight)
      updateIframeHeight()
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', updateIframeHeight)
      }
    }
  }, [html, iframeRef.current])


  if (isLoading) {
    return (
      <div style={{ marginTop: '3rem' }} className='d-flex justify-content-center'>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  }
  return (
    <div>
      <iframe ref={iframeRef} style={{ width: '100%', height: iframeHeight, border: 'none' }} title='HTML Renderer' />
      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
    </div>
  )
}

export default TemplatePreview
