import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Switch from 'react-switch'
import cx from 'classnames'
import CMSButton from '../../../../CMSComponent/Forms/Fields/button'

import Loader from 'react-loaders'
import { primary_color } from '../../../../Helper/uiHelper'
import useFetchVoucher from '../../../../Queries/BlockPlan/useFetchVoucher'
import IconSet from '../../../../CMSComponent/icon'
import { useDispatch, useSelector } from 'react-redux'
import AssignForm from './assignForm'
import AddVoucher from './addVoucher'
import { AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation'
import { request } from '../../../../Redux/Sagas/requests/api'
import set from 'date-fns/fp/set/index.js'
import { isSuccessResponse } from '../../../../helper'
import { export_button } from './voucherConstant'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

const VoucherTable = ({ tab }) => {
  const dispatch = useDispatch()
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false)

  const [validFrom, setValidFrom] = useState(null)
  const [validTo, setValidTo] = useState(null)
  const [code, setCode] = useState()
  const [discount, setDiscount] = useState('')
  const [page, setPage] = useState()
  const [email, setEmail] = useState()
  const [filterType, setFilterType] = useState('all')

  const [sendVoucherOnEmail, setSendVoucherOnEmail] = useState(false)

  const [selectedVoucher, setSelectedVoucher] = useState([])
  const [assignData, setAssignData] = useState(false)

  const handleVoucherSelection = (checked, voucher) => {
    if (checked) {
      const newUser = [...selectedVoucher]
      newUser?.push(voucher)
      setSelectedVoucher(newUser)
    } else {
      const newUser = [...selectedVoucher]
      let index
      newUser?.map((item, i) => {
        if (item?.id === voucher?.id) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedVoucher(newUser)
    }
  }

  const {
    data: voucherData,
    isLoading,
    refetch,
  } = useFetchVoucher({
    domain_id: currentRowData?.[0]?.id,
    page,
    email,
    valid_to: validTo,
    valid_from: validFrom,
    code,
    discount,
    filter_type: filterType,
  })

  const selectedVoucherId = selectedVoucher?.map((item) => item?.id)

  useEffect(() => {
    request('send-voucher-on-email', 'POST', { domain_id: currentRowData?.[0]?.id }).then((res) => {
      if (res?.data) {
        setSendVoucherOnEmail(res?.data)
      }
    })
  }, [])

  const onChangeToggle = () => {
    request('update-voucher-on-email', 'POST', { domain_id: currentRowData?.[0]?.id, value: !sendVoucherOnEmail }).then((res) => {
      if (isSuccessResponse(res)) {
        request('send-voucher-on-email', 'POST', { domain_id: currentRowData?.[0]?.id }).then((res) => {
          setSendVoucherOnEmail(res?.data)
        })
      }
    })
  }

  const assignVoucher = (id) => {
    request('assign-voucher', 'POST', { id }).then((res) => {
      isSuccessResponse(res, 'Voucher assigned successfully!')
      refetch()
    })
  }

  const onDeleteCode = () => {
    request('delete-voucher', 'POST', { ids: selectedVoucherId }).then((res) => {
      isSuccessResponse(res, 'Voucher deleted successfully!')
      refetch()
      setSelectedVoucher([])
      setIsDeleteWarningOpen(false)
    })
  }

  if (assignData)
    return (
      <AssignForm
        defaultValue={assignData}
        refetch={refetch}
        onCloseForm={() => {
          setAssignData()
        }}></AssignForm>
    )

  if (isFormOpen) {
    return <AddVoucher grid_id={tab?.component_call_form?.[0]?.main_grid?.id} onCloseForm={() => setIsFormOpen(false)} refetch={refetch}></AddVoucher>
  }
  return (
    <div className='px-3 mt-4 merge-user-table'>
      <div className='mb-3 d-flex'>
        <div className='mr-3 subtitle1'>Send voucher code via email</div>
        <Switch
          checked={sendVoucherOnEmail}
          onChange={(e) => onChangeToggle()}
          height={25} // Adjust the height of the switch
          // width={60}
          uncheckedIcon={<i className='fa-thin fa-xmark switch-icon subtitle1'></i>}
          checkedIcon={<i className='fa-thin fa-check switch-icon subtitle1'></i>}
          className='d-inline subtitle1'
        />
      </div>
      <div className='d-flex justify-content-between '>
        {' '}
        <AvForm>
          {' '}
          <AvRadioGroup inline name='radioExample2' className='mt-3 mb-4' value={filterType} onChange={(e) => setFilterType(e.target.value)} required>
            <AvRadio label={<span className='subtitle1'>All</span>} value='all' />
            <AvRadio label={<span className='subtitle1'>Used</span>} value='used' />
            <AvRadio label={<span className='subtitle1'>Issued</span>} value='issued' />
            <AvRadio label={<span className='subtitle1'>Not Issued</span>} value='not_issued' />
          </AvRadioGroup>
        </AvForm>
        <div className='float-right'>
          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            // onClick={onPrintClick}
            disabled={true}>
            <IconSet icon='fa-regular fa-print' color='light' />
          </Button>
          <Button
            className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              setIsFormOpen(true)
            }}>
            <IconSet icon='fa-solid fa-plus' color='light' />
          </Button>
          <Button
            className={`btn-danger border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            disabled={selectedVoucherId?.length > 0 ? false : true}
            onClick={() => {
              setIsDeleteWarningOpen(true)
              // setIsFormOpen(true)
            }}>
            <IconSet icon='fa-solid fa-trash' color='light' />
          </Button>

          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              refetch()
            }}>
            <IconSet icon='fa-solid fa-sync' color='light' />
          </Button>
          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              setEmail('')
              setDiscount('')
              setCode('')
              setValidTo(null)
              setValidFrom(null)
            }}>
            <IconSet icon='fas fa-ban' color='light' />
          </Button>
          <CMSButton
            item={export_button}
            triggerActions={() => {
              dispatch(setNotification({ type: 'success', message: 'Voucher exported successfully!' }))
            }}></CMSButton>
        </div>
      </div>

      <Table hover className='notes-type-table'>
        <thead>
          <tr>
            <th>
              <input
                type='checkbox'
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedVoucher(voucherData?.data)
                  } else {
                    setSelectedVoucher([])
                  }
                }}
                // checked={checkUserSelected(selectedVoucherId, item?.id)}
                // onChange={(e) => handleVoucherSelection(e.target.checked, item)}
              ></input>
            </th>
            <th className='no-border-bottom'></th>
            <th className='subtitle1 font-weight-bold py-2' style={{ color: primary_color, textTransform: 'capitalize' }}>
              START DATE
            </th>
            <th className='subtitle1 font-weight-bold py-2' style={{ color: primary_color, textTransform: 'capitalize' }}>
              END DATE
            </th>
            <th className='subtitle1 font-weight-bold py-2' style={{ color: primary_color, textTransform: 'capitalize' }}>
              CODE
            </th>
            <th className='subtitle1 font-weight-bold py-2' style={{ color: primary_color, textTransform: 'capitalize' }}>
              DISCOUNT(%)
            </th>
            <th className='subtitle1 font-weight-bold py-2' style={{ color: primary_color, textTransform: 'capitalize' }}>
              EMAIL
            </th>
          </tr>
          <tr>
            <th></th>
            <th className='no-border-top'></th>

            <th>
              <DatePicker
                selected={validFrom && moment(validFrom, 'YYYY-MM-DD').toDate()}
                className='date-picker remove-outline subtitle1'
                dateFormat='d MMMM , yyyy'
                placeholder='Search'
                onChange={(e) => {
                  setValidFrom(moment(e).format('YYYY-MM-DD'), true)
                }}
              />
            </th>
            <th>
              <DatePicker
                selected={validTo && moment(validTo, 'YYYY-MM-DD').toDate()}
                className='date-picker remove-outline subtitle1'
                dateFormat='d MMMM , yyyy'
                placeholder='Search'
                onChange={(e) => {
                  setValidTo(moment(e).format('YYYY-MM-DD'), true)
                }}
              />
            </th>
            <th>
              <Input className='subtitle1' type='text' placeholder='Search' value={code} onChange={(e) => setCode(e.target.value)} />
            </th>
            <th>
              <Input className='subtitle1' type='text' placeholder='Search' value={discount} onChange={(e) => setDiscount(e.target.value)} />
            </th>
            <th>
              <Input className='subtitle1' type='text' placeholder='Search' value={email} onChange={(e) => setEmail(e.target.value)} />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                  <Loader type='ball-pulse' color='blue' />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {voucherData?.data?.map((item, index) => {
                return (
                  <tr key={item.id} className={`${index % 2 === 0 && 'row-odd'}`}>
                    <td>
                      <input
                        type='checkbox'
                        checked={checkUserSelected(selectedVoucherId, item?.id)}
                        onChange={(e) => handleVoucherSelection(e.target.checked, item)}></input>
                    </td>
                    <td>
                      {!item.issued && (
                        <Button
                          className={`btn-icon btn-icon-only btn btn-primary btn-sm subtitle1`}
                          style={{ background: primary_color }}
                          onClick={() => {
                            if (!sendVoucherOnEmail) {
                              assignVoucher(item?.id)
                            } else {
                              setAssignData(item)
                            }
                          }}>
                          Assign
                        </Button>
                      )}
                    </td>

                    <td className='subtitle1'>{item.start && moment(item.start).format('Do MMM YYYY')}</td>
                    <td className='subtitle1'>{item.end && moment(item.end).format('Do MMM YYYY')}</td>
                    <td className='subtitle1'>{item.code}</td>
                    <td className='subtitle1'>{item.discount_percent}</td>
                    <td className='subtitle1'>{item.email}</td>
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </Table>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {voucherData?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  className='subtitle2'
                  onClick={() => {
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(voucherData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(voucherData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>

        <Modal
          isOpen={!!isDeleteWarningOpen}
          centered={true}
          toggle={() => {
            setIsDeleteWarningOpen(false)
          }}
          style={{ width: '50rem', boxShadow: 'none', maxWidth: '50rem' }}>
          <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
            <div className='p font-weight-bold' style={{ color: primary_color }}>
              It will delete the code which is not assigned yet. Are you sure that you want to procceed further?
            </div>
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-between'>
              <button
                className='btn text-white rounded-pill mt-2'
                type='button'
                onClick={() => {
                  onDeleteCode()
                }}
                style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
                Yes
              </button>
              <button
                className='btn rounded-pill mt-2 text-danger border-danger'
                type='button'
                onClick={() => {
                  setIsDeleteWarningOpen(false)
                }}
                style={{ fontWeight: 700, width: '48%' }}>
                Cancel
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  )
}

export default VoucherTable
