import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!email) {
      dispatch(setNotification({ type: 'error', message: 'please enter email' }))
      return
    }
    request('forgot-password', 'POST', { email }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: res?.data?.message }))
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message }))
      }
    })
  }
  return (
    <div>
      <div className='subtitle1 mb-3 text-muted' style={{ textAlign: 'left' }}>
        Please add your registered email address and you will be sent a link to change your password
      </div>
      <form className=' d-flex flex-column' style={{ gap: '0.75rem' }}>
        <input
          className=' form-control rounded-pill rounded-input'
          placeholder='Email'
          type='email'
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className='btn w-100 text-white rounded-pill mt-2' style={{ background: primary_color }} onClick={(e) => handleSubmit(e)}>
          Send
        </button>
      </form>
    </div>
  )
}

export default ForgotPassword
