import { Input, Table } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'

const ReservedLockerTable = ({ previewLocker, setPreviewLocker, setChangeLocker }) => {
  const onChangeValue = (value, field, index) => {
    setPreviewLocker((data) => {
      const newData = data?.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          }
        } else {
          return item
        }
      })
      return newData
    })
  }
  return (
    <Table className='notes-type-table' hover>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
            First Name
          </th>
          <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
            Surname
          </th>
          <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
            Locker No
          </th>
          <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
            Tier
          </th>
        </tr>
      </thead>
      <tbody>
        {previewLocker.map((item, index) => {
          return (
            <tr key={item.item_id}>
              <td>
                <input
                  type='checkbox'
                  // checked={checkUserSelected(selectedUserUUID, item?.uuid)}
                  // onChange={(e) => handleUserSelection(e.target.checked, item)}
                ></input>
              </td>
              <td>
                <div
                  className='cursor-pointer py-1 px-2 d-flex align-items-center justify-content-center'
                  style={{ background: `${primary_color}33`, borderRadius: '5px' }}
                  onClick={() => setChangeLocker(item)}>
                  <i class='fa-solid fa-pen-to-square primary_color' color={primary_color}></i>
                </div>
              </td>
              <td className='subtitle1'>
                <Input
                  type='text'
                  value={item?.first_name}
                  onChange={(e) => {
                    if (e.target.value) {
                      onChangeValue(e.target.value, 'first_name', index)
                    }
                  }}
                />
              </td>
              <td className='subtitle1'>
                <Input
                  type='text'
                  value={item?.last_name}
                  onChange={(e) => {
                    if (e.target.value) {
                      onChangeValue(e.target.value, 'last_name', index)
                    }
                  }}
                />
              </td>
              <td className='subtitle1'>{item.item_id_label}</td>
              <td className='subtitle1'>{item.tier}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ReservedLockerTable
