import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import DatePicker from 'react-datepicker'

import { Row, Col } from 'reactstrap'
import { tidyName } from '../../../helper'
import IconSet from '../../icon'
import ConditionCommonField from './conditionCommonField'
import { TagsInput } from 'react-tag-input-component'
import WhereClauseOR from './whereClauseOr'

const renderFields = (type, form, onChangeValue, count, value) => {
  switch (type) {
    case 'todays_date':
      return (
        <Col md={6}>
          <div>VALUE</div>
          <div style={{ width: '100%' }}>
            <DatePicker
              selected={new Date()}
              className='where-clause-date-picker'
              dateFormat='d MMMM , yyyy HH:mm'
              readOnly={true}
              //   onChange={(e) => {
              //     onChangeValue(count, e);
              //   }}
            />
          </div>
        </Col>
      )
    case 'fixed_date_value':
      return (
        <Col md={6}>
          <div>VALUE</div>
          <div style={{ width: '100%' }}>
            <DatePicker
              selected={value ? new Date(value) : null}
              className='where-clause-date-picker'
              dateFormat='d MMMM , yyyy'
              onChange={(e) => {
                onChangeValue(count, e)
              }}
            />
          </div>
        </Col>
      )
    case 'fixed_numeric_value':
      return (
        <Col md={6}>
          <AvField name='value' value={form?.value} label='Value' type='number' onChange={(e) => onChangeValue(count, e.target.value)}></AvField>
        </Col>
      )
    case 'select_multiple_values':
      return (
        <Col md={6}>
          <label>Values:</label>
          <TagsInput
            separators={['Enter', 'Tab']}
            value={value}
            onChange={(value) => onChangeValue(count, value)}
            name='email'
            placeHolder='Values'
          />
        </Col>
      )

    default:
      return (
        <Col md={6}>
          <AvField name='value' label='Value' value={form?.value} onChange={(e) => onChangeValue(count, e.target.value)}></AvField>
        </Col>
      )
  }
}

const WhereClause = ({ source_table, onChange = () => {}, form, isCreatingField, main_source_table_for_field, isCreatingFieldForRepeatingForm }) => {
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)
  const [whereClauseForm, setWhereClauseForm] = useState([])
  const [showDropDown, setShowDropDown] = useState(false)

  useEffect(() => {
    if (form && form?.length > 0) {
      setWhereClauseForm(form)
    }
  }, [])

  const onSelectChange = (count, value) => {
    // const updated_form = [...whereClauseForm];
    // const old_value = updated_form[count][Object.keys(updated_form[count])[0]];

    // let new_object_form = { ...updated_form[count] };
    // new_object_form[value] = old_value;
    // updated_form[count] = new_object_form;
    // setWhereClauseForm(updated_form);
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['field'] = value
    setWhereClauseForm(updated_form)
  }

  const onValueChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['value'] = value
    if (showDropDown) {
      old_object['ref_for_form_field'] = true
    }

    setWhereClauseForm(updated_form)
  }

  const onSaveWhereClauseOR = (data, whereClauseIndex, whereClauseORIndex, isEdit) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[whereClauseIndex]
    if (old_object?.where_clause_or && Array.isArray(old_object?.where_clause_or)) {
      if (isEdit) {
        old_object['where_clause_or'][whereClauseORIndex] = { ...data }
      } else {
        old_object['where_clause_or'] = [...old_object['where_clause_or'], { ...data }]
      }
    } else {
      old_object['where_clause_or'] = [{ ...data }]
    }
    setWhereClauseForm(updated_form)
  }

  const onDeleteWhereClauseOr = (whereClauseIndex, whereClauseORIndex) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[whereClauseIndex]
    old_object['where_clause_or']?.splice(whereClauseORIndex, 1)
    setWhereClauseForm(updated_form)
  }

  const onRemove = (index) => {
    const updated_form = [...whereClauseForm]
    updated_form?.splice(index, 1)
    setWhereClauseForm(updated_form)
  }
  const onConditionChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['condition'] = value
    setWhereClauseForm(updated_form)
  }

  const onTypeChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['type'] = value
    old_object['value'] = ''
    old_object['condition'] = ''
    setWhereClauseForm(updated_form)
  }

  const onBetweenTypeChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['between_type'] = value
    old_object['between_value'] = ''
   
    setWhereClauseForm(updated_form)
  }
  const onBetweenPeriodChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['between_period'] = value
    old_object['between_value'] = ''
    setWhereClauseForm(updated_form)
  }
  const onBetweenValueChange = (count, value) => {
    const updated_form = [...whereClauseForm]
    const old_object = updated_form[count]
    old_object['between_value']  = value
    setWhereClauseForm(updated_form)
  }

  useEffect(() => {
    onChange(whereClauseForm)
  }, [whereClauseForm])

  const select_column_option = grid_selector[source_table]?.columns


  return (
    <div>
      <hr></hr>
      <div>
        <span style={{ fontSize: '1rem' }}>Add where clause</span>
        <Button
          color='success'
          className='float-right'
          onClick={() => {
            setWhereClauseForm([...whereClauseForm, {}])
          }}
          style={{ marginTop: '-5px' }}>
          <IconSet icon={'fa-solid fa-plus'} color='light' />
        </Button>
      </div>

      <hr></hr>
      <AvForm>
        {whereClauseForm?.length > 0 &&
          whereClauseForm?.map((form, index) => {
            return (
              <Row md={12} key={index}>
                <Col md={12}>
                  <div style={{ marginBottom: '0.5rem' }}>
                    {index + 1}.{' '}
                    <span
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => {
                        onRemove(index)
                      }}>
                      Remove Clause
                    </span>
                  </div>
                </Col>
                <ConditionCommonField
                  source_table={source_table}
                  columnValue={select_column_option?.filter((column) => column?.name === whereClauseForm[index]?.field)[0]?.name}
                  onColumnChange={(value) => onSelectChange(index, value)}
                  type={form?.type}
                  onTypeChange={(value) => onTypeChange(index, value)}
                  setShowDropDown={(value) => setShowDropDown(value)}
                  condition={form?.condition}
                  onConditionChange={(value) => onConditionChange(index, value)}
                  onValueChange={(value) => onValueChange(index, value)}
                  value={form?.value}
                  onChangeBetweenType={(value) => onBetweenTypeChange(index, value)}
                  between_type={form?.between_type}
                  onChangeBetweenPeriod={(value) => onBetweenPeriodChange(index, value)}
                  between_period={form?.between_period}
                  between_value={form?.between_value}
                  onChangeBetweenValue={(value) => onBetweenValueChange(index, value)}
                  isCreatingField={isCreatingField}
                  main_source_table_for_field={main_source_table_for_field}
                />
                <Col md={12}>
                  {' '}
                  <WhereClauseOR
                    source_table={source_table}
                    select_column_option={select_column_option}
                    isCreatingField={isCreatingField}
                    main_source_table_for_field={main_source_table_for_field}
                    onSaveWhereClauseOR={onSaveWhereClauseOR}
                    onDeleteWhereClauseOr={onDeleteWhereClauseOr}
                    whereClauseIndex={index}
                    whereClauseForm={form}
                  />
                </Col>

                {/* <Col md={6}>
                  <AvField
                    type="text"
                    name="where_clause_value"
                    label="Value"
                    
                    onChange={(e) => onValueChange(index, e.target.value)}
                  ></AvField>
                </Col> */}
              </Row>
            )
          })}
      </AvForm>
    </div>
  )
}

export default WhereClause
