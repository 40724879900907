import React, { useState, useCallback, useRef } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { primary_color } from '../../Helper/uiHelper'
import { request } from '../../Redux/Sagas/requests/api'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from '../../Helper/cookieHelper'
import { setPublicUser } from '../../Redux/Reducers/user'
import ProfilePicDropdown from './profilePicDropdown'

const ImageUploadAndCrop = ({ profilePic, defaultProfilePic, onRemoveProfilePic }) => {
  const dispatch = useDispatch()
  const publicUser = useSelector((state) => state.User.publicUser)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [src, setSrc] = useState(null)
  const [crop, setCrop] = useState({ aspect: 16 / 9 })
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [file,setFile] = useState()
  // const [imageRef, setImageRef] = useState(null)
  const imageRef = useRef()

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setSrc(reader.result)
        setIsModalOpen(true)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoaded = useCallback((image) => {
    imageRef.current = image
    // setImageRef(image)
  }, [])

  const onCropComplete = useCallback((crop) => {
    makeClientCrop(crop)
  }, [])

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const makeClientCrop = async (crop) => {
    if (imageRef?.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef?.current, crop, 'newFile.jpeg')
      setCroppedImageUrl(croppedImageUrl)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty')
          return
        }
        const fileUrl = window.URL.createObjectURL(blob)
        resolve(fileUrl)
      }, 'image/jpeg')
    })
  }

  const uploadCroppedImage = async (e) => {
    const response = await fetch(croppedImageUrl)
    const blob = await response.blob()
    const formData = new FormData()
    formData.append('file', blob, 'croppedImage.jpeg')

    const token = getCookie('AUTH_TOKEN')

    fetch(process.env.REACT_APP_API_URL + '/file-upload/profile', {
      method: 'POST',
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : 'Bearer token',
      },
    })
      .then((response) => {
        if (response.ok) {
          dispatch(setNotification({ type: 'success', message: 'Image uploaded successfully!' }))
          onCancelImage()
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
        request('profile').then((res) => {
          if (res?.status === 200 || res?.status === 202) {
            dispatch(setPublicUser(res?.data?.data))
          }
        })
      })
      .catch((error) => console.error('Error uploading image:', error))
  }

  const onCancelImage = () => {
    setSrc()
    imageRef.current = null
    setCroppedImageUrl()
    setCrop()
    setIsModalOpen()
  }

  const onProfilePicUpload = (file) =>{
     const formData = new FormData()
     formData.append('file', file)
     const token = getCookie('AUTH_TOKEN')
     fetch(process.env.REACT_APP_API_URL + '/file-upload/profile', {
       method: 'POST',
       body: formData,
       headers: {
         authorization: token ? `Bearer ${token}` : 'Bearer token',
       },
     })
       .then((response) => {
         if (response.ok) {
           dispatch(setNotification({ type: 'success', message: 'Image uploaded successfully!' }))
           onCancelImage()
         } else {
           dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
         }
         request('profile').then((res) => {
           if (res?.status === 200 || res?.status === 202) {
             dispatch(setPublicUser(res?.data?.data))
           }
         })
       })
       .catch((error) => console.error('Error uploading image:', error))
  }

  return (
    <div>
      <input
        type='file'
        accept='image/*'
        id='upload_profile_pic_direct'
        style={{ display: 'none' }}
        onChange={(e) => onProfilePicUpload(e.target.files?.[0])}
      />
      <input type='file' accept='image/*' id='upload_profile_pic' style={{ display: 'none' }} onChange={onSelectFile} />
      <label htmlFor='upload_profile_pic' className='profile-icon position-relative'>
        {publicUser?.photo ? (
          <img
            src={process.env.REACT_APP_STORAGE + publicUser?.photo}
            alt='Profile'
            className='profile-pic cursor-pointer'
            style={{ height: '100px', width: '100px', borderRadius: '100px', objectFit: 'cover' }}
          />
        ) : (
          <img
            src={defaultProfilePic}
            alt='Profile'
            className='profile-pic cursor-pointer'
            style={{ height: '100px', width: '100px', borderRadius: '100px' }}
          />
        )}
        <ProfilePicDropdown isProfilePicUploaded={publicUser?.photo} onRemoveProfilePic={onRemoveProfilePic} />
      </label>
      {src && (
        <Modal toggle={() => setIsModalOpen(false)} isOpen={isModalOpen}>
          <ModalHeader toggle={() => setIsModalOpen(false)}>Crop Image</ModalHeader>
          <ModalBody>
            <ReactCrop src={src} crop={crop} onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange} />{' '}
            {croppedImageUrl && (
              <div className='d-flex justify-content-center'>
                <img alt='Crop' style={{ borderRadius: '100%', height: '10rem', width: '10rem', objectFit:'cover' }} src={croppedImageUrl} />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {croppedImageUrl && (
              <div className='d-flex'>
                <button
                  className='btn mr-3 btn-outline-danger rounded-pill px-5 d-flex gap-5 justify-content-center align-items-center'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onCancelImage()
                  }}>
                  Cancel
                </button>

                <button
                  className='btn btn-primary rounded-pill px-5'
                  style={{ background: primary_color }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    uploadCroppedImage()
                  }}>
                  Crop
                </button>
              </div>
            )}
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default ImageUploadAndCrop
