import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSContent from '../../CMSWidget/Content/cmsContent'
import { useSelector } from 'react-redux'

const Content = ({
  heading,
  subHeading,
  description,
  buttonText,
  buttonIcon = 'fa fa-download',
  buttonType = 'v1',
  image,
  action_url,
  style,
  widget_uuid,
  data,
}) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()
  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()
  const sectionRef = useRef(null)

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        // top: rect.top + window.scrollY - 50,
        top: rect.height + 100,

        //  left: 100, // Position below the parent element
        left: 0, // Align left edge with parent element
        width: '100%',
      })
    }
  }
  return (
    <div
      className={` d-flex flex-column mb-5 mt-5 justify-content-center position-relative ${modalPosition && 'active-cms-edit'}`}
      ref={sectionRef}
      onClick={handleSectionClick}>
      {/* <h2 style={{ fontSize: isDesktop ? '30px' : '25px', fontWeight: '600', color: primary_color }} className='mb-2'> */}
      {heading && (
        <div
          className='content-widget heading position-relative'
          dangerouslySetInnerHTML={{ __html: heading }}
          style={{ color: primary_color, fontWeight: '700 !important', marginBottom: '2rem' }}></div>
      )}

      {/* {subHeading ? <div className={`mb-3 ${style && style}`}>{subHeading}</div> : null} */}
      {subHeading ? <div dangerouslySetInnerHTML={{ __html: subHeading }} className={`mb-3 content-widget ${style && style}`}></div> : null}
      {/* {description ? (
        <div
          style={{
            fontSize: isDesktop ? '20px' : '14px',
            color: '#8E8E8E',
            lineHeight: isDesktop ? '40px' : '30px',
            fontWeight: 400,
            marginBottom: isDesktop ? '3rem' : '1rem',
          }}>
          {description}
        </div>
      ) : null} */}
      {/* {description ? <div className={`rich-text my-3 ${style && style}`}>{description}</div> : null} */}
      {description ? (
        <div dangerouslySetInnerHTML={{ __html: description }} className={`rich-text mb-3 content-widget p ${style && style}`}></div>
      ) : null}
      {buttonText ? (
        <>
          <Button
            className={` poppin d-flex align-items-center justify-content-center mb-2 ${style && style}`}
            style={{
              color: buttonType === 'v1' ? 'white' : primary_color,
              border: `2px solid ${primary_color}`,
              // maxWidth: isDesktop ? 360 : '100%',
              // height: isDesktop ? 56 : 48,
              backgroundColor: buttonType === 'v1' ? primary_color : 'transparent',
              borderRadius: 30,
              width: 'fit-content',
              padding: '0.8rem 6.5rem',
            }}
            onClick={() => {
              if (action_url) {
                history.push(action_url)
              }
            }}>
            <span className='body2 mr-2' style={{ fontWeight: 600 }}>
              {buttonText}
            </span>
            <i className={`${buttonIcon} ml-2 body2 font-weight-bold`}></i>
          </Button>
        </>
      ) : null}
      {image && <img src={process.env.REACT_APP_STORAGE + image} className={`${style && style} mb-4 mt-4`}></img>}
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => setModalPosition(null)}>
          <CMSContent widget_uuid={widget_uuid} data={data} toggleModal={() => setModalPosition(null)} isDataArray={false} />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default Content
