import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from '../../../CMSComponent/Forms/Fields/select'
import { Col, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { isSuccessResponse } from '../../../helper'
import ReservedLockerTable from './reservedLockerTable'
import ChangeLocker from './changeLocker'
import { clearCurrentRowData, clearParentTableData, fetchUpdateApiData } from '../../../Redux/Reducers/CMS'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'

const PupilPremiumForm = ({ addDataDetail, setAddGridDataDetail }) => {
  const [uuid, setuuid] = useState(uuidv4())
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [form, setForm] = useState()
  const [blocks, setBlocks] = useState([])
  const [previewLocker, setPreviewLocker] = useState([])
  const [changeLocker, setChangeLocker] = useState()
  const [noBlockError, setNoBlockError] = useState(false)
  const [tierData, setTierData] = useState([])
  const [loader, setLoader] = useState({ form: false, blocks: false, preview: false, book: false })

  const [formFieldValue, setFormFieldValue] = useState({})

  useEffect(() => {
    setLoader({ ...loader, form: true })
    request('get-grid-form', 'POST', { grid_id: addDataDetail?.mainGrid?.id }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setForm(res.data?.[0])
      }
      setLoader({ ...loader, form: false })
    })
  }, [])

  useEffect(() => {
    if (formFieldValue?.item_date_time_id && formFieldValue?.categories_id) {
      setNoBlockError(false)
      setLoader({ ...loader, blocks: true })
      request('get-tier-by-category-academic', 'POST', {
        item_date_time_id: formFieldValue?.item_date_time_id,
        category_id: formFieldValue?.categories_id,
        domain_id: currentRow?.[0]?.id,
      }).then((res) => {
        let maxTier = 0
        if(res?.data?.length === 0){
          setNoBlockError(true)
        }
        res?.data?.map((item) => {
          if (item?.max_tier > maxTier) {
            maxTier = item?.max_tier
          }
        })
        const tierData = []
        for (let i = 1; i <= maxTier; i++) {
          tierData?.push({
            label: `Tier ${i}`,
            value: i,
            checked: true,
          })
        }
        setTierData(tierData)
        setLoader({ ...loader, blocks: false })
      })
    }
  }, [formFieldValue?.item_date_time_id, formFieldValue?.categories_id])

  useEffect(() => {
    if (formFieldValue?.item_date_time_id && formFieldValue?.categories_id && tierData?.length > 0) {
      setNoBlockError(false)
      setLoader({ ...loader, blocks: true })
      request('get-location-item-count', 'POST', {
        item_date_time_id: formFieldValue?.item_date_time_id,
        category_id: formFieldValue?.categories_id,
        domain_id: currentRow?.[0]?.id,
        tier: tierData?.filter((item) => item.checked)?.map((item) => item?.value),
      }).then((res) => {
        if (res.status === 200 || res.status === 202) {
          const filteredBlock = res?.data?.filter((item) => {
            if (item?.total_items) {
              return true
            } else {
              return false
            }
          })
          setBlocks(filteredBlock)
        } else if (res.status === 404) {
          setNoBlockError(true)
          setBlocks([])
          setPreviewLocker([])
        }
        setLoader({ ...loader, blocks: false })
      })
    }
  }, [formFieldValue?.item_date_time_id, formFieldValue?.categories_id, tierData])

  useEffect(()=>{
    if(formFieldValue?.user_id){
      request('get-user-by-id', 'POST', { user_id: formFieldValue?.user_id }).then((res) => {
        if (res?.data) {
          setFormFieldValue((data) => {
            return {
              ...data,
              first_name: res?.data?.first_name,
              last_name: res?.data?.surname,
            }
          })
        }
      })
    }
  },[formFieldValue?.user_id])

  const onCloseForm = () => {
    dispatch(fetchUpdateApiData())
    if (!addDataDetail?.isAddNew) {
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
    }
    setAddGridDataDetail({ open: false })
    dispatch(fetchUpdateApiData())
  }

  const validateAllFields = (values) => {
    if (!values.item_date_time_id) {
      dispatch(setNotification({ type: 'error', message: 'Please select academic year' }))
      return false
    }
    if (!values.categories_id) {
      dispatch(setNotification({ type: 'error', message: 'Please select student year group' }))
      return false
    }
    if (!values.first_name) {
      dispatch(setNotification({ type: 'error', message: 'Please select name' }))
      return false
    }
    if (!values.user_id) {
      dispatch(setNotification({ type: 'error', message: 'Please select user' }))
      return false
    }
    return true
  }

  const onPreviewLocker = (save) => {
    if (!validateAllFields(formFieldValue)) return
    if (previewLocker?.length > 0) {
      callBookLocker(previewLocker)
    } else {
      const bookedPayload = []
      blocks?.map((item) => {
        if (item?.count) {
          if (parseInt(item?.count > item?.total_items - item?.total_booked_items)) {
            dispatch(setNotification({ message: 'Number of locker is more than available lockers', type: 'error' }))
            return
          }
          bookedPayload?.push({ id: item?.location_id, count: item?.count })
        }
      })
      if (bookedPayload?.length == 0) {
        return dispatch(setNotification({ type: 'error', message: 'Please add locker count' }))
      }
      setLoader({ ...loader, preview: true })
      request('reserve-pupil-locker', 'POST', {
        domain_id: currentRow?.[0]?.id,
        ...formFieldValue,
        booked_locker: bookedPayload,
        session_id: uuid,
        tier: tierData?.filter((item) => item.checked)?.map((item) => item?.value),
      }).then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          setPreviewLocker(res?.data?.data)
          if (save) {
            callBookLocker(res?.data?.data)
          }
        }
        setLoader({ ...loader, preview: false })
      })
    }
  }

  const callBookLocker = (reservedLockers) => {
    setLoader({ ...loader, book: true })
    const lockers = reservedLockers || previewLocker
    request('booked-pupil-locker', 'POST', {
      items: lockers,
      session_id: uuid,
    }).then((res) => {
      isSuccessResponse(res, 'Lockers booked successfully!')
      setLoader({ ...loader, book: false })
      onCloseForm()
    })
  }

  const onChangeLockerInPupilPremium = (locker) => {
    const newLockerData = previewLocker?.map((item) => {
      if (item?.item_id === changeLocker?.item_id) {
        const { questions, ...rest } = locker
        return { ...item, ...rest, old_item_id: changeLocker?.item_id }
      } else {
        return item
      }
    })
    setPreviewLocker(newLockerData)
    setChangeLocker()
  }

  const onChangeTier = (checked, index) => {
    const newTierData = tierData?.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          checked,
        }
      } else {
        return item
      }
    })
    setTierData(newTierData)
  }

  const academicYearField = form?.component_call_fields?.find((item) => item?.field_id === 'item_date_time_id')
  const yearGroupField = form?.component_call_fields?.find((item) => item?.field_id === 'categories_id')
  const assignGroupField = form?.component_call_fields?.find((item) => item?.field_id === 'user_id')

  return (
    <div className='p-4'>
      <div style={{ color: primary_color }} className='body1 font-weight-bold my-4'>
        Create Pupil Premium
      </div>
      {changeLocker && (
        <ChangeLocker
          changeLocker={changeLocker}
          setChangeLocker={setChangeLocker}
          item={changeLocker}
          onChangeLockerInPupilPremium={onChangeLockerInPupilPremium}></ChangeLocker>
      )}

      <div style={{ visibility: !!changeLocker && 'hidden' }}>
        <AvForm>
          <Row>
            {academicYearField && (
              <Col md={6}>
                <Select
                  data={academicYearField}
                  id={academicYearField?.field_id}
                  formFieldValue={formFieldValue}
                  setFormFieldValue={setFormFieldValue}></Select>
              </Col>
            )}
            {yearGroupField && (
              <Col md={6}>
                <Select
                  data={yearGroupField}
                  id={yearGroupField?.field_id}
                  formFieldValue={formFieldValue}
                  setFormFieldValue={setFormFieldValue}></Select>
              </Col>
            )}
          </Row>
          {noBlockError && (
            <div class='alert alert-danger' role='alert'>
              Sorry, blocks not available!
            </div>
          )}
          {tierData?.length > 0 && <div className='my-4 body1 font-weight-bold'>Blocks</div>}

          {tierData?.length > 0 && (
            <Row>
              <Col md={12}>
                <div className='d-flex'>
                  {tierData?.map((item, i) => {
                    return (
                      <div className='my-3 mr-4' style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                        <input
                          checked={item?.checked}
                          type='checkbox'
                          id='tier2'
                          className='subtitle1'
                          onChange={(e) => {
                            onChangeTier(e.target.checked, i)
                            // setFormFieldValue({ ...formFieldValue, tier2: e.target.checked })
                          }}
                        />
                        <div>{item?.label}</div>
                      </div>
                    )
                  })}
                </div>
              </Col>
              {blocks?.map((item, index) => {
                return (
                  <Col md={6}>
                    <AvField
                      name={item?.id + 'location'}
                      value={item?.count}
                      type='number'
                      max={item?.total_items - item?.total_booked_items}
                      label={`${item?.location_name} (${item?.total_booked_items}/${item?.total_items})`}
                      onChange={(e) => {
                        if (parseInt(e.target.value) && parseInt(e.target.value) > item?.total_items - item?.total_booked_items) {
                          dispatch(setNotification({ message: 'Number of locker exceeded', type: 'error' }))
                          setBlocks(JSON.parse(JSON.stringify(blocks)))
                          return
                        }
                        const newBlocks = blocks
                        newBlocks[index]['count'] = e.target.value
                        setBlocks(newBlocks)
                      }}></AvField>
                  </Col>
                )
              })}
            </Row>
          )}
          {blocks?.length > 0 && (
            <>
              {' '}
              <div className='my-4 body1 font-weight-bold'>Assign</div>
              <Row>
                <Col md={12}>
                  {assignGroupField && (
                    <Select
                      data={assignGroupField}
                      id={assignGroupField?.field_id}
                      formFieldValue={formFieldValue}
                      setFormFieldValue={setFormFieldValue}
                      noCache={true}></Select>
                  )}
                </Col>
                <Col md={6}>
                  <AvField
                    name='first_name'
                    label='First Name'
                    value={formFieldValue?.first_name}
                    onChange={(e) => {
                      setFormFieldValue((data) => {
                        return {
                          ...data,
                          first_name: e.target.value,
                        }
                      })
                    }}></AvField>
                </Col>
                <Col md={6}>
                  <AvField
                    name='last_name'
                    value={formFieldValue?.last_name}
                    label='Last Name'
                    onChange={(e) => {
                      setFormFieldValue((data) => {
                        return {
                          ...data,
                          last_name: e.target.value,
                        }
                      })
                    }}></AvField>
                </Col>
              </Row>
            </>
          )}

          <button
            className='btn text-white rounded-pill mt-2'
            type='button'
            onClick={(e) => {
              onPreviewLocker()
            }}
            disabled={noBlockError || previewLocker?.length > 0}
            style={{ background: primary_color, fontWeight: 700, width: '8rem' }}>
            {!loader?.preview ? 'Preview' : 'loading....'}
          </button>
          <div className='my-5' style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <input
              checked={formFieldValue?.send_confirmation_mail}
              type='checkbox'
              id='emailCheckBox'
              className='subtitle1'
              onChange={(e) => {
                setFormFieldValue({ ...formFieldValue, send_confirmation_mail: e.target.checked })
              }}
            />
            <label for='emailCheckBox' className='font-weight-bold mt-2 subtitle1' style={{ opacity: 0.7 }}>
              Send Confirmation Mail
            </label>
          </div>
          {previewLocker?.length > 0 && (
            <ReservedLockerTable
              previewLocker={previewLocker}
              setPreviewLocker={setPreviewLocker}
              setChangeLocker={setChangeLocker}></ReservedLockerTable>
          )}

          <div className='my-5 d-flex justify-content-end'>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger mr-3'
              type='button'
              id='close-btn'
              onClick={() => {
                onCloseForm()
              }}
              style={{ fontWeight: 700, width: '8rem' }}>
              Cancel
            </button>
            <button
              className='btn text-white rounded-pill mt-2'
              type='button'
              disabled={noBlockError}
              onClick={(e) => {
                onPreviewLocker(true)
              }}
              style={{ background: primary_color, fontWeight: 700, width: '8rem' }}>
              {!loader?.save ? 'Save' : 'Saving...'}
            </button>
          </div>
        </AvForm>
      </div>
    </div>
  )
}

export default PupilPremiumForm
