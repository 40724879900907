import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FileUploader } from 'react-drag-drop-files'
import { useHistory } from 'react-router-dom'

import city3 from '../../assets/utils/images/dropdown-header/city3.jpg'
import { can, cms, editCMS, getColor } from '../../helper'
import PageTitle from '../../Layout/AppMain/pageTitle'
import { clearAllRowClearedStatus } from '../../Redux/Reducers/CMS'
import { SetTab } from '../../Redux/Reducers/page'
import AddGridData from '../Grid/Component/AddGridData/addGridData'
import IconSet from '../icon'
import PageContent from '../pageContent'

import QuestionnaireMain from '../Questionnaire/questionnaireMain'
import Tabs from '../tabs'
import NoData from './noData'
import { request } from '../../Redux/Sagas/requests/api'
import { setUserProfilePic } from '../../Redux/Reducers/user'
import Dashboard from '../../Components/PageSpecificComponents/Dashboard/dashboard'
import PermissionTab from '../../Components/Permissions/PermissionTab'
import { primary_color } from '../../Helper/uiHelper'
import PageHeader from '../../Components/Permissions/PageHeader'

const select = (state) => ({
  isPageFull: state.Page.isPageFull,
  cmsData: state.CMS.cmsData,
  coreData: state.CMS.coreData,
  allRowCleared: state.CMS.allRowCleared,
  editPermissionByPage: state.CMS.editPermissionByPage,
})

const PageWithTabs = ({
  page,
  CMSData,
  isPageFull = false,
  setBigDropdown = () => {},
  disableBG,
  gridData = {},
  dropdownData = false,
  grid = false,
  isModel = false,
  coreData,
  isModalAction,
  allRowCleared,
  editPermissionByPage,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const userData = useSelector((state) => state.User.user)
  const enabledOrDisabledMethodTab = useSelector((state) => state.CMS.enabledOrDisabledMethodTab)

  const [content, updateContent] = useState([])
  const [fullPage, updateFullPage] = useState(false)
  const [hiddenContent, updateHiddenContent] = useState([])

  const [addDataDetail, setAddDataDetail] = useState({ open: false, detail: null })

  const [questionnaireAction, setQuestionnaireAction] = useState({ open: false })

  const questionnaire = questionnaireAction?.detail

  useEffect(() => {
    if (allRowCleared) {
      setAddDataDetail({ open: false, detail: null })
      dispatch(clearAllRowClearedStatus())
    }
  }, [allRowCleared])

  useEffect(() => {
    updateFullPage(isPageFull)
    dispatch(SetTab(0))
    // PATCH
    // if (grid) { // real code
    if (grid) {
      let filterContent = []
      let filterhiddenContent = []
      if (page.component_call_buttons) {
        page.component_call_buttons.map((item) => {
          if (item.component_name === 'show_tabs_on_load') {
            filterContent.push(item)
          } else if (item.component_name === 'depend_tab') {
            if (!enabledOrDisabledMethodTab?.includes(item?.source_table)) {
              filterContent.push(item)
            }
          } else {
            filterhiddenContent.push(item)
          }
        })
      }
      updateHiddenContent(filterhiddenContent)
      updateContent(filterContent)
    } else {
      updateContent(page.component_call_buttons)
    }
    setBigDropdown(page?.component_call_buttons?.length > 1)
  }, [isPageFull, page, questionnaire, enabledOrDisabledMethodTab])

  const { cms_header_key, cms_header_name, cms_sub_header_key, cms_sub_header_name, icon, component_name, color, icon_type } = page
       
  if (page.permission === 'view_draw' || page.cms_header_name?.includes('download')) {
  
    return (
      <Tabs
        content={content}
        pageSize={page.style}
        CMSData={CMSData}
        pageName={page.name}
        fullPage={page.style === 'full_page'}
        isModel={isModel}
        gridData={gridData}
      />
    )
  }
  if (questionnaire && questionnaireAction?.open) {
    return <QuestionnaireMain questionnaireDetail={questionnaire} setQuestionnaireAction={setQuestionnaireAction} />
  }

  if (addDataDetail?.open && (fullPage || isModel)) {
    return <AddGridData addDataDetail={addDataDetail} setAddGridDataDetail={setAddDataDetail} isModel={isModel} />
  }

  // return <ItemsReportsPreview/>

  if (page?.isDashboardPage) return <Dashboard />
  if (fullPage && !isModel && !addDataDetail?.open) {
    return (
      <>

        <PageTitle
          onTitleClick={() => editCMS(cms_header_key || cms_header_name)}
          onSubTitleClick={() => editCMS(cms_sub_header_key || cms_sub_header_name)}
          heading={cms(cms_header_key || cms_header_name)}
          subheading={cms(cms_sub_header_key || cms_sub_header_name)}
          icon={icon}
          component_name={component_name}
          hideBreadCrumbs
        />
        {/* {!window.location.pathname?.includes('permission') && (
          <PageHeader
            onTitleClick={() => editCMS(cms_header_key || cms_header_name)}
            onSubTitleClick={() => editCMS(cms_sub_header_key || cms_sub_header_name)}
            text={cms(cms_header_key || cms_header_name)}
            icon={icon}
          />
        )} */}

        {!window.location.pathname?.includes('permission') && editPermissionByPage && (
          <div className='my-5'>
            <div className='d-flex justify-content-between align-items-center mb-4'>
              <div className='h5 font-weight-bold' style={{ color: primary_color }}>
                Permissions
              </div>
              <div>
                <Button
                  className={` poppin d-flex align-items-center justify-content-center mb-2 `}
                  style={{
                    color: 'white',
                    border: `2px solid ${primary_color}`,
                    // maxWidth: isDesktop ? 360 : '100%',
                    // height: isDesktop ? 56 : 48,
                    backgroundColor: primary_color,
                    borderRadius: 30,
                    width: 'fit-content',
                    padding: '0.5rem 3.5rem',
                  }}
                  onClick={() => {
                    history.push('/permissions-list')
                  }}>
                  <span className='mr-2' style={{ fontWeight: 600 }}>
                    View All Permission
                  </span>
                  <i className={`fa-solid fa-arrow-right ml-2 body2 font-weight-bold`}></i>
                </Button>
              </div>
            </div>
            <PermissionTab isSinglePage={true} pageName={window.location.pathname.replace('-list', '')?.replace('/', '')} />
          </div>
        )}
        <PageContent
          pageContent={hiddenContent}
          tabContent={content}
          parentPageName={page.name}
          addDataDetail={addDataDetail}
          setAddDataDetail={setAddDataDetail}
          setQuestionnaireAction={setQuestionnaireAction}
          questionnaireAction={questionnaireAction}
          pageData={page}
        />
      </>
    )
  }

  if (page.allowed && can(page.permission)) {
    return (
      <>
        {dropdownData &&
          (page.button_actions === 'logout' ? (
            <div className='dropdown-menu-header'>
              <div className='dropdown-menu-header-inner bg-info'>
                <div
                  className='menu-header-image opacity-2'
                  style={{
                    backgroundImage: 'url(' + city3 + ')',
                  }}
                />
                <div className='menu-header-content text-left'>
                  <div className='widget-content p-0'>
                    <div className='widget-content-wrapper'>
                      <div className='widget-content-left mr-3'>
                        {page?.name === 'profiles' ? (
                          <ProfileAvatar icon_type={icon_type} icon={icon} color={color} user={userData} />
                        ) : (
                          <IconSet
                            type={icon_type}
                            icon={icon}
                            style={{ color: getColor(color), fontSize: '3rem' }}
                            color={color}
                            navheadericon='true'
                          />
                        )}
                      </div>
                      <div className='widget-content-left'>
                        <div className='widget-heading' onClick={() => editCMS(cms_header_name)}>
                          {cms(cms_header_name)}
                        </div>
                        <div className='widget-subheading opacity-8' onClick={() => editCMS(cms_header_name)}>
                          {cms(cms_sub_header_name)}
                        </div>
                      </div>
                      <div className='widget-content-right mr-2'>
                        <Link to='/logout'>
                          <Button className='btn-pill btn-shadow btn-shine' color='focus'>
                            Logout
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='dropdown-menu-header'>
              <div className={disableBG ? 'dropdown-menu-header-inner' : 'dropdown-menu-header-inner bg-deep-blue'}>
                {!disableBG && (
                  <div
                    className='menu-header-image opacity-1'
                    style={{
                      backgroundImage: 'url(' + city3 + ')',
                    }}
                  />
                )}

                <div className='menu-header-content text-dark'>
                  <h5 className='menu-header-title' onClick={() => editCMS(page.cms_header_name)}>
                    {cms(page.cms_header_name)}
                  </h5>
                  <h6 className='menu-header-subtitle' onClick={() => editCMS(page.cms_sub_header_name)}>
                    {cms(page.cms_sub_header_name)}
                  </h6>
                </div>
              </div>
            </div>
          ))}
        {/* <PageHeader
          onTitleClick={() => editCMS(cms_header_key || cms_header_name)}
          onSubTitleClick={() => editCMS(cms_sub_header_key || cms_sub_header_name)}
          text={cms(cms_header_key || cms_header_name)}
          icon={icon}
        /> */}
        <Tabs
          content={content}
          pageSize={page.style}
          CMSData={CMSData}
          pageName={page.name}
          fullPage={page.style === 'full_page'}
          isModel={isModel}
          gridData={gridData}
          addDataDetail={addDataDetail}
          setAddDataDetail={setAddDataDetail}
          isModalAction={isModalAction} //Modal component
        />
      </>
    )
  } else {
    return <NoData message={page.not_allowed_message ? page.not_allowed_message : 'You do not have permission to see this.'} />
  }
}

const ProfileAvatar = ({ icon_type, icon, color, user }) => {
  const [openModal, setOpenModal] = useState(false)
  const fileTypes = ['JPG', 'JPEG', 'GIF', 'PNG']

  const APP_URL = process.env.REACT_APP_URL

  const [file, setFile] = useState()
  const [preview, setPreview] = useState()
  useEffect(() => {
    if (!file && !(user.photo && user.photo.length > 0)) {
      setPreview(undefined)
      return
    } else if (user.photo && user.photo.length > 0 && !file) {
      setPreview(`${APP_URL}${user.photo}`)
      return
    }
    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [file, openModal])

  const handleChange = (image) => {
    setFile(image)
  }
  const toggleModal = () => {
    setOpenModal(!openModal)
    setFile()
  }
  const dispatch = useDispatch()

  const onSaveProfilePic = () => {
    const formData = new FormData()
    // formData.append("domain_name",data.domain_name)
    // formData.append("logo_default",data.logo_default)
    formData.append('file', file)
    if (file) {
      request('file-upload/profile', 'POST', formData).then((res) => {
        if (res.status === 202 || res.status === 200) {
          dispatch(setUserProfilePic(res.data.path))
        }

        // dispatch(fetchUser())
        toggleModal()
      })
    } else {
      request('file-upload/profile', 'POST').then((res) => {
        if (res.status === 202 || res.status === 200) {
          dispatch(setUserProfilePic(res.data.path))
        }
        // dispatch(fetchUser())
        toggleModal()
      })
    }
  }

  const onRemove = () => {
    setFile()
    setPreview()
  }
  return (
    <>
      {user.photo && user.photo?.length > 0 ? (
        <img
          src={APP_URL + '/' + user.photo}
          style={{ width: '3rem', height: '3rem', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}
          onClick={() => setOpenModal(true)}></img>
      ) : (
        <IconSet
          type={icon_type}
          icon={icon}
          style={{ color: getColor(color), fontSize: '3rem' }}
          color={color}
          navheadericon='true'
          onClick={() => setOpenModal(true)}
        />
      )}

      <Modal isOpen={openModal} toggle={() => toggleModal()} style={{ width: 500 }}>
        <ModalHeader toggle={() => toggleModal()}>Update Profile Image</ModalHeader>
        <ModalBody>
          <div>
            {preview && (
              <img src={preview} style={{ height: '5rem', width: '5rem', borderRadius: '50%', marginBottom: '1rem', objectFit: 'cover' }}></img>
            )}
          </div>
          <FileUploader handleChange={handleChange} name='file' types={fileTypes} multiple={false} fileOrFiles={file} />
        </ModalBody>
        <ModalFooter>
          {}
          <Button onClick={() => onRemove()}>Remove</Button>
          <Button color='primary' onClick={() => onSaveProfilePic()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default connect(select)(PageWithTabs)
