import { useEffect, useState } from 'react'
import { tidyName } from '../../../helper'
import RCTabs from '../../Tabs/rcTabs'
import BlockMainDetails from './blockMainDetails'
import BlockRates from './blockRates'
import ManageLocker from './manageLocker'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import Loader from 'react-loaders'
import { primary_color } from '../../../Helper/uiHelper'
import BlockMaintanance from './blockMaintanance'
import { clearCurrentRowData, clearParentTableData, fetchUpdateApiData } from '../../../Redux/Reducers/CMS'
import CodesTable from './codes/codeTable'
import GoBackButton from './goBackButton'

const getTabTitle = (title, item, details) => {
  if (item?.name === 'main_details') {
    return (
      <div>
        {!details?.id ? (
          <div className='subtitle1'>
            {title}{' '}
            <span className='bg-danger ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-xmark '></i>
            </span>
          </div>
        ) : (
          <div className='subtitle1'>
            {title}{' '}
            <span className='bg-success ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-check '></i>
            </span>
          </div>
        )}
      </div>
    )
  } else if (item?.name === 'lockers') {
    return (
      <div className='subtitle1'>
        <div className='subtitle1'>
          {title}{' '}
          <span className='bg-info ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px', color: '#fff' }}>
            {details?.lockers?.length}
          </span>
        </div>
      </div>
    )
  } else if (item?.name === 'rates') {
    return (
      <div>
        {!details?.is_all_tier_price ? (
          <div className='subtitle1'>
            {title}{' '}
            <span className='bg-danger ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-xmark '></i>
            </span>
          </div>
        ) : (
          <div className='subtitle1'>
            {title}{' '}
            <span className='bg-success ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px' }}>
              <i class='fa-solid fa-check '></i>
            </span>
          </div>
        )}
      </div>
    )
  } else if (item?.name === 'maintenance') {
    const maintainanceLocker = details?.lockers?.filter((item) => item?.is_maintenance || item?.is_maintainence)
    return (
      <div className='subtitle1'>
        <div className='subtitle1'>
          {title}{' '}
          <span className='bg-info ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px', color: '#fff' }}>
            {maintainanceLocker?.length}
          </span>
        </div>
      </div>
    )
  } else if (item?.name === 'codes') {
    return (
      <div className='subtitle1'>
        {title}{' '}
        {/* <span className='bg-danger ml-2 subtitle1' style={{ padding: '1px 10px', borderRadius: '20px' }}>
          <i class='fa-solid fa-xmark '></i>
        </span> */}
      </div>
    )
  }
}

const getTabs = (tabs, blockDetails, setBlocketails, onCloseForm, isPlannedBlock, isTemplateImported, setIsTemplateImported) => {
  return tabs?.map((item, index) => {
    if (!blockDetails?.id && index > 0) return
    return {
      title: getTabTitle(tidyName(item?.name), item, blockDetails),
      getContent: () => {
        switch (item?.template_name) {
          case 'planned_block_item':
          case 'active_block_item':
            return (
              <ManageLocker
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                onCloseForm={onCloseForm}
                isPlannedBlock={isPlannedBlock}></ManageLocker>
            )
          case 'planned_block_rates':
          case 'active_block_rates':
            return (
              <BlockRates
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                form={item}
                onCloseForm={onCloseForm}
                isPlannedBlock={isPlannedBlock}></BlockRates>
            )
          case 'planned_block_main_details':
          case 'active_block_main_details':
            return (
              <BlockMainDetails
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                form={item}
                onCloseForm={onCloseForm}
                isPlannedBlock={isPlannedBlock}
                isTemplateImported={isTemplateImported}
                setIsTemplateImported={setIsTemplateImported}></BlockMainDetails>
            )
          case 'planned_block_maintanance':
          case 'active_block_maintanance':
            return (
              <BlockMaintanance
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                form={item}
                onCloseForm={onCloseForm}
                isPlannedBlock={isPlannedBlock}></BlockMaintanance>
            )
          case 'active_block_code':
            return <CodesTable blockDetails={blockDetails} setBlocketails={setBlocketails} form={item} isPlannedBlock={isPlannedBlock}></CodesTable>
        }
      },
    }
  })
}

const PlannedBlockTabs = ({ addFormList, addDataDetail, setAddGridDataDetail, isPlannedBlock }) => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [blockDetails, setBlocketails] = useState()
  const [tabs, setTabs] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [errorInBlockDetails, setErrorInBlockDetails] = useState(false)
  const [isTemplateImported, setIsTemplateImported] = useState(false)

  useEffect(() => {
    const parentElement = document.getElementById('child-page-1')
    const breadcrumb = document.getElementById('tab-breadcrumb')
    if (parentElement) {
      const childElement = parentElement.querySelector('.rc-tabs-bar')
      childElement.style.display = 'none'
    }
    if (breadcrumb) {
      breadcrumb.style.display = 'none'
    }

    return () => {
      const parentElement = document.getElementById('child-page-1')
      if (parentElement) {
        const childElement = parentElement.querySelector('.rc-tabs-bar')
        childElement.style.display = 'block'
      }
      const breadcrumb = document.getElementById('tab-breadcrumb')
      if (breadcrumb) {
        breadcrumb.style.display = 'block'
      }
    }
  }, [])

  useEffect(() => {
    if (!addDataDetail?.isAddNew) {
      request('get-location-data', 'POST', { location_id: currentRow?.[1]?.id, is_planned_block: isPlannedBlock }).then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
          setBlocketails(res?.data)
        } else {
          setErrorInBlockDetails(true)
        }
      })
    }
    return () => {
      dispatch(fetchUpdateApiData())
    }
  }, [currentRow?.length])

  const onCloseForm = () => {
    dispatch(fetchUpdateApiData())
    if (!addDataDetail?.isAddNew) {
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
    }

    setAddGridDataDetail({ open: false })
  }

  useEffect(() => {
    const tabsData = getTabs(addFormList, blockDetails, setBlocketails, onCloseForm, isPlannedBlock, isTemplateImported, setIsTemplateImported)
    setTabs(tabsData)
  }, [addFormList, blockDetails])

  if (errorInBlockDetails) return <div class='alert alert-danger mt-5'>Error while fetching block details. Please try after sometime</div>
  if (!addDataDetail?.isAddNew && !blockDetails?.id)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )
  return (
    <div className='p-3'>
      <div className='mt-2 mb-5 d-flex align-items-center'>
        <div className='subtitle1 mr-3'>Schools</div>
        <div className='subtitle1 mr-3'>&gt;</div>
        <div
          className='subtitle1 mr-3 cursor-pointer'
          onClick={() => {
            onCloseForm()
          }}>
          {currentRow?.[0]?.domain_name}
        </div>
        <div className='subtitle1 mr-3'>&gt;</div>
        <div className='subtitle1 mr-3 cursor-pointer'>
          {addDataDetail?.tableGrid?.source_table === 'locations' ? 'Edit block' : 'Edit Planned block'}
        </div>
        <div className='subtitle1 mr-3'>&gt;</div>
        <div className='subtitle1 mr-3 cursor-pointer'>{blockDetails?.name}</div>
      </div>
      <GoBackButton onCloseForm={onCloseForm}></GoBackButton>
      <div className='mt-3'>
        <RCTabs
          items={tabs}
          selectedTabKey={activeTab || 0}
          activeTab={activeTab}
          onChange={(e) => {
            setActiveTab(parseInt(e))
          }}
          hideBreadcrumb={true}
        />
      </div>
    </div>
  )
}

export default PlannedBlockTabs
