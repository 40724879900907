import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Card, CardBody, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import cx from 'classnames'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import Icon from '../../icon'
import ConfirmButton from '../../Common/confirmButton'
import { removeItemByName, updateNavGroupRedux } from '../../../Redux/Reducers/CMS'
import AddNavGroup from './addNavGroup'
import AddNavLink from './addNavLink'
import { cms } from '../../../helper'
import OrderSideNavBar from './orderSideNavBar'
import { setLoader } from '../../../Redux/Reducers/loader'
import { request } from '../../../Redux/Sagas/requests/api'
import { reduxLoad } from '../../../Redux/Reducers'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  nav: state.CMS.coreData.nav,
  sideBarOpen: state.StaticThemeOptions.enableClosedSidebar,
})

const EditSidebarNav = ({ editPageMode, nav, newNav = false, sideBarOpen }) => {
  const dispatch = useDispatch()
  const [modalValue, setModal] = useState(false)
  const [isOrdering, setIsOrdering] = useState(false)
  const [group, setGroup] = useState()
  const [orderedGroup, setOrderedGroup] = useState()

  useEffect(() => {
    const groupArray = nav
      ?.filter((item) => item !== 'heading.menu')
      ?.map((item, index) => {
        return {
          name: item?.name,
          id: index,
        }
      })
    setGroup(groupArray)
  }, [nav])

  const submitNavOrdering = () => {
    if (orderedGroup) {
      dispatch(setLoader(true))
      request('submit-nav-order', 'POST', { data: orderedGroup }).then((res) => {
        dispatch(setLoader(false))
        dispatch(reduxLoad())
        setIsOrdering(false)
      })
    }
  }
  const renderSection = () => {
    if (nav && nav.length === 0) {
      return (
        <div className='text-center'>
          <AddNavGroup newNav={newNav} type='section' />
        </div>
      )
    }

    return nav?.map((item, key) => (
      <React.Fragment key={key}>
        {Object.prototype.toString.call(item) === '[object String]' ? (
          <ListGroupItem key={key}>
            {cms(item)} (heading)
            <AddNavGroup section={item} newNav={newNav} />{' '}
            <ConfirmButton
              style={{ padding: 0 }}
              color='link'
              id={item.name}
              clickEvent={() => dispatch(removeItemByName({ name: item, type: 'nav-section' }))}>
              <Icon icon={'fa-solid fa-xmark'} color='danger' />
            </ConfirmButton>
          </ListGroupItem>
        ) : (
          renderGroup(item, key)
        )}
      </React.Fragment>
    ))
  }

  const renderGroup = (list, key = 0) => {
    if (list.length > 0) {
      return list.map((item, key) => {
        if (item.cms) {
          return renderLink(item, key)
        } else {
          return (
            <React.Fragment key={key}>
              <ListGroupItem className='ml-3' key={key}>
                {cms(item.name)} (group) <AddNavLink section={item.name} newNav={newNav} /> <AddNavGroup section={item.name} />
                <ConfirmButton
                  style={{ padding: 0 }}
                  color='link'
                  id={item.name.split('.').join('_')}
                  clickEvent={() => dispatch(removeItemByName({ name: item.name, type: 'nav-group' }))}>
                  <Icon icon={'fa-solid fa-xmark'} color='danger' />
                </ConfirmButton>
              </ListGroupItem>
              {item.items.length > 0 && renderGroup(item.items)}
            </React.Fragment>
          )
        }
      })
    } else {
      if (list.cms) {
        return renderLink(list, key)
      } else {
        return (
          <React.Fragment key={key}>
            <ListGroupItem className='ml-3' key={key}>
              {cms(list.name)} (group)
              <AddNavLink section={list.name} newNav={newNav} /> <AddNavGroup section={list.name} />
              <ConfirmButton
                style={{ padding: 0 }}
                color='link'
                id={list.name.split('.').join('_')}
                clickEvent={() => dispatch(removeItemByName({ name: list.name, type: 'nav-group' }))}>
                <Icon icon={'fa-solid fa-xmark'} color='danger' />
              </ConfirmButton>
            </ListGroupItem>
            {list.items.length > 0 && renderGroup(list.items)}
          </React.Fragment>
        )
      }
    }
  }
  const onChangeMainPage = (page, path, checked) => {
    if (!checked) return
    const newNav = nav?.map((group) => {
      if (typeof group === 'string') {
        return group
      } else {
        let items = group?.items?.map((item) => {
          if (item.page === page) {
            return {
              ...item,
              main_page: parseInt(item?.main_page) ? 0 : 1,
            }
          } else {
            return {
              ...item,
              main_page: 0,
            }
          }
        })
        let newGroup = {
          ...group,
          items: items,
        }
        return newGroup
      }
    })
    dispatch(updateNavGroupRedux(newNav))
    request('update-main-page', 'POST', { page, path })
  }

  const renderLink = (list, key = 0) => {
    return (
      <React.Fragment key={key}>
        <ListGroupItem className='ml-5' key={key}>
          {cms(list.cms)} (link)
          <ConfirmButton
            style={{ padding: 0 }}
            color='link'
            id={list.cms.split('.').join('_')}
            clickEvent={() => dispatch(removeItemByName({ name: list.cms, type: 'nav-link' }))}>
            <Icon icon={'fa-solid fa-xmark'} color='danger' />
          </ConfirmButton>
          <div
            className='switch has-switch mb-2 mr-2 float-right'
            data-on-label='ON'
            data-off-label='OFF'
            onClick={() => onChangeMainPage(list?.page, list?.path, !list?.main_page)}>
            <div
              className={cx('switch-animate', {
                'switch-on': parseInt(list?.main_page),
                'switch-off': !parseInt(list?.main_page),
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-success'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-success'>OFF</span>
            </div>
          </div>
        </ListGroupItem>
        {list.items && renderGroup(list.items)}
      </React.Fragment>
    )
  }

  useEffect(() => {}, [])

  if (!editPageMode && !newNav) return null
  return (
    <>
      {!sideBarOpen && !newNav && (
        <Button onClick={() => setModal(true)} color='info' className='sidebar-nav-edit-button'>
          <Icon icon={'fas fa-pen'} color='light' /> {newNav ? 'Create' : 'Edit'} Sidebar Nav
        </Button>
      )}
      {newNav && (
        <CSSTransitionGroup
          component='div'
          transitionName='TabsAnimation'
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row style={{ marginTop: '13vh' }}>
            <Col md='12'>
              <Card className='main-card mb-3'>
                <CardBody>
                  <div className='text-center'>
                    <div className='font-10rem'>
                      <div className='mx-auto mb-0 font-size-lg text-capitalize font-weight-normal card-title'>No page or data found!</div>
                      <i className='fa-solid fa-face-frown-open icon-gradient bg-mean-fruit' />
                    </div>
                    {editPageMode && (
                      <Button onClick={() => setModal(true)} color='info' className='sidebar-nav-edit-button'>
                        <Icon icon={'fas fa-pen'} color='light' /> Create Page
                      </Button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      )}

      <Modal isOpen={modalValue} toggle={() => setModal(!modalValue)} style={{ width: 800 }}>
        <ModalHeader toggle={() => setModal(!modalValue)}>Sidebar Nav</ModalHeader>
        <ModalBody>
          <Container>
            {group?.length > 0 && isOrdering && (
              <Button className='btn btn-success float left m-2' onClick={() => submitNavOrdering()}>
                Confirm Order
              </Button>
            )}
            {group?.length > 0 && (
              <Button
                className='btn btn-info float right m-2'
                onClick={() => {
                  setIsOrdering(!isOrdering)
                }}>
                {isOrdering ? 'Disable' : 'Enable'} Order
              </Button>
            )}
            {isOrdering ? (
              <OrderSideNavBar
                nav={nav}
                setIsOrdering={setIsOrdering}
                isOrdering={isOrdering}
                group={group}
                setGroup={setGroup}
                setOrderedGroup={setOrderedGroup}
              />
            ) : (
              <ListGroup>{renderSection()}</ListGroup>
            )}

            {/*  */}
          </Container>
        </ModalBody>
      </Modal>
    </>
  )
}

export default connect(select)(EditSidebarNav)
