import moment from 'moment'

import { getTabNameForLogs } from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        {updated_data?.map((item) => {
          return (
            <div className='px-5 py-2'>
              <div className='mb-3'>
                <div className='font-weight-bold body2' style={{ color: primary_color }}>
                  Location
                </div>
                <div className='subtitle1'>{item?.location?.name}</div>
              </div>
              <div className='mb-3'>
                <div className='font-weight-bold body2' style={{ color: primary_color }}>
                  Locker Number
                </div>
                <div className='subtitle1'>{item?.item?.item_name}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const LockerMaintananceLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data ? JSON.parse(data?.updated_data) : {}
  let original_data = data?.original_data ? JSON.parse(data?.original_data) : {}

  if (expandedId) {
    return (
      <ShowDifference
        data={data}
        onExpand={onExpand}
        updated_data={updated_data && JSON.parse(updated_data)}
        original_data={original_data ? JSON.parse(original_data) : {}}></ShowDifference>
    )
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has updated in maintanance`} <span className='font-weight-bold'>{JSON.parse(original_data)?.name}</span>
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default LockerMaintananceLog
