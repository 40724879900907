import { useState } from 'react'
import { displayVertically } from './manageLocker'
import LockerListing from './lockerListing'
import { Button } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const RestoreLocker = ({ setIsRestoringLocker, blockDetails, setBlocketails, isPlannedBlock }) => {
  const dispatch = useDispatch()
  const [selectedLockers, setSelectedLockers] = useState([])
  const [selectedLockerIds, setSelectedLockerIds] = useState([])
  const [isRestoring, setIsRestoring] = useState(false)
  const lockers = displayVertically(blockDetails?.deleted_lockers, 1)

  const onRestoreLocker = () => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      items: selectedLockerIds?.map((item) => {
        return {
          item_id: item,
        }
      }),
      is_restore: true,
      is_planned_block: isPlannedBlock,
    }
    setIsRestoring(true)
    request('delete-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id, is_planned_block: isPlannedBlock }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setIsRestoring(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setIsRestoring(false)
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }
  return (
    <div className='mt-5'>
      <div className='mb-4'>
        <Button
          color='dark'
          onClick={() => {
            setIsRestoringLocker(false)
          }}>
          <i class='fa-solid fa-arrow-left'></i>
        </Button>
      </div>
      <div className='font-weight-bold subtitle1 mb-4' style={{ color: '#8E8E8E' }}>
        Select the Lockers to restore
      </div>
      <button
        className='btn text-white  mb-5 '
        disabled={selectedLockerIds?.length === 0}
        type='submit'
        onClick={() => {
          onRestoreLocker()
        }}
        style={{ background: primary_color, fontWeight: 700 }}>
        {isRestoring ? 'Restoring....' : 'Restore Selected Locker'}
      </button>
      {blockDetails?.deleted_lockers?.length > 0 && (
        <LockerListing
          lockers={lockers}
          isLockerSelectable={true}
          setSelectedLockerIds={setSelectedLockerIds}
          selectedLockerIds={selectedLockerIds}
          setSelectedLockers={setSelectedLockers}
          selectedLockers={selectedLockers}
          isSelectSingleLocker={false}
          hideAddRemoveColumn={true}></LockerListing>
      )}
    </div>
  )
}

export default RestoreLocker
