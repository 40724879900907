import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logTime, resetPublicUser, setDefaultUrl, setIsAuth } from '../../Redux/Reducers/user'
import { reduxLoad } from '../../Redux/Reducers'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { setCookie } from '../../Helper/cookieHelper'
import { getNavFilterList, tidyName } from '../../helper'

const NavLinks = ({ data, isSidebar = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const publicUser = useSelector((state) => state.User.publicUser)
  const Navigations = useSelector((state) => state.Navigations)
  const isDesktop = useMediaQuery('(min-width:768px)')
  const [hover, setHover] = useState('')

  const iconStyle = (name, route) => {
    return {
      fontSize: hover === name ? '1.4rem' : '1.2rem',
      width: isSidebar ? '2rem' : '5rem',
      color: hover === name || window.location.pathname === '/' + route ? primary_color : '#8E8E8E',
      transition: 'font-size 0.3s ease',
    }
  }

  const onClickIcon = (item) => {
    if (item?.action === 'redirect-to-cms') {
      
      history.push('/redirect-to-dashboard')
      return
    }
    if (item?.action === 'logout') {
      dispatch(resetPublicUser())
      setCookie('AUTH_TOKEN')
      return
    }
    if (item?.route) {
      history.push(item?.route)
    }
  }

  const nameStyle = {
    fontSize: '10px',
    position: 'absolute',
    bottom: '-15px',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease', // Add transition property for opacity
    // Set opacity based on hover
  }

  const current_tab = 'Home'

  let navigationData = []
  if (data) {
    navigationData = [...data]
  }
  if (publicUser?.email) {
    navigationData?.push({
      name: 'dashboard',
      icon: 'fa-solid fa-user',
      action: 'redirect-to-cms',
      display: 1,
    })
  }

  return navigationData?.map((nav, index) => {
    // if (nav.isLoginRequired && !publicUser?.email) return
    // if (nav.isOnlyMobile && isDesktop) return
    if (!nav?.display) return
    return (
      <div
        key={index}
        style={{ textAlign: 'center', position: 'relative', cursor: 'pointer', cursor: 'pointer', position: 'relative' }}
        onMouseOver={() => {
          setHover(nav.icon)
        }}
        onMouseOut={() => setHover(false)}
        onClick={() => {
          if (nav.action || nav.route) {
            onClickIcon(nav)
          }
        }}>
        {isSidebar ? (
          <div
            className='d-flex pb-2 align-items-center mt-3'
            style={{ gap: '0.75rem', color: window.location.pathname === '/' + nav?.route ? primary_color : '#8E8E8E' }}>
            <i className={nav.icon} style={{ ...iconStyle(nav.icon, nav.route) }} />
            <div>{tidyName(nav.name)}</div>
          </div>
        ) : (
          <>
            <i className={nav.icon} style={{ ...iconStyle(nav.icon, nav.route) }} />
            <div
              key={index}
              className='line-clamp-1'
              style={{
                ...nameStyle,
                visibility: hover === nav.icon ? 'visible' : 'hidden', // Show/hide the name based on hover
                opacity: hover === nav.icon ? 1 : 0,
                color: window.location.pathname === '/' + nav.route ? primary_color : '#8E8E8E',
              }}>
              {tidyName(nav.name)}
            </div>
          </>
        )}
      </div>
    )
  })
}

export default NavLinks
