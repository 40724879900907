import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { saveCustomColor } from '../../../Redux/Reducers/themeOptions'

const initialFormData = {
  uuid: '',
  name: '',
  color: '#000000',
  is_dark: '1',
  status: '1',
}
const AddCustomColor = ({ id, toggleShowCard, data }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialFormData)
  const [error, setError] = useState({})
  const [isSubmit, setIsSubmit] = useState(true)
  // const [color, setColor] = useState()
  useEffect(() => {
    if (id) {
      setFormData(data)
    }
  }, [])
  const onHandleChange = (e) => {
    // debugger
    const { name, value } = e.target
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }
  const validate = () => {
    let errorFlag = false
    if (!formData.name) {
      setError({ ...error, name: 'Please enter name' })
      errorFlag = true
    }
    return errorFlag
  }
  const onSubmit = (e) => {
    // debugger
    e.preventDefault()


    // setIsSubmit(true)
    if (!validate()) {
      const data = { ...formData }
      data.is_dark = formData.is_dark == '1' ? true : false
      dispatch(saveCustomColor(data))
      toggleShowCard()
    }
  }
  return (
    <Card>
      <CardHeader>{id ? 'Edit' : 'Add'} Custom Color</CardHeader>
      <Form onSubmit={(e) => onSubmit(e)}>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Name</Label>
                <Input name='name' value={formData.name} onChange={(e) => onHandleChange(e)} invalid={error?.name ? true : false} />
                {error?.name && <FormFeedback>{error?.name}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                {/* <div
              style={{
                width: 50,
                height: 50,
                marginTop: 20,
                backgroundColor: color.rgba,
              }}></div> */}
                <Label>Choose Color</Label>
                <Input name='color' type='color' onChange={(e) => onHandleChange(e)} value={formData.color} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Text Color</Label>
                <FormGroup>
                  <FormGroup check inline>
                    <Input
                      id='lightTextColor'
                      name='is_dark'
                      type='radio'
                      value='0'
                      checked={formData.is_dark == '0'}
                      onClick={(e) => onHandleChange(e)}
                    />
                    <Label for='lightTextColor' check>
                      Light
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      id='darkTextColor'
                      name='is_dark'
                      type='radio'
                      value='1'
                      checked={formData.is_dark == '1'}
                      onClick={(e) => onHandleChange(e)}
                    />
                    <Label for='darkTextColor' check>
                      Dark
                    </Label>
                  </FormGroup>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Status</Label>
                <FormGroup>
                  <FormGroup check inline>
                    <Input
                      id='activeStatus'
                      name='status'
                      type='radio'
                      value='1'
                      checked={formData.status == '1'}
                      onClick={(e) => onHandleChange(e)}
                    />
                    <Label for='activeStatus' check>
                      Active
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      id='inactiveStatus'
                      name='status'
                      type='radio'
                      value='0'
                      checked={formData.status == '0'}
                      onClick={(e) => onHandleChange(e)}
                    />
                    <Label for='inactiveStatus' check>
                      Inactive
                    </Label>
                  </FormGroup>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button type='submit' color='primary' className='mr-1'>
            Save
          </Button>
          <Button type='button' color='secondary' onClick={toggleShowCard}>
            Cancel
          </Button>
        </CardFooter>
      </Form>
    </Card>
  )
}

export default AddCustomColor
