import React from 'react'
import { Col, Row, Button, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'

import SliderFormWrapper from '../../Components/Form/sliderFormWrapper'

import { userRegister, setRegister } from '../../Redux/Reducers/user'
import { fetchThemeOptions } from '../../Redux/Reducers/themeOptions'
import { renderBlockColorClass, renderBlockColorStyle, renderBlockStyle, renderTextColorClass } from '../../helper'
import { primary_color } from '../../Helper/uiHelper'

const Register = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)

  const themeOptions = useSelector((state) => state.ThemeOptions)
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (themeOptions.updatedAt === null) {
      dispatch(fetchThemeOptions())
    }
  }, [])

  const enableRegisterButton = themeOptions?.['enableRegisterButton']

  let showSignup = false
  if (typeof enableRegisterButton === 'string') {
    showSignup = parseInt(enableRegisterButton)
  } else {
    showSignup = enableRegisterButton
  }

  useEffect(() => {
    if (!showSignup) {
      history?.push('/welcome/login')
    }
  }, [showSignup])

  const handleSubmit = (e, values) => {
    dispatch(setRegister({ ...values, history }))
    dispatch(userRegister())
  }
  const linkClassName = themeOptions?.primaryColor && renderTextColorClass(themeOptions?.primaryColor).split(' ')[0] + '-text btn'

  return (
    <SliderFormWrapper
      title={themeOptions?.signUpTitle || 'Sign Up'}
      titleColor={themeOptions?.authTitleColor}
      messageColor={themeOptions?.authSubTitleColor}
      message={
        <>
          {/* Create <span className='text-success'>your account</span> for web and app access */}
          {themeOptions?.signUpSubTitle}
        </>
      }>
      <AvForm onValidSubmit={(event, values) => handleSubmit(event, values)}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <AvField
                name='first_name'
                label='Firstname'
                type='text'
                validate={{
                  maxLength: { value: 100 },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField
                type='text'
                name='surname'
                label='Surname'
                validate={{
                  maxLength: { value: 100 },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField
                name='username'
                label='Username'
                type='text'
                validate={{
                  maxLength: { value: 100 },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField
                name='email'
                label='Email'
                type='email'
                validate={{
                  email: { value: true, errorMessage: 'Invalid Email' },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField name='phone_number' label='Phone Number (optional)' type='number' />
            </FormGroup>
          </Col>
          <Col md={6}>
            {/* <FormGroup>
              <AvField
                type="password"
                name="password"
                label="password"
                validate={{
                  minLength: {
                    value: 5,
                    errorMessage: "Minimum password length is 5.",
                  },
                  required: {
                    value: true,
                    errorMessage: "This field is required.",
                  },
                }}
              />
            </FormGroup> */}
            <Label>Password</Label>
            <InputGroup>
              {/* <AvField type='password' name='password' label='password' required /> */}

              <Input
                type={`${showPassword ? 'text' : 'password'}`}
                name='password'
                label='password'
                onChange={(e) => setPassword(e.target.value)}
                validate={{
                  minLength: {
                    value: 5,
                    errorMessage: 'Minimum password length is 5.',
                  },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
                required
              />
              <InputGroupAddon addonType='append' onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                <InputGroupText>{showPassword ? <i className='fa-light fa-eye-slash'></i> : <i className='fa-light fa-eye'></i>}</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <AvField type='hidden' name='password' value={password} />
          </Col>
          <Col md={6}>
            <FormGroup>
              <AvField
                type='password'
                name='password_confirmation'
                label='Confirmation Password'
                validate={{
                  match: {
                    value: 'password',
                    errorMessage: "Password doesn't match.",
                  },
                  required: {
                    value: true,
                    errorMessage: 'This field is required.',
                  },
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className='divider' />

        <Row>
          <Col md={12}>
            <div className='d-flex'>
              <div className=''>
                <Button size='lg' 
                  className='subtitle1'
                  style={{background: primary_color,border:'none'}}
                // className={renderBlockColorClass(themeOptions?.primaryColor)}
                //  style={renderBlockStyle(themeOptions?.primaryColor)}
                 >
                  Create Account
                </Button>
              </div>
            </div>
            <div className='my-4'>
              Already have an account?
              <Link to='login' className={linkClassName} style={renderBlockColorStyle(themeOptions?.primaryColor)}>
                {' '}
                Sign in
              </Link>
            </div>
          </Col>
        </Row>
      </AvForm>
    </SliderFormWrapper>
  )
}
export default Register
