import { useEffect, useState } from 'react'
import RoleBlock from './RoleBlock'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import DetailPanel from './DetailPanel'
import { primary_color } from '../../Helper/uiHelper'
import PermissionPanel from './PermissionPanel'
import 'rc-tabs/assets/index.css'
import CMSPermissionPanel from './CMSPermissionPanel'
import AddNewRoleBlock from './AddNewRoleBlock'
import PermissionFilters from './PermissionFilters'
import { request } from '../../Redux/Sagas/requests/api'
import { permissionsData } from './dummyPermission'
import { getRolesFromPermissions } from '../../Helper/permissionHelper'
import { tidyName } from '../../helper'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from '../../CustomHook/usePrevious'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { clearPublicPageData } from '../../Redux/Reducers/CMS'
import PermissionLogs from './logs/PermissionLogs'

const CustomPrevIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '19px' }}>⬅</div>

// Custom right icon component
const CustomNextIcon = () => <div style={{ fontSize: '24px', display: 'flex', alignItems: 'center', marginTop: '15px' }}>➡️</div>

const PermissionTab = ({ isSinglePage, pageName }) => {
  const dispatch = useDispatch()
  const [allPermissions, setAllPermissions] = useState()
  const [permissionLoading, setPermissionLoading] = useState()
  const [permissionsWithoutRole, setPermissionsWithoutRole] = useState()
  const [roles, setRoles] = useState()

  const [form, updateForm] = useState({
    name: '',
    description: '',
  })

  const [activeRole, setActiveRole] = useState()
  const [activeTab, setActiveTab] = useState('1')
  const [filterPage, setFilterPage] = useState()
  const [isCreatingNewRole, setIsCreatingNewRole] = useState(false)
  const [selectedPermissionToCreateRole, setSelectedPermissionToCreateRole] = useState([])

  const reduxQueue = useSelector((state) => state.Queue.queues)

  let currentPermission

  if (isCreatingNewRole) {
    currentPermission = permissionsWithoutRole?.permissions
  } else {
    currentPermission = allPermissions?.find((item) => item?.role_id === activeRole)?.permissions
  }

  const updatePermissionQueue = reduxQueue?.filter((queue) => queue.url === 'update-role-wise-permission') || []
  const updatePermissionQueueLength = updatePermissionQueue?.length
  const updatePermissionQueuePreviousLength = usePrevious(updatePermissionQueueLength)

  useEffect(() => {
    dispatch(clearPublicPageData())
  })

  useEffect(() => {
    // setAllPermissions(permissionsData)
    // setRoles(getRolesFromPermissions(permissionsData))
    setPermissionLoading(true)
    request('view-all-permission').then((res) => {
      setAllPermissions(res?.data)
      setRoles(getRolesFromPermissions(res?.data))
      const allRoles = getRolesFromPermissions(res?.data)
      setActiveRole(allRoles?.[0]?.role_id)
      setPermissionLoading(false)
    })
  }, [])

  useEffect(() => {
    if (isCreatingNewRole) {
      request('without-role-permission').then((res) => {
        setPermissionsWithoutRole(res?.data?.[0])
      })
    }
  }, [isCreatingNewRole])

  useEffect(() => {
    if (
      updatePermissionQueueLength !== updatePermissionQueuePreviousLength &&
      updatePermissionQueuePreviousLength !== 0 &&
      updatePermissionQueueLength === 0
    ) {
      setTimeout(() => {
        setPermissionLoading(true)
        request('view-all-permission').then((res) => {
          setAllPermissions(res?.data)
          setPermissionLoading(false)
          //  setRoles(getRolesFromPermissions(res?.data))
          //  const allRoles = getRolesFromPermissions(res?.data)
          //  setActiveRole(allRoles?.[0]?.role_id)
        })
      }, 3000)
    }
  }, [updatePermissionQueueLength])

  useEffect(() => {
    let filters = []
    if (isSinglePage) {
      filters?.push({ id: pageName, label: tidyName(pageName), checked: true })
      setFilterPage(filters)
    } else {
      currentPermission?.page &&
        Object.keys(currentPermission?.page)?.map((pageKey) => {
          filters?.push({ id: pageKey, label: tidyName(pageKey), checked: true })
        })
      setFilterPage(filters)
    }
  }, [JSON.stringify(currentPermission)])

  const onChangeTab = (e) => {
    setActiveTab(e)
  }

  const onClickRole = (id) => {
    setActiveRole(id)
    setIsCreatingNewRole(false)
    setSelectedPermissionToCreateRole([])
    setActiveTab('1')
  }

  const onDeleteRole = (id) => {
    request(`/remove-roles/${id}`).then((res1) => {
      request('view-all-permission').then((res) => {
        if (res1?.status === 200 || res1?.status === 202) {
          dispatch(setNotification({ type: 'success', message: 'Role deleted successfully!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: res1?.message || res1?.data?.message || 'Something went wrong' }))
        }
        setAllPermissions(res?.data)
        setRoles(getRolesFromPermissions(res?.data))
        const allRoles = getRolesFromPermissions(res?.data)
        setActiveRole(allRoles?.[0]?.role_id)
      })
    })
  }

  const onClickPermissionForNewRole = (id) => {
    let newIds = [...selectedPermissionToCreateRole]
    if (newIds?.includes(id)) {
      let i = newIds?.indexOf(id)
      newIds.splice(i, 1)
    } else {
      newIds?.push(id)
    }
    setSelectedPermissionToCreateRole(newIds)
  }

  const onSaveRole = () => {
    let payload
    payload = {
      ...form,
      role_id: activeRole,
    }
    let url = 'roles/update'

    if (isCreatingNewRole) {
      url = 'roles/create'
      payload = {
        ...form,
        permissions: selectedPermissionToCreateRole,
      }
    }
    request(url, 'POST', payload).then((res1) => {
      request('view-all-permission').then((res) => {
        if (res1?.status === 200 || res1?.status === 202) {
          dispatch(setNotification({ type: 'success', message: isCreatingNewRole ? 'Role created successfully!' : 'Role updated successfully!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
        setAllPermissions(res?.data)
        setIsCreatingNewRole(false)
        setSelectedPermissionToCreateRole([])
        setRoles(getRolesFromPermissions(res?.data))
        const allRoles = getRolesFromPermissions(res?.data)
        if (isCreatingNewRole) {
          setActiveRole(allRoles?.[allRoles?.length - 1]?.role_id)
        }
      })
    })
  }

  const onCopyRole = (id) => {
    request(`roles/copy-role/${id}`).then((res1) => {
      request('view-all-permission').then((res) => {
        if (res1?.status === 200 || res1?.status === 202) {
          dispatch(setNotification({ type: 'success', message: 'Role Copied!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
        setAllPermissions(res?.data)
        setIsCreatingNewRole(false)
        setSelectedPermissionToCreateRole([])
        setRoles(getRolesFromPermissions(res?.data))
        const allRoles = getRolesFromPermissions(res?.data)
        if (isCreatingNewRole) {
          setActiveRole(allRoles?.[allRoles?.length - 1]?.role_id)
        }
      })
    })
  }

  return (
    <>
      <div className='permission-table d-sm-flex w-100'>
        <div className='w-100 left-col' style={{ borderRight: '1px solid #E3E3E3', maxHeight: isSinglePage ? '40vh' : '78vh', overflow: 'auto' }}>
          <div className='permission-header w-100 bg-transparent subtitle1' style={{ color: primary_color, borderBottom: '1px solid #E3E3E3' }}>
            Roles
          </div>
          <div className='w-12 px-4 py-3'>
            {roles?.map((role) => {
              return (
                <RoleBlock
                  isActive={role?.role_id === activeRole}
                  text={tidyName(role?.role)}
                  onClickRole={onClickRole}
                  id={role?.role_id}
                  is_guest={role?.is_guest}
                  is_default={role?.is_default}
                  onCopyRole={onCopyRole}
                />
              )
            })}
            {!isSinglePage && (
              <AddNewRoleBlock
                text='Add New User Group'
                setActiveRole={setActiveRole}
                setIsCreatingNewRole={setIsCreatingNewRole}
                setActiveTab={setActiveTab}
              />
            )}
          </div>
          {!isCreatingNewRole && activeTab === '1' && !isSinglePage && <PermissionFilters filterPage={filterPage} setFilterPage={setFilterPage} />}
        </div>
        <div className='w-100 right-col permission-tab-wrapper'>
          <Tabs
            defaultActiveKey='1'
            activeKey={activeTab?.toString() || '1'}
            onChange={(e) => onChangeTab(e)}
            className='permission-tab'
            renderTabBar={(props) => <ScrollableInkTabBar {...props} prevIcon={<CustomPrevIcon />} nextIcon={<CustomNextIcon />} />}
            renderTabContent={() => <TabContent />}>
            {!isSinglePage && (
              <TabPane tab={'details'} key={0}>
                <DetailPanel
                  roles={roles}
                  activeRole={activeRole}
                  onDeleteRole={onDeleteRole}
                  isCreatingNewRole={isCreatingNewRole}
                  setIsCreatingNewRole={setIsCreatingNewRole}
                  setSelectedPermissionToCreateRole={setSelectedPermissionToCreateRole}
                  setActiveRole={setActiveRole}
                  setActiveTab={setActiveTab}
                  onSaveRole={onSaveRole}
                  form={form}
                  updateForm={updateForm}
                />
              </TabPane>
            )}

            <TabPane tab={'Permissions'} key={1}>
              <PermissionPanel
                currentPermission={currentPermission}
                filterPage={filterPage}
                role_id={activeRole}
                isCreatingNewRole={isCreatingNewRole}
                onClickPermissionForNewRole={onClickPermissionForNewRole}
                selectedPermissionToCreateRole={selectedPermissionToCreateRole}
                permissionLoading={permissionLoading}
                isSinglePage={isSinglePage}
              />
            </TabPane>
            {!isSinglePage && (
              <TabPane tab={'Cms Permissions'} key={2}>
                <CMSPermissionPanel currentPermission={currentPermission} role_id={activeRole} />
              </TabPane>
            )}
            {!isSinglePage && (
              <TabPane tab={'Logs'} key={3}>
                {(activeTab == 3 || activeTab == '3') && <PermissionLogs role_id={activeRole} setActiveTab={setActiveTab}></PermissionLogs>}
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default PermissionTab
