import { useEffect, useState } from 'react'
import RoundedInput from '../Form/Fields/roundedInput'
import defaultProfilePic from './profile_picc.png'
import ProfileBanner from './profileBanner'
import ProfileAddress from './profileAddress'
import { primary_color } from '../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../Redux/Sagas/requests/api'
import { resetPublicUser, setPublicUser } from '../../Redux/Reducers/user'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import ChangePassword from './changePassword'
import ImageUploadAndCrop from './imageUploadAndCrop'
import { setCookie } from '../../Helper/cookieHelper'
import { clearNav } from '../../Redux/Reducers/navigations'
import { useHistory } from 'react-router-dom'
import { validateEmail } from '../../helper'

const Profile = () => {
  const [profilePic, setProfilePic] = useState(null)
  const [profileData, setProfileData] = useState()
  const publicUser = useSelector((state) => state.User.publicUser)

  const [page, setPage] = useState('default')
  // const addresses = ['No: 12/123, ABC Street', 'No: 34, XYZ Street', 'No: 140, Light House']
  const [addresses, setAllAddress] = useState()
  const [defaultAddress, setDefaultAddress] = useState()
  const [addAddress, setAddAddress] = useState(false)
  const [showOTP, setShowOTP] = useState(false)
  const [OTP, setOTP] = useState('')
  const [isProfileUpdateEnable, setIsProfileUpdateEnable] = useState(false)
  const [isVerificationButtonEnable, setIsVerificationButtonEnable] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const fetchAllAddress = () => {
    request('user/address/all', 'GET')
      .then((res) => {
        setAllAddress(res.data)
        if (res.data?.length > 0) {
          let add = res?.data?.[0]
          let default_add = res?.data?.find((item) => item?.default)
          if (default_add) {
            add = default_add
          }
          let defaultAddress = add?.address1 + ', ' + add?.city + ', ' + add?.country + ', ' + add?.post_code

          setDefaultAddress(defaultAddress)
        }
      })
      .catch((e) => {})
  }

  useEffect(() => {
    setProfileData({
      first_name: publicUser?.first_name,
      surname: publicUser?.surname,
      email: publicUser?.email,
      username: publicUser?.username,
      phone_number: publicUser?.phone_number,
      subscribe: publicUser?.subscribe,
    })
    fetchAllAddress()
  }, [publicUser?.email])

  const handleAddressBackClick = () => {
    setPage('default')
    setAddAddress(false)
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfilePic(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleSubmit = () => {}

  const handleAddressSubmit = () => {}

  const handleBackSpace = (e) => {
    const target = e.target
    const key = e.key.toLowerCase()

    if (key == 'backspace' || key == 'delete') {
      target.value = ''
      const prev = target.previousElementSibling
      if (prev) {
        // e.target.setAttribute('readOnly', false);
        prev.focus()
      }
      return
    }
  }

  const handleInput = (e) => {
    const target = e.target
    const val = target.value

    if (isNaN(val)) {
      target.value = ''
      return
    }

    if (val != '') {
      handleOTP()
      const next = target.nextElementSibling
      if (next) {
        // e.target.setAttribute('readOnly', true);
        next.focus()
      }
    }
  }

  const handleOTP = (val) => {
    let text = ''
    const tags = document.querySelectorAll('.inputs input')
    tags.forEach((item) => {
      text = text + item.value
    })

    tags.forEach((item) => {
      item.style.borderColor = '#8E8E8E'
    })

    setOTP(text)
  }

  const onProfileChange = (e) => {
    let { name, value } = e.target
    if (name === 'email' && publicUser?.email !== value) {
      setIsVerificationButtonEnable(true)
      setIsProfileUpdateEnable(false)
    } else if ((name !== 'email' && !isVerificationButtonEnable) || (name === 'email' && publicUser?.email === value)) {
      setIsProfileUpdateEnable(true)
      setIsVerificationButtonEnable(false)
    } else {
      setIsProfileUpdateEnable(false)
    }
    setProfileData((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const onUpdateProfileData = () => {
    request('profile-save', 'POST', profileData).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        request('profile').then((res) => {
          if (res?.status === 200 || res?.status === 202) {
            dispatch(setPublicUser(res?.data?.data))
          }
        })
        dispatch(
          setNotification({
            type: 'success',
            message: 'Profile update successfully!',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.message || 'Something went wrong!',
          })
        )
      }
    })
  }

  const onSendVerificationCode = () => {
    if (!profileData?.email || !validateEmail(profileData?.email)) {
      dispatch(setNotification({ type: 'error', message: 'Please enter correct email' }))
      return
    }
    request('user/verify-email', 'POST', { email: profileData?.email }).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        dispatch(
          setNotification({
            type: 'success',
            message: 'Verification code sent successfully!',
          })
        )
        setIsVerificationButtonEnable(false)
        setShowOTP(true)
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.message || 'Something went wrong!',
          })
        )
      }
    })
  }
  const onResendOTP = () => {
    request('user/verify-email', 'POST', { email: profileData?.email }).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        dispatch(
          setNotification({
            type: 'success',
            message: 'Verification code sent successfully!',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message || 'Something went wrong!',
          })
        )
      }
    })
  }

  const onVerifyOTP = () => {
    request('user/verify-otp', 'POST', {
      email: profileData?.email,
      otp: OTP,
    }).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        dispatch(
          setNotification({
            type: 'success',
            message: 'OTP verified successfully!',
          })
        )
        setIsVerificationButtonEnable(false)
        setShowOTP(false)
        setIsProfileUpdateEnable(true)
      } else {
        const tags = document.querySelectorAll('.inputs input')
        tags.forEach((item) => {
          item.style.borderColor = 'red'
        })
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message || 'Something went wrong!',
          })
        )
      }
    })
  }

  const onRemoveProfilePic = () => {
    request('remove-profile', 'POST', { id: publicUser?.id }).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        request('profile').then((res) => {
          if (res?.status === 200 || res?.status === 202) {
            dispatch(setPublicUser(res?.data?.data))
          }
        })
        dispatch(
          setNotification({
            type: 'success',
            message: 'Profile update successfully!',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.message || 'Something went wrong!',
          })
        )
      }
    })
  }

  return (
    <>
      {page === 'default' && (
        <>
          <ProfileBanner />
          <div
            className='container p-5'
            style={{
              width: '60vw',
              display: 'flex',
              flexDirection: 'column',
              gap: '50px',
            }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
              <div style={{ position: 'relative' }}>
                <ImageUploadAndCrop profilePic={profilePic} defaultProfilePic={defaultProfilePic} onRemoveProfilePic={onRemoveProfilePic} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '40px',
                }}>
                <div className='d-inline w-100'>
                  <input
                    onChange={onProfileChange}
                    value={profileData?.first_name}
                    className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                    placeholder='First Name'
                    // value={cardDetails.cardholderName}
                    name='first_name'
                    type='text'
                  />
                  {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                </div>
                <div className='d-inline w-100'>
                  <input
                    onChange={onProfileChange}
                    value={profileData?.surname}
                    className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                    placeholder='surname'
                    // value={cardDetails.cardholderName}
                    name='surname'
                    type='text'
                  />
                  {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                </div>
                <div className='d-inline w-100'>
                  <input
                    onChange={onProfileChange}
                    value={profileData?.username}
                    className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                    placeholder='Username'
                    name='username'
                    type='text'
                  />
                  {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}>
                  <div style={{ width: '130%' }} className='d-flex align-items-center'>
                    <div className='d-inline' style={{ width: '77%' }}>
                      <input
                        onChange={onProfileChange}
                        value={profileData?.email}
                        className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                        placeholder='email'
                        // value={cardDetails.cardholderName}
                        name='email'
                        type='text'
                      />

                      {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                    </div>
                    {!showOTP && (
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onSendVerificationCode()
                        }}
                        style={{ fontSize: '15px', background: primary_color }}
                        disabled={!isVerificationButtonEnable}
                        className='btn btn-primary px-4 rounded-pill ml-3'>
                        Send Verification Code
                      </button>
                    )}
                  </div>
                  {showOTP && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}>
                      <div className='inputs my-3' style={{ display: 'flex', gap: '20px' }}>
                        <input
                          style={{
                            height: '55px',
                            width: '50px',
                            textAlign: 'center',
                            border: '2px solid #8E8E8E',
                            borderRadius: '10px',
                          }}
                          onKeyUp={handleBackSpace}
                          onInput={handleInput}
                          maxLength={1}
                          placeholder=''
                          type='text'
                          // className='rounded'
                        />
                        <input
                          style={{
                            height: '55px',
                            width: '50px',
                            textAlign: 'center',
                            border: '2px solid #8E8E8E',
                            borderRadius: '10px',
                          }}
                          onKeyUp={handleBackSpace}
                          onInput={handleInput}
                          maxLength={1}
                          placeholder=''
                          type='text'
                          // className='rounded'
                        />
                        <input
                          style={{
                            height: '55px',
                            width: '50px',
                            textAlign: 'center',
                            border: '2px solid #8E8E8E',
                            borderRadius: '10px',
                          }}
                          onKeyUp={handleBackSpace}
                          onInput={handleInput}
                          // maxLength={1}
                          placeholder=''
                          type='text'
                          // className='rounded'
                        />
                        <input
                          style={{
                            height: '55px',
                            width: '50px',
                            textAlign: 'center',
                            border: '2px solid #8E8E8E',
                            borderRadius: '10px',
                          }}
                          onKeyUp={handleBackSpace}
                          onInput={handleInput}
                          maxLength={1}
                          placeholder=''
                          type='text'
                          // className='rounded'
                        />
                      </div>
                      <button
                        className='btn btn-primary rounded-pill px-5'
                        style={{ width: '12rem', background: primary_color }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onVerifyOTP()
                        }}>
                        Verify
                      </button>

                      <p className='subtitle1'>
                        Didn't receive the email?{' '}
                        <span
                          style={{ color: primary_color }}
                          className='cursor-pointer'
                          onClick={() => {
                            onResendOTP()
                          }}>
                          <u>Resend Email</u>
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                <div className='d-inline w-100'>
                  <input
                    onChange={onProfileChange}
                    value={profileData?.phone_number}
                    className={`form-control subtitle1 rounded-pill rounded-input rounded-pill-no-icon `}
                    placeholder='Number'
                    // value={cardDetails.cardholderName}
                    name='phone_number'
                    type='number'
                  />

                  {/* {showValidation && !cardDetails.cardholderName && <div className='text-danger subtitle1'>Please enter card holder name</div>} */}
                </div>
              </div>
              <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <input
                  checked={profileData?.subscribe}
                  type='checkbox'
                  id='emailCheckBox'
                  className='subtitle1'
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      subscribe: e.target.checked,
                    })
                  }}
                />
                <label for='emailCheckBox' className='fw-normal mt-2 subtitle1' style={{ color: primary_color }}>
                  Receive Communications emails from Locker Rental
                </label>
              </div>
              <div>
                <button
                  className='btn btn-primary rounded-pill'
                  style={{ background: primary_color, paddingInline: '8rem' }}
                  disabled={!isProfileUpdateEnable}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onUpdateProfileData()
                  }}>
                  Save
                </button>
              </div>
            </form>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setPage('Address')}>
                <div>
                  <div>
                    <p style={{ color: primary_color }} className='body1 font-weight-bold'>
                      Residential Address
                    </p>
                  </div>
                  <div>
                    <p style={{ color: primary_color, marginBottom: '0' }} className='subtitle1'>
                      Address
                    </p>
                    <p className='subtitle1 font-weight-bold'>{defaultAddress}</p>
                  </div>
                </div>
                <div>
                  <i
                    className='fa-solid fa-chevron-right text-light  rounded-circle fs-6 cursor-pointer'
                    style={{ background: primary_color, padding: '0.7rem 0.9rem' }}></i>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {}}>
                <div>
                  <div>
                    <p style={{ color: primary_color }} className='body1 font-weight-bold'>
                      Change Password
                    </p>
                  </div>
                  <div>
                    <p style={{ color: primary_color, marginBottom: '0' }} className='subtitle1'>
                      Password
                    </p>
                    <input type='password' value='password' className='border-0 curs' style={{ outline: 'none' }} readOnly={true} />
                  </div>
                </div>
                <div>
                  <i
                    className='fa-solid fa-chevron-right text-light rounded-circle fs-6 cursor-pointer'
                    style={{ background: primary_color, padding: '0.7rem 0.9rem' }}
                    onClick={() => setPage('change_password')}></i>
                </div>
              </div>
              <div>
                <button
                  className='btn btn-outline-danger rounded-pill d-flex gap-5 justify-content-center align-items-center'
                  style={{ paddingInline: '6.5rem' }}
                  onClick={() => {
                    dispatch(resetPublicUser())
                    setCookie('AUTH_TOKEN')
                    dispatch(clearNav())
                    history.push('/')
                  }}>
                  <i class='fa-solid fa-arrow-right-from-bracket mr-3'></i>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {page === 'Address' && (
        <ProfileAddress
          addAddress={addAddress}
          handleAddressBackClick={handleAddressBackClick}
          defaultAddress={defaultAddress}
          setAddAddress={setAddAddress}
          addresses={addresses}
          handleAddressSubmit={handleAddressSubmit}
          fetchAllAddress={fetchAllAddress}
        />
      )}
      {page === 'change_password' && <ChangePassword setPage={setPage} />}
    </>
  )
}

export default Profile
